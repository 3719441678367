<template>
  <ArvaModal
    name="reviewFSSMatchability"
    :title="title"
    :wide="true"
    :width="1100"
    @close-modal="handleCloseModal"
  >
    <v-card class="pa-3">
      <div v-if="items.length > 0">
        <v-text-field
          v-model="includedSearch"
          label="Search Programs"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
          single-line
        ></v-text-field>
        <v-data-table
          :headers="programHeaders"
          :items="items"
          :search="includedSearch"
        >
          ></v-data-table
        >
      </div>
      <div v-else class="pw-error pa-2">No Programs Found</div>
    </v-card>
  </ArvaModal>
</template>

<script>
import ArvaModal from "@/components/modals/ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"

export default {
  name: "ReviewFSSMatchability",
  props: ["items", "title", "fssPrograms"],
  components: { ArvaModal, ArvaModalSaveFooter },

  data() {
    return {
      programHeaders: [
        { text: "FSS ID", value: "FSS ID" },
        { text: "Program ID", value: "Program ID" },
        {
          text: "Program Name",
          value: "Program Name",
        },
        {
          text: "Reasons Unmatched",
          value: "Reasons Unmatched",
        },
      ],
      includedSearch: "",
    }
  },

  methods: {
    handleCloseModal() {
      this.$emit("close-modal")
    },
  },
}
</script>

<style scoped>
.pw-error {
  color: red;
  font-weight: 500;
}
</style>
