<template>
  <div>
    <v-row>
      <v-col>
        <h1>EIC what's up on CropForce</h1>
        <p v-if="loading['table']['error']" class="server-error">{{ loading['table']['error'] }}</p>
        <p v-if="loading['filters']['error']" class="server-error">{{ loading['filters']['error'] }}</p>
      </v-col>

      <v-col class="d-flex justify-end">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="h-44 mr-3" :ripple="false" outlined v-bind="attrs" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
              <span class="ml-1">{{ view == FIELD_VIEW ? 'Field View' : 'Dashboard' }}</span>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group @change="setView" mandatory color="primary">
              <v-list-item>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>Field View</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row :class="loading['table']['loading'] ? 'loading' : ''">
      <v-col class="v-input-modified d-flex align-center" :class="loading['table']['loading'] ? 'loading-bg' : ''">
        <h3 class="ma-0 mr-4">Comparing:</h3>

        <v-menu ref="startDateMenuRef" v-model="startDateMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined class="ma-0 pa-0" hide-details v-model="startDate" append-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker
            hide-details
            v-model="startDate"
            no-title
            scrollable
            :allowed-dates="v => allowed(v, 'start')"
          />
        </v-menu>

        <v-icon class="mx-2">mdi-arrow-right</v-icon>

        <v-menu ref="endDateMenuRef" v-model="endDateMenu" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-text-field dense outlined class="ma-0 pa-0" hide-details v-model="endDate" append-icon="mdi-calendar"
              readonly v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker
            hide-details
            v-model="endDate"
            no-title
            scrollable
            :allowed-dates="v => allowed(v, 'end')"
          />
        </v-menu>

        <v-chip class="ml-2" v-if="!loading['table']['loading'] && sortedFilteredItems.length == 0" color="error">
          No EIC Diffs
        </v-chip>
      </v-col>
    </v-row>

    <v-row :class="loading['filters']['loading'] ? 'loading' : ''">
      <v-progress-circular v-if="loading['filters']['loading']" indeterminate color="#79c61c" />

      <v-col class="d-flex align-center flex-wrap v-input-modified"
        :class="loading['filters']['loading'] ? 'loading-bg' : ''">
        <span class="filter-subhead w-100">Filter by hierarchy</span>

        <div class="w-100 d-flex disabled-filters-temp">
          <v-autocomplete dense outlined multiple clearable hide-details v-model="filters['orgs']"
            :items="filterItems['orgs']" placeholder="All Orgs" class="mr-3" item-value="id" item-text="name" />

          <v-autocomplete dense outlined multiple clearable hide-details v-model="filters['clients']"
            :items="filterItems['clients']" placeholder="All Clients" class="mr-3" item-value="id" item-text="name" />

          <v-autocomplete dense outlined multiple clearable hide-details v-model="filters['farms']"
            :items="filterItems['farms']" placeholder="All Farms" class="mr-3" item-value="id" item-text="name" />

          <v-autocomplete dense outlined multiple clearable hide-details v-model="filters['crops']"
            :items="filterItems['crops']" placeholder="All Crops" class="mr-3" item-value="id" item-text="name" />

          <v-autocomplete dense outlined multiple clearable hide-details v-model="filters['fields']"
            :items="filterItems['fields']" placeholder="All Fields" class="mr-3" item-value="id" item-text="name" />
        </div>
      </v-col>
    </v-row>

    <!--
      <v-row :class="loading['table']['loading'] ? 'loading' : ''">
        <v-progress-circular v-if="loading['table']['loading']" indeterminate color="#79c61c" />
  
        <v-col class="d-flex align-center v-input-modified" :class="loading['table']['loading'] ? 'loading-bg' : ''">
          <div class="mr-12 d-flex align-center flex-wrap">
            <span class="filter-subhead w-100">Filter by change in EIC</span>
            <div class="position-relative">
              <v-slider :step="(upperEICBound - lowerEICBound) / upperEICBound" ticks hide-details color="#4B5563"
                track-color="#E5E7EB" v-model="EICChangesFilter" :min="lowerEICBound" :max="upperEICBound" disabled />
  
              <span v-if="lowerEICBound != upperEICBound" class="position-absolute bottom-end-slider-tick">
                {{ lowerEICBound < 0 ? `-${lowerEICBound}` : lowerEICBound }}+ </span>
                  <span v-if="lowerEICBound != upperEICBound" class="position-absolute top-end-slider-tick">
                    {{ upperEICBound }}+
                  </span>
            </div>
          </div>
  
          <div class="mr-12 d-flex align-center flex-wrap">
            <span class="filter-subhead w-100">Filter by # of practices gained</span>
  
            <div class="position-relative">
              <v-slider step="10" hide-details color="#4B5563" track-color="#E5E7EB" v-model="practiceGainFilter"
                :min="0" :max="maxPracticeGain" disabled />
  
              <span v-if="maxPracticeGain != 0" class="position-absolute bottom-end-slider-tick">
                0
              </span>
              <span v-if="maxPracticeGain != 0" class="position-absolute top-end-slider-tick">
                {{ maxPracticeGain }}
              </span>
            </div>
          </div>
  
          <div class="d-flex align-center flex-wrap">
            <span class="filter-subhead w-100">Filter by # of practices lost</span>
  
            <div class="position-relative">
              <v-slider step="10" hide-details color="#4B5563" track-color="#E5E7EB" v-model="practiceLossFilter"
                :min="0" :max="maxPracticeLoss" disabled />
  
              <span v-if="maxPracticeLoss != 0" class="position-absolute bottom-end-slider-tick">
                0
              </span>
              <span v-if="maxPracticeLoss != 0" class="position-absolute top-end-slider-tick">
                {{ maxPracticeLoss }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    -->

    <div v-if="view == DASHBOARD_VIEW">
      <div class="mt-8" :class="loading['table']['loading'] ? 'loading' : ''">
        <v-progress-circular v-if="loading['table']['loading']" indeterminate color="#79c61c" />

        <div class="histogram-wrapper" :class="loading['table']['loading'] ? 'loading-bg' : ''">
          <HistogramD3
            v-if="startDate != null && endDate != null"
            :chartData="chartData"
            :date1="startDate"
            :date2="endDate"
          />
        </div>
      </div>
    </div>

    <div v-else-if="view == FIELD_VIEW">
      <v-row :class="loading['table']['loading'] ? 'loading' : ''">
        <v-progress-circular v-if="loading['table']['loading']" indeterminate color="#79c61c" />

        <v-col :class="loading['table']['loading'] ? 'loading-bg' : ''">
          <div class="mt-4 position-relative manual-table">
            <!-- manual table so we can take advantage of virtualization -->
            <div class="d-flex align-center manual-table-head">
              <div class="px-6 d-flex align-center position-relative font-weight-bold manual-table-cell field-id">Field
                ID
              </div>
              <div class="px-6 d-flex align-center position-relative manual-table-cell field-name">Field Name</div>
              <div
                class="px-6 d-flex align-center position-relative white-space-nowrap font-weight-bold manual-table-cell eic-earlier">
                EIC on <br>{{ startDate }}</div>
              <div
                class="px-6 d-flex align-center position-relative white-space-nowrap font-weight-bold manual-table-cell eic-later">
                EIC on <br>{{ endDate }}
              </div>
              <div
                :data-sort-on="sorts['diff']['on']"
                :data-sort-direction="sorts['diff']['direction']"
                @click="setSort('diff')"
                class="
                  px-6 d-flex align-center position-relative 
                  font-weight-bold manual-table-cell eic-diff sortable-click
                "
              >
                EIC Diff
              </div>
              <div class="px-6 d-flex align-center position-relative font-weight-bold manual-table-cell practice-changes">
                Practice Changes
              </div>
            </div>

            <div class="d-flex flex-wrap align-center manual-table-body">
              <v-virtual-scroll :height="500" :items="sortedFilteredItems" :item-height="200" :key="jankForceRerender">
                <template v-slot:default="{ item }">
                  <div class="d-flex align-center">
                    <div class="px-6 d-flex align-center position-relative manual-table-cell field-id">{{
                      item['field_id'] }}</div>
                    <div class="px-6 d-flex align-center position-relative manual-table-cell field-name">{{
                      item['field_name'] }}</div>
                    <div class="px-6 d-flex align-center position-relative manual-table-cell eic-earlier">{{
                      item['start_eic'].toFixed(5) }}</div>
                    <div class="px-6 d-flex align-center position-relative manual-table-cell eic-later">{{
                      item['end_eic'].toFixed(5) }}</div>
                    <div class="px-6 d-flex align-center position-relative manual-table-cell eic-diff">{{
                      item['eic_diff'].toFixed(5) }}</div>
                    <div class="px-6 d-flex align-center position-relative manual-table-cell practice-changes"
                      v-if="item['added_practices'].length == 0 && item['lost_practices'].length == 0">
                      No change
                    </div>
                    <div
                      class="px-6 d-flex flex-wrap align-content-center position-relative manual-table-cell practice-changes"
                      v-else>
                      <div class="w-100 d-flex align-center mb-1">
                        <div class="d-flex align-center justify-center plus-icon">+</div>
                        <p class="ma-0 ml-2 font-weight-normal">{{ item['added_practices'].join(', ') }}</p>
                      </div>
                      <div class="w-100 d-flex align-center">
                        <div class="d-flex align-center justify-center minus-icon">-</div>
                        <p class="ma-0 ml-2 font-weight-normal">{{ item['lost_practices'].join(', ') }}</p>
                      </div>
                    </div>
                  </div>
                </template>
              </v-virtual-scroll>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import CarbonReadyAPI from '@/api/CarbonReadyAPI';
import HistogramD3 from "@/components/d3/HistogramD3.vue";

const DASHBOARD_VIEW = 0
const FIELD_VIEW = 1

const DEFAULT_FILTERS = {
  orgs: [],
  clients: [],
  farms: [],
  crops: [],
  fields: [],
  corporations: []
}

const DEFAULT_SORTS = {
  diff: { on: true, direction: -1 },
  practiceChanges: { on: true, direction: -1 }
}

const DEFAULT_LOADING = {
  table: { loading: false, error: null },
  filters: { loading: false, error: null }
}

export default {
  name: "EICExplorer",
  components: { HistogramD3 },
  data() {
    return {
      jankForceRerender: 0,
      view: DASHBOARD_VIEW,
      startDateMenu: false,
      endDateMenu: false,
      startDate: null,
      endDate: null,
      EICChangesFilter: 0,
      practiceGainFilter: 0,
      practiceLossFilter: 0,
      maxPracticeGain: 0,
      maxPracticeLoss: 0,
      upperEICBound: 0,
      lowerEICBound: 0,
      possibleDates: [],
      filterItems: { ...DEFAULT_FILTERS },
      loading: { ...DEFAULT_LOADING },
      filters: { ...DEFAULT_FILTERS },
      sorts: { ...DEFAULT_SORTS },
      comparison: [],
      DASHBOARD_VIEW,
      FIELD_VIEW
    }
  },
  computed: {
    datesInBetweenSelections() {
      // dates are sorted most recent -> least recent
      if (this.possibleDates.length <= 2) return []

      const min = { date: this.startDate, idx: this.possibleDates.indexOf(this.startDate) }
      const max = { date: this.endDate, idx: this.possibleDates.indexOf(this.endDate) }
      const inBetweenDates = this.possibleDates.filter(d => {
        const idx = this.possibleDates.indexOf(d)
        return idx < min['idx'] && idx > max['idx']
      })

      return inBetweenDates
    },
    startDateMinMax() {
      return {
        min: this.possibleDates.slice(-1)[0],
        max: this.datesInBetweenSelections.length == 0 
          ? this.startDate
          : this.datesInBetweenSelections[0]
      }
    },
    endDateMinMax() {
      return {
        min: this.datesInBetweenSelections.length == 0 
          ? this.endDate
          : this.datesInBetweenSelections.slice(-1)[0],
        max: this.possibleDates[0]
      }
    },
    sortedFilteredItems() {
      const filteredItems = this.filteredItems
      
      if (this.sorts['diff']['on']) {
        filteredItems.sort((a, b) => {
          if (this.sorts['diff']['direction'] == -1) return b['eic_diff'] - a['eic_diff']
          return a['eic_diff'] - b['eic_diff']
        })
      }

      return filteredItems
    },
    chartData() {
      return this.filteredItems.map(({ eic_diff: diff }) => ({ diff }))
    },
    filteredItems() {
      return this.comparison.filter(f => {
        if (this.filters['orgs'].length > 0) {
          if (!this.filters['orgs'].includes(f['org_node_id'])) return false
        }
        if (this.filters['clients'].length > 0) {
          if (!this.filters['clients'].includes(f['client_id'])) return false
        }
        if (this.filters['farms'].length > 0) {
          if (!this.filters['farms'].includes(f['farm_id'])) return false
        }
        if (this.filters['crops'].length > 0) {
          if (!this.filters['crops'].includes(f['crop_id'])) return false
        }
        if (this.filters['fields'].length > 0) {
          if (!this.filters['fields'].includes(f['field_id'])) return false
        }

        return true
      })
    }
  },
  methods: {
    allowed(v, startOrEnd) {
      const min = new Date(this.possibleDates[0])
      const max = new Date(this.possibleDates.slice(-1)[0])
      const d = new Date(v)

      if (startOrEnd == 'start') return d < new Date(this.endDate) && d >= min
      if (startOrEnd == 'end') return d > new Date(this.startDate) && d <= max
    },
    setView(c) {
      this.view = c
    },
    setSort(key) {
      this.jankForceRerender += 1

      if (this.sorts[key]['on']) {
        if (this.sorts[key]['direction'] == -1) this.sorts[key]['direction'] = 1
        else if (this.sorts[key]['direction'] == 1) this.sorts[key]['on'] = false
      }
      else {
        this.sorts[key]['on'] = true
        this.sorts[key]['direction'] = -1
      }
    },
    getEICData({ startDate=null, endDate=null }) {
      this.loading['table']['loading'] = true
      this.loading['table']['error'] = null

      CarbonReadyAPI.getEICExplorerDataset({ startDate, endDate })
      .then(({ data: { possible_dates, comparison, start_date, end_date } }) => {
        // get the start/end dates from the dataset
        this.comparison = JSON.parse(comparison).reduce((accum, curr) => {
          if (curr['eic_diff'] != 0) {
            // calculate the removed/added practices
            curr['added_practices'] = curr['end_practices'].filter(p => !curr['start_practices'].includes(p))
            curr['lost_practices'] = curr['start_practices'].filter(p => !curr['end_practices'].includes(p))
            accum.push(curr)
          }
          return accum
        }, [])

        this.comparison.sort((a, b) => b['eic_diff'] - a['eic_diff'])
        this.startDate = start_date
        this.endDate = end_date
        this.possibleDates = possible_dates
        this.loading['table']['loading'] = false

        // for (const key in DEFAULT_FILTERS) {
        //   this.filterItems[key] = unique_values[key].map(([id, name]) => ({ id, name }))
        // }
      })
      .catch(err => {
        console.log('error!', err)
        this.loading['table']['loading'] = false
        this.loading['table']['error'] = err
      })
    }
  },
  mounted() {
    this.getEICData({ startDate: null, endDate: null })
  },
  watch: {
    // comparison(curr) {
    //   const fss_ids = curr.map(({ fss_id }) => fss_id)
    //   CarbonReadyAPI.getEICFilters({ fss_ids })
    //   .then()
    // },
    filteredItems(curr) {
      const { maxGain, maxLoss, upperEICBound, lowerEICBound } = curr.reduce((accum, curr) => {
        if (curr['added_practices'].length > accum['maxGain']) accum['maxGain'] = curr['added_practices'].length
        if (curr['lost_practices'].length > accum['maxLoss']) accum['maxLoss'] = curr['lost_practices'].length

        if (curr['eic_diff'] > accum['upperEICBound']) accum['upperEICBound'] = curr['eic_diff']
        if (curr['eic_diff'] < accum['lowerEICBound']) accum['lowerEICBound'] = curr['eic_diff']
        return accum
      }, { maxGain: 0, maxLoss: 0, upperEICBound: 0, lowerEICBound: 0 })

      this.maxPracticeGain = maxGain
      this.maxPracticeLoss = maxLoss
      this.upperEICBound = Math.ceil(upperEICBound)
      this.lowerEICBound = Math.floor(lowerEICBound)
    },
    startDate(curr, prev) {
      if (prev == null) return
      this.getEICData({ startDate: curr, endDate: this.endDate })
    },
    endDate(curr, prev) {
      if (prev == null) return
      this.getEICData({ startDate: this.startDate, endDate: curr })
    }
  }
}
</script>

<style scoped>
.loading {
  position: relative;
}

.loading .v-progress-circular {
  position: absolute;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  z-index: 10;
}

.loading-bg {
  opacity: 0.5;
  pointer-events: none;
}

h3 {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.filter-subhead {
  color: #6F767E;
  /* Base 2 */
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.14px;
}

.v-input-modified .v-text-field {
  max-width: 156px;
  border-radius: 12px;
}

.v-input-modified .v-text-field ::placeholder {
  color: rgba(0, 0, 0, 0.75);
}

.v-input-modified .v-text-field :deep(.v-input__slot) {
  min-height: 36px !important;
}

.v-input-modified .v-text-field :deep(.v-input__append-inner) {
  margin-top: 6px !important;
}

.v-input-modified :deep(.v-slider) {
  cursor: pointer;
}

.v-input-modified div:has(.v-slider) {
  width: 250px;
}

.v-input-modified :deep(.v-slider__thumb) {
  width: 20px;
  height: 20px;
}

.v-input-modified :deep(.v-slider__thumb::before) {
  display: none !important;
}

.v-input-modified :deep(.v-slider__thumb::after) {
  width: 12px;
  height: 12px;
  background: #FFFFFF;
  border-radius: 50%;
}

.v-input-modified :deep(.v-slider__track-container) {
  border-radius: 4px;
  overflow: hidden;
  height: 8px;
}

.bottom-end-slider-tick,
.top-end-slider-tick {
  color: #111827;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  bottom: -12px;
}

.bottom-end-slider-tick {
  left: 0;
}

.top-end-slider-tick {
  right: 0;
}

.manual-table {
  height: 556px;
  overflow: scroll;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.manual-table-head {
  position: sticky;
  background: #FFFFFF;
  z-index: 2;
  top: 0;
}

.manual-table-cell {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 200px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.manual-table-head .manual-table-cell {
  height: 56px;
}

.manual-table-cell::before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.25);
  right: 0;
}

.manual-table-cell.field-id {
  min-width: 98px;
  max-width: 98px;
  position: sticky !important;
  background: #FFFFFF;
  left: 0;
  z-index: 1;
}

.manual-table-cell.field-name {
  min-width: 206px;
  max-width: 206px;
  position: sticky !important;
  background: #FFFFFF;
  left: 98px;
  z-index: 1;
}

.manual-table-cell.eic-earlier {
  min-width: 126px;
  max-width: 126px;
}

.manual-table-cell.eic-later {
  min-width: 126px;
  max-width: 126px;
}

.manual-table-cell.eic-diff {
  min-width: 116px;
  max-width: 116px;
}

.manual-table-cell.practice-changes {
  width: 100%;
}

.manual-table-cell.practice-changes::before {
  display: none;
}

.plus-icon,
.minus-icon {
  height: 16px;
  width: 16px;
  border-radius: 4px;
}

.plus-icon+p,
.minus-icon+p {
  color: #000000;
  line-height: 1.25;
}

.plus-icon {
  color: #61B100;
  background: #F1FDDF;
  border: 1px solid #61B100;
}

.minus-icon {
  color: #EF4444;
  background: #FEE2E2;
  border: 1px solid #EF4444;
}

.sortable-click {
  cursor: pointer;
}
.sortable-click:hover {
  opacity: 0.6;
}
.sortable-click::after {
  content: '↑';
  font-size: 16px;
  top: calc(50% - 10px);
  transform: rotate(180deg);
  display: none;
  margin-left: 6px;
}
.sortable-click[data-sort-on="true"]::after,
.sortable-click[data-sort-on="true"]::after {
  display: block;
}
.sortable-click[data-sort-direction="1"]::after {
  transform: rotate(0deg);
}
.server-error {
  color: red;
  font-size: 18px;
  line-height: 1;
}
.histogram-wrapper {
  min-height: 500px;
}
.disabled-filters-temp {
  pointer-events: none;
  opacity: 0.3;
}
</style>
