<template>
  <div v-if="user.hasOwnProperty('is_superuser')">
    <DashboardTitle
      title="SuperUser Admin"
      imgSrc="/assets/images/side-nav/admin.png"
    />
    <v-snackbar v-model="snackbar" color="red lighten-1" top>{{
      snackbarText
    }}</v-snackbar>

    <div v-if="!user.is_superuser" class="row">
      <div class="col-12">
        <v-alert type="error" dismissible>
          You do not have permission to view this page.
        </v-alert>
      </div>
    </div>

    <div v-else class="col-12 scroll-col">
      <v-toolbar class="mb-4" flat>
        <v-tabs grow v-model="tab" active-class="active-tab font-weight-bold">
          <v-tab v-for="item in tabTitles" :key="item.index">
            {{ item.name }}
          </v-tab>
        </v-tabs>
        <div class="d-flex justify-end bg-surface-variant mt-3 mb-5 ml-4">
          <v-btn color="primary" @click="loginToDjangoAdmin">
            Login to Django Admin</v-btn
          >
        </div>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="item in tabTitles" :key="item.index">
          <v-card class="ma-2" v-if="item.name == 'Client Management'">
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel class="mb-4">
                <v-expansion-panel-header
                  class="primary text-white font-weight-bold"
                  >MOVE CLIENT</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div class="row mt-3 mb-3">
                    <v-btn
                      class="float-right"
                      :loading="loading"
                      @click="clickCreateBtn"
                    >
                      <v-icon>mdi-plus</v-icon>
                      Create New Task
                    </v-btn>
                  </div>
                  <div class="row">
                    <v-simple-table
                      class="selector-table"
                      fixed-header
                      height="380px"
                      overflow="auto"
                    >
                      <thead>
                        <tr>
                          <th width="300">Created At</th>
                          <th width="300">Client</th>
                          <th width="400">Source Organization</th>
                          <th width="400">Target Organization</th>
                          <th width="300">Requested By</th>
                          <th width="300">Release Form Required</th>
                          <th width="300">Status</th>
                          <th width="300"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <MoveClientListRow
                          v-for="job in moveClientJobs"
                          :key="job.client_id"
                          :job="job"
                          @delete-job="confirmDeleteMoveClientJob"
                        />
                      </tbody>
                    </v-simple-table>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="mb-4">
                <v-expansion-panel-header
                  class="primary text-white font-weight-bold"
                >
                  RENAME EXISTING CLIENT
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-if="items.length > 0">
                    <v-text-field
                      v-model="searchJob"
                      label="Search"
                      class="mx-4"
                      compact
                    ></v-text-field>
                    <v-data-table
                      :headers="headers"
                      :items="items"
                      :search="searchJob"
                    ></v-data-table>
                  </div>
                  <v-card>
                    <v-container fluid>
                      <v-row>
                        <v-col cols="3">
                          <v-row class="pa-6">
                            <v-autocomplete
                              v-model="selectedRenameOrg"
                              label="Existing Org Node"
                              :items="renameOrgOptions"
                              class="mx-4"
                              compact
                            ></v-autocomplete>
                          </v-row>
                        </v-col>
                        <v-col cols="3">
                          <v-row class="pa-6">
                            <v-autocomplete
                              v-model="selectedRenameClient"
                              label="Existing Client Name"
                              :items="renameClientOptions"
                              class="mx-4"
                              compact
                            ></v-autocomplete>
                          </v-row>
                        </v-col>
                        <v-col cols="4">
                          <v-row class="pa-6">
                            <v-text-field
                              v-model="newClient"
                              label="New Client Name"
                              class="mx-4"
                              compact
                            ></v-text-field>
                          </v-row>
                        </v-col>
                        <v-col cols="2">
                          <v-row class="pa-6">
                            <v-btn @click="renameClient">Rename Client</v-btn>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
          <v-card class="ma-2" v-if="item.name == 'Program Management'">
            <div class="d-flex">
              <v-col cols="4">
                <h4 bold class="mt-2 ml-3">
                  {{ EAOrders.length.toLocaleString() }} Programs
                </h4>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="EAOrdersSearch"
                  label="Search Programs"
                  prepend-inner-icon="mdi-magnify"
                  variant="outlined"
                  hide-details
                ></v-text-field>
              </v-col>
            </div>
            <v-data-table
              :headers="EAOrdersHeaders"
              :items="EAOrders"
              :search="EAOrdersSearch"
            >
              <template slot="item.is_locked" slot-scope="props">
                <v-checkbox
                  @change="handleEAOrderLock(props.item)"
                  color="blue"
                  inset
                  v-model="props.item.is_locked"
                  off-icon="mdi-lock-open"
                  on-icon="mdi-lock"
                >
                  Locked : {{ props.item.is_locked }}
                </v-checkbox>
              </template></v-data-table
            >
            <v-btn class="mx-3 mb-3" @click="lockAllPrograms"
              >Lock All Programs</v-btn
            >
            <v-btn class="mx-3 mb-3" @click="unLockAllPrograms"
              >Unlock All Programs</v-btn
            >
          </v-card>
          <v-card class="ma-2" v-if="item.name == 'Contract Management'">
            <div v-if="counterSignItems.length > 0">
              <div class="d-flex">
                <v-col cols="4">
                  <h4 bold class="mt-2 ml-3">
                    {{ counterSignItems.length.toLocaleString() }} Contracts
                  </h4>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="counterSignSearch"
                    label="Search Contracts"
                    prepend-inner-icon="mdi-magnify"
                    variant="outlined"
                    hide-details
                  ></v-text-field>
                </v-col>
              </div>
              <v-layout column style="height: 80vh">
                <v-flex style="overflow: auto">
                  <v-data-table
                    class="countersign"
                    :items="counterSignItemsFiltered"
                    :headers="counterSignHeaders"
                    :search="counterSignSearch"
                    :items-per-page="-1"
                    height="75vh"
                    fixed-header
                    @click:row="fetchCounterSignEnrollment"
                  >
                    <template v-slot:header="{ header }">
                      <VDataTablesMultiSelectHeader
                        :items="counterSignItems"
                        :headers="counterSignHeaders"
                        :filters="filters"
                        @filtered-data="filteredData"
                      />
                    </template>

                    <template slot="item.Notes" slot-scope="props">
                      <div
                        v-if="props.item.Status != 'contract completed'"
                        class="d-flex align-center notes-container"
                      >
                        <img
                          v-if="
                            props.item.Notes &&
                            props.item.Status == 'changes requested'
                          "
                          :src="changeRequestIcon"
                          contain
                          max-height="40"
                          max-width="40"
                          class="notes-icon mr-2"
                        />
                        <img
                          v-else-if="
                            props.item.Notes &&
                            props.item.Status == 'contract voided'
                          "
                          :src="voidChangeRequestIcon"
                          contain
                          max-height="40"
                          max-width="40"
                          class="notes-icon mr-2"
                        />
                        <v-tooltip bottom max-width="250px">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="truncate-text" v-bind="attrs" v-on="on">
                              {{ props.item.Notes }}
                            </div>
                          </template>
                          <span>{{ props.item.Notes }}</span>
                        </v-tooltip>
                      </div>
                    </template>

                    <template slot="item.Status" slot-scope="props">
                      <v-chip
                        outlined
                        small
                        :color="`${
                          props.item.Status in CONTRACT_STATUS_MAP
                            ? CONTRACT_STATUS_MAP[props.item.Status]['color']
                            : 'grey'
                        }`"
                      >
                        {{ props.item.Status in CONTRACT_STATUS_MAP
                            ? CONTRACT_STATUS_MAP[props.item.Status]['text']
                            : props.item.Status }}
                      </v-chip>
                    </template>

                    <template slot="item.id" slot-scope="props">
                      <span
                        class="hover"
                        @click="fetchCounterSignEnrollment(props.item)"
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <div v-on="on">
                              {{ props.item.id }}
                            </div>
                          </template>
                          <span>Click Review And Approve/Reject</span>
                        </v-tooltip>
                      </span>
                    </template>
                  </v-data-table>
                </v-flex>
              </v-layout>
            </div>
            <div
              v-if="fetchingApprovals"
              class="mt-3 d-flex justify-content-center"
            >
              <div class="text-bold mb-0">Fetching Data</div>
              <div class="spinner-border ml-4" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </v-card>
        </v-tab-item></v-tabs-items
      >
    </div>

    <CreateNewMoveClientTaskModal
      v-if="showCreateNewMoveClientTaskModal"
      :clientOrgOptions="clientOrgOptions"
      :targetCorpOptions="targetCorpOptions"
      @close-modal="clickCloseBtnInCreateModal"
      @save-changes="confirmCreateNewMoveClientTask"
    />

    <ConfirmModal
      v-if="confirmCreateJobModalOpen"
      :titleText="confirmMoveClientTitle"
      :confirmText="confirmMoveClientBody"
      @confirm="handleCreateNewMoveClientTask"
      @close-modal="confirmCreateJobModalOpen = false"
    />

    <ConfirmModal
      v-if="confirmDeleteMoveClientJobOpen"
      titleText="Confirm Delete Move Client Job"
      :confirmText="deleteMoveClientBodyText"
      @confirm="deleteJob"
      @close-modal="confirmDeleteMoveClientJobOpen = false"
    />

    <AlertErrorModal
      v-if="alertErrorModalOpen"
      :titleText="modalTitleText"
      :errorMessage="errorMessageForModal"
      @close-modal="alertErrorModalOpen = false"
    />

    <ContractCounterSignSidebar
      v-if="showGenerateContractSidebar"
      :visible="showGenerateContractSidebar"
      :enrollmentID="enrollmentID"
      :channelPartnerName="channelPartnerName"
      @closeSidebar="handleGrowerContractSidebarClose"
      @refreshContractList="fetchCounterSignApprovals"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex"
import { User } from "@/store/modules"
import DashboardTitle from "@/components/dashboard/DashboardTitle"
import MoveClientListRow from "@/components/superUserAdmin/MoveClientListRow"
import CreateNewMoveClientTaskModal from "@/components/modals/superUserAdmin/CreateNewMoveClientTaskModal"
import FieldsAPI from "@/api/FieldsAPI"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

import ConfirmModal from "@/components/modals/ConfirmModal"
import AlertErrorModal from "@/components/modals/AlertErrorModal"
import { Filter } from "@/store/modules"
import ContractCounterSignSidebar from "@/components/contractGroups/ContractCounterSignSidebar"
import VDataTablesMultiSelectHeader from "@/components/misc/VDataTablesMultiSelectHeader"
import changeRequestIcon from "@/assets/images/change-request.png"
import voidChangeRequestIcon from "@/assets/images/void-change-request.png"

import { CONTRACT_STATUS_MAP } from "@/constants/contractGroups"

import {
  setIntervalAsync,
  clearIntervalAsync,
} from "set-interval-async/dynamic"

export default {
  name: "SuperUserAdminView",
  components: {
    DashboardTitle,
    MoveClientListRow,
    CreateNewMoveClientTaskModal,
    ConfirmModal,
    AlertErrorModal,
    ContractCounterSignSidebar,
    VDataTablesMultiSelectHeader,
  },

  data() {
    return {
      changeRequestIcon,
      voidChangeRequestIcon,
      CONTRACT_STATUS_MAP,
      tab: 0,
      tabTitles: [
        { index: 0, name: "Client Management" },
        { index: 1, name: "Program Management" },
        { index: 2, name: "Contract Management" },
      ],

      panel: [-1],
      snackbarText: "",
      snackbar: false,
      showCreateNewMoveClientTaskModal: false,
      showGenerateContractSidebar: false,
      focusedGroupId: null,
      fetchIntervalID: null,
      fetchIntervalContractID: null,
      counterSignSearch: "",
      EAOrdersSearch: "",
      loading: false,
      enrollmentID: null,
      moveClientJobs: [],
      pendingClientJobs: [],
      targetCorpOptions: [],
      counterSignItems: [],
      counterSignItemsFiltered: [],
      counterSignHeaders: [
        { text: "Contract ID", value: "id" },
        { text: "CP Name", value: "Channel Partner" },
        { text: "Contract Group", value: "Contract Group Name" },
        { text: "Client(s)", value: "Client" },
        { text: "Status", value: "Status" },
        { text: "Notes", value: "Notes" },
        { text: "Uploaded By", value: "Uploaded By", width: "10%" },
        { text: "Uploaded At", value: "Uploaded At" },
        { text: "Contract UUID", value: "Contract UUID" },
        { text: "Counter Signed At", value: "CounterSigned At" },
      ],
      filters: {
        Status: [],
        "Uploaded By": [],
        "Channel Partner": [],
        "Contract Group Name": [],
      },
      channelPartnerName: "",
      EAOrders: [],
      EAOrdersHeaders: [
        { text: "ID", value: "id" },
        { text: "Program Subcode", value: "name", width: "70%" },
        { text: "% Allocated", value: "pct_filled", width: "10%" },
        { text: "Locked?", value: "is_locked", width: "10%" },
      ],
      confirmCreateJobModalOpen: false,
      createJobPayload: {},
      alertErrorModalOpen: false,
      errorMessageForModal: null,
      modalTitleText: null,
      fetchingApprovals: true,
      confirmMoveClientTitle: "Confirm Create New Move Client Job",
      confirmMoveClientBody: "Are you sure you want to create this job?",
      newClient: null,
      selectedRenameClient: null,
      selectedRenameOrg: null,
      searchJob: "",
      items: [],
      headers: [
        { text: "Date", value: "created_at" },
        { text: "Organization", value: "org_name" },
        { text: "Existing Client", value: "existing_client_name" },

        { text: "New Client", value: "new_client_name" },
        { text: "Status", value: "stage" },
      ],
      confirmDeleteMoveClientJobOpen: false,
      deleteMoveClientBodyText: "Are you sure you want to delete this job?",
      moveClientJobToDelete: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
    }),
    ...mapGetters({
      clients: Filter.Getters.getClients,
      orgNodes: Filter.Getters.getOrgNodes,
    }),

    renameOrgOptions() {
      let options = []
      this.orgNodes.forEach(org => {
        options.push({ text: org.name + " - " + org.id, value: org.id })
      })
      return options
    },
    renameClientOptions() {
      let options = []
      this.clients.forEach(client => {
        options.push({
          text: client.name + " - " + client.id,
          value: client.id,
        })
      })
      return options
    },
  },

  methods: {
    ...mapActions({
      logInToAdmin: User.Actions.logInToAdmin,
    }),
    filteredData(e) {
      this.counterSignItemsFiltered = e
    },

    handleEAOrderLock(e) {
      let payload = { order_id: e.id, lock_only: true, is_locked: e.is_locked }

      CarbonReadyAPI.updateInsetOrder(payload)
      if (e.is_locked) {
        this.snackbarText = "Program " + e.id + " ( " + e.name + " ) Locked!"
      } else {
        this.snackbarText = "Program " + e.id + " ( " + e.name + " ) Unlocked!"
      }
      this.snackbar = true
    },

    async clickCreateBtn() {
      this.loading = true
      await FieldsAPI.fetchClientOrgOptions().then(({ data }) => {
        // Filter out the clients that are already in pending state
        this.clientOrgOptions = data.client_org_options.map(org => ({
          ...org,
          clients: org.clients.filter(
            client => !this.pendingClientJobs.includes(client.id)
          ),
        }))
      })

      if (this.targetCorpOptions.length === 0) {
        await this.fetchTargetCorpOptions()
      }

      this.loading = false
      this.showCreateNewMoveClientTaskModal = true
    },

    loginToDjangoAdmin() {
      this.logInToAdmin()
    },

    clickCloseBtnInCreateModal() {
      this.showCreateNewMoveClientTaskModal = false
      this.createJobPayload = {}
    },

    confirmCreateNewMoveClientTask(payload) {
      if (payload.send_release_form) {
        this.confirmMoveClientTitle = "Confirm Request New Move Client Job"
        this.confirmMoveClientBody =
          "Are you sure you want to create this job? The Release Form must be signed by the requester before moving the selected client onto the new organization."
      } else {
        this.confirmMoveClientTitle = "Confirm Create New Move Client Job"
        this.confirmMoveClientBody = "Are you sure you want to create this job?"
      }
      this.confirmCreateJobModalOpen = true
      this.createJobPayload = payload
    },

    confirmDeleteMoveClientJob(job) {
      this.confirmDeleteMoveClientJobOpen = true
      this.deleteMoveClientBodyText = `Are you sure you want to cancel moving the client?<br><br>
        • <strong>Client Name:</strong> ${job.client.name} (ID: ${job.client.id})<br>
        • <strong>From:</strong> ${job.source_org.name} (ID: ${job.source_org.id})<br>
        • <strong>To:</strong> ${job.dest_org.name} (ID: ${job.dest_org.id})<br><br>
        Please confirm your action.`
      this.moveClientJobToDelete = job
    },

    handleGrowerContractSidebarClose() {
      this.showGenerateContractSidebar = false
    },

    async handleCreateNewMoveClientTask() {
      this.confirmCreateJobModalOpen = false
      await FieldsAPI.createMoveClientTask(this.createJobPayload)
        .then(resp => {
          this.moveClientJobs = resp.data
          this.showCreateNewMoveClientTaskModal = false
        })
        .catch(error => {
          this.modalTitleText = "Failed To Create A New Move Client Job"
          this.errorMessageForModal = [error.response.data.message]
          this.alertErrorModalOpen = true
        })
    },

    async fetchMoveClientJobs() {
      await FieldsAPI.getMoveClientTasks().then(resp => {
        this.moveClientJobs = resp.data
        this.moveClientJobs.forEach(job => {
          // If the job is pending, add the client to the pending list
          if (job.status !== "rejected" && job.status !== "completed") {
            if (!this.pendingClientJobs.includes(job.client.id)) {
              this.pendingClientJobs.push(job.client.id)
            }
          }
        })
      })
    },

    async renameClient() {
      let payload = {
        org_node_id: this.selectedRenameOrg,
        existing_client_id: this.selectedRenameClient,
        new_client_name: this.newClient,
      }
      await FieldsAPI.postRenameClientJob(payload).then(resp => {
        //this.items = []
        resp.data.forEach(job => {
          this.items.push({
            created_at: job.created_at,
            existing_client_name: job.existing_client_name,
            org_name: job.org_node.name,
            new_client_name: job.new_client,
            stage: job.stage,
          })
        })
        this.selectedRenameOrg = null
        this.selectedRenameClient = null
        this.newClient = null
      })
    },
    async fetchRenameClientJobs() {
      this.items = []
      await FieldsAPI.getRenameClientJobs().then(resp => {
        resp.data.forEach(job => {
          this.items.push({
            created_at: job.created_at,
            existing_client_name: job.existing_client_name,
            org_name: job.org_node.name,
            new_client_name: job.new_client,
            stage: job.stage,
          })
        })
      })
    },
    async lockAllPrograms() {
      let payload = { is_locked: true }
      await CarbonReadyAPI.postEAOrderLock(payload).then(resp => {
        this.EAOrders = resp.data
        this.snackbarText = "All Programs Locked!"
        this.snackbar = true
      })
    },

    async unLockAllPrograms() {
      let payload = { is_locked: false }
      await CarbonReadyAPI.postEAOrderLock(payload).then(resp => {
        this.EAOrders = resp.data
        this.snackbarText = "All Programs Unlocked!"
        this.snackbar = true
      })
    },

    async fetchEAOrders() {
      this.EAOrders = []
      await CarbonReadyAPI.getEAOrderForCorp().then(resp => {
        this.EAOrders = resp.data
      })
    },

    async fetchTargetCorpOptions() {
      await FieldsAPI.fetchTargetCorpOptions().then(({ data }) => {
        this.targetCorpOptions = data
      })
    },

    async fetchCounterSignEnrollment(item) {
      /*await CarbonReadyAPI.getCounterSignEnrollment(payload).then(resp => {
        console.log(resp.data)
        this.enrollment = resp.data

      })*/
      this.enrollmentID = item["id"]
      this.channelPartnerName = item["Channel Partner Name"]
      this.showGenerateContractSidebar = true
    },
    async fetchCounterSignApprovals() {
      this.fetchingApprovals = true
      await CarbonReadyAPI.getCounterSignApprovals().then(resp => {
        this.fetchingApprovals = false
        this.counterSignItems = resp.data
        this.counterSignItemsFiltered = this.counterSignItems
      })
    },

    async deleteJob() {
      this.confirmDeleteMoveClientJobOpen = false
      await FieldsAPI.deleteMoveClientTask(this.moveClientJobToDelete.id)
        .then(() => {
          this.moveClientJobs = this.moveClientJobs.filter(
            moveClientJob => moveClientJob.id !== job.id
          )
        })
        .catch(error => {
          this.modalTitleText = "Failed To Delete Move Client Job"
          this.errorMessageForModal = [error.response.data.message]
          this.alertErrorModalOpen = true
        })
    },
  },

  watch: {
    showGenerateContractSidebar() {
      if (this.showGenerateContractSidebar == false) {
        this.fetchCounterSignApprovals()
      }
    },
  },
  async mounted() {
    await this.fetchMoveClientJobs()
    await this.fetchRenameClientJobs()
    await this.fetchEAOrders()
    await this.fetchCounterSignApprovals()

    this.fetchIntervalID = setIntervalAsync(this.fetchMoveClientJobs, 10000)
  },

  destroyed() {
    if (this.fetchIntervalID) clearIntervalAsync(this.fetchIntervalID)
    if (this.fetchIntervalContractID)
      clearIntervalAsync(this.fetchIntervalContractID)
  },
}
</script>

<style scoped>
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
.countersign :deep(tr) {
  cursor: pointer;
}
h4 {
  font-weight: bold;
}
</style>
