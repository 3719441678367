<template>
  <modal
    :name="name"
    :scrollable="true"
    :clickToClose="false"
    :tooltip="tooltip"
    height="auto"
    styles="overflow:visible"
    :width="wide ? width : '600'"
    class="topz"
  >
    <div class="row header-row">
      <div v-if="tooltip" class="col-12 title-col">
        <h2 class="modal-title">
          {{ title }}
          <v-tooltip top max-width="300px"
            ><template v-slot:activator="{ on, attrs }"
              ><v-icon v-bind="attrs" v-on="on"
                >mdi-information</v-icon
              ></template
            ><span>{{ tooltip }}</span>
          </v-tooltip>
        </h2>
      </div>
      <div v-else class="col-12 title-col">
        <h2 class="modal-title">
          {{ title }}
        </h2>
      </div>
      <div class="additional-icon">
        <slot name="additional-icon"></slot>
      </div>
      <div class="close" @click="hide">×</div>

    </div>
    <slot />
  </modal>
</template>

<script>
export default {
  name: "ArvaModal",
  props: {
    name: { type: String },
    title: { type: String },
    wide: { type: Boolean },
    width: { type: Number },
    tooltip: { type: String },
  },

  methods: {
    show() {
      this.$modal.show(this.name)
    },

    hide() {
      this.$modal.hide(this.name)
      this.$emit("close-modal")
    },
  },

  mounted() {
    this.$modal.show(this.name)
  },
}
</script>

<style scoped>

.additional-icon {
  position: absolute;
  top: 5px;
  right: 70px;
  font-size: 32px;
  cursor: pointer;
}

.close {
  position: absolute;
  top: 15px;
  right: 40px;
  font-size: 32px;
  cursor: pointer;
}

.header-row {
  position: relative;
  border-bottom: 1px solid #e9ecef;
  margin: 0px;
}

.title-col {
  padding: 0px 10px;
}

.modal-title {
  padding: 15px;
  font-size: 19px;
  font-weight: 500;
}

.wide {
  width: 1000px;
}
.topz {
  z-index: 9999999999999999999999999999999;
}
</style>
