<template>
  <v-expansion-panel v-model="panelOpen">
    <v-expansion-panel-header class="px-5">
      <v-row no-gutter>
        <v-col class="d-flex align-center">
          <v-icon v-if="selected == null" color="black">mdi-account-plus-outline</v-icon>
          <v-icon v-else-if="!isValidated" color="black">mdi-account-alert-outline</v-icon>
          <v-icon v-else color="black">mdi-account-check-outline</v-icon>

          <div class="d-flex flex-wrap align-center ml-4">
            <p v-if="selected != null" class="w-100 ma-0">{{ `${firstName ? `${firstName} ${lastName}` : 'Not Specified'}` }}</p>
            <p v-else class="w-100 ma-0">New Profile</p>
            <p class="w-100 ma-0 lighter-color">{{ profileSubDetails }}</p>
          </div>
        </v-col>
      </v-row>

      <template v-slot:actions="props">
        <div class="d-flex align-center">
          <v-chip
            v-if="selected != null && !isValidated"
            outlined
            color="purple"
            class="mb-0 mr-8"
          >
            Missing Information
          </v-chip>

          <v-btn :ripple="false" v-if="props['open']" text class="border-none">
            <v-icon color="black">mdi-close</v-icon>
          </v-btn>
          <div v-else class="d-flex align-center">
            <v-btn :ripple="false">Edit</v-btn>
          </div>
        </div>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div>
        <!--<v-row class="mb-8" v-if="clientProfiles != null && clientProfiles.length > 0">
          <v-col cols="4">
            <v-autocomplete
              :items="items"
              item-text="poc_email"
              item-value="id"
              placeholder="Select Profile"
              label="Select Profile"
              :value="selectedProfileId"
              @change="handleChange"
              solo
            />
          </v-col>
        </v-row>-->
      
        <v-form class="mb-10 position-relative">
          <v-progress-circular v-if="loading" class="position-absolute" indeterminate :size="64" color="#79c61c" />
          <v-row :class="`mb-8 ${loading ? 'showing-spinner' : ''}`">
            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="First Name"
                placeholder="First Name"
                underlined
                autocomplete="home street-address"
                v-model="firstName"
              />
            </v-col>
      
            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Last Name"
                placeholder="Last Name"
                underlined
                v-model="lastName"
              />
            </v-col>

            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Title"
                placeholder="Title"
                underlined
                v-model="title"
              />
            </v-col>
          </v-row>
          
          <v-row :class="`mt-0 mb-6 ${loading ? 'showing-spinner' : ''}`">
            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Email Address"
                placeholder="Email Address"
                underlined
                autocomplete="home street-address"
                v-model="email"
              />
            </v-col>

            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Phone Number"
                placeholder="(000) 000-000"
                v-model="phoneNumber"
                :rules="[numberValidator]"
              >
                <template v-slot:prepend-inner>
                  <v-select
                    class="pa-0 ma-0 inner-select"
                    height="40px"
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <img :src="usIcon" class="pa-3 pr-0" />
                    </template>
  
                    <template v-slot:selection>yo</template>
                  </v-select>
                  <span class="d-flex align-center ml-1 mr-1">+1</span>
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Relationship to Primary Contact"
                placeholder="Relationship to Primary Contact"
                underlined
                v-model="relationshipToPrimaryContact"
              />
            </v-col>
          </v-row>

          <v-row :class="`mt-0 mb-8 ${loading ? 'showing-spinner' : ''}`">
            <v-col cols="6">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Street Address Line 1"
                placeholder="Street Address Line 1"
                underlined
                autocomplete="home street-address"
                v-model="streetAddress1"
              />
            </v-col>
      
            <v-col cols="6">
              <v-text-field
                hide-details
                height="40"
                class="mt-0 pa-0"
                label="Street Address Line 2 (Optional)"
                placeholder="Street Address Line 2 (Optional)"
                underlined
                autocomplete="home street-address"
                v-model="streetAddress2"
              />
            </v-col>
          </v-row>
      
          <v-row :class="`mt-0 mb-8 ${loading ? 'showing-spinner' : ''}`">
            <v-col cols="4">
              <v-text-field
                hide-details
                height="40"
                label="City"
                placeholder="City"
                underlined
                autocomplete="home street-address"
                v-model="city"
              />
            </v-col>
      
            <v-col cols="4">
              <div class="d-flex align-end">
                <v-autocomplete
                  :items="US_STATES_INVERTED"
                  label="State"
                  height="40"
                  hide-details
                  v-model="state"
                  solo
                >
                </v-autocomplete>
      
                <v-text-field
                  hide-details
                  height="40"
                  class="ml-6"
                  label="Zip"
                  placeholder="Zip"
                  underlined
                  autocomplete="home street-address"
                  v-model="zip"
                  :rules="[numberValidator]"
                />
              </div>
            </v-col>
      
            <v-col cols="4">
              <v-autocomplete
                :items="COUNTRIES"
                hide-details
                height="40"
                label="Country"
                placeholder="Country"
                autocomplete="home street-address"
                v-model="country"
              >
                <template v-slot:prepend-inner>
                  <v-select
                    class="pa-0 ma-0 inner-select"
                    height="40px"
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <img :src="usIcon" class="pa-3 pr-0" />
                    </template>
  
                    <template v-slot:selection>yo</template>
                  </v-select>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
  
        <v-row class="ma-0">
          <v-col class="d-flex align-center justify-space-between pa-5 lightblue-bg">
            <!--<h4 class="ma-0">All fields are required</h4>-->

            <div class="d-flex align-center">
              <v-btn v-if="selected != null" :ripple="false" solo color="white" @click="handleProfileRemoval" class="mr-3">
                Remove
              </v-btn>

              <v-btn v-if="selected == null" :ripple="false" solo color="white" @click="handleCancelCreation" class="mr-3">
                Cancel
              </v-btn>
  
              <v-btn :ripple="false" solo color="white" @click="selected == null ? handleCreateSave() : handleUpdateSave()">
                Save
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import usIcon from "@/assets/images/flags/us.png"
import { US_STATES_INVERTED, COUNTRIES } from "@/constants"
import { numberValidator, requiredValidator } from "@/utility"

const CREATE_PROFILE_OPTION = { poc_email: "Create New Profile", id: "createNew" }

export default {
  name: 'ClientProfileEditor',
  props: {
    includeNewOption: { type: Boolean, default: false },
    isCPProfile: { type: Boolean },
    clientProfiles: { type: Array },
    selected: { type: Object },
  },
  emits: [
    'modificationStatusChange',
    'addProfile',
    'swapProfile',
    'createProfile',
    'updateProfile',
    'removeProfile',
  ],
  data() {
    return {
      panelOpen: false,
      loading: false,
      selectedProfileId: null,
      isValidated: false,
      firstName: '',
      lastName: '',
      title: '',
      relationshipToPrimaryContact: '',
      phoneNumber: '',
      email: '',
      streetAddress1: '',
      streetAddress2: '',
      city: '',
      state: '',
      zip: '',
      country: 'US',
      US_STATES_INVERTED,
      COUNTRIES,
      usIcon,
      numberValidator,
      requiredValidator,
      CREATE_PROFILE_OPTION
    }
  },
  mounted() {
    this.updateLocalTrackers(this.selected)
  },
  computed: {
    items() {
      const i = []
      if (this.includeNewOption) i.push(CREATE_PROFILE_OPTION)
      i.push(...this.clientProfiles)
      return i
    },
    profileSubDetails() {
      let s = '';

      if (this.email) s += `${this.email},`
      if (this.phoneNumber) s += ` ${this.phoneNumber},`
      if (this.streetAddress1) s += ` ${this.streetAddress1},`
      if (this.streetAddress2) {
        s = s.slice(0, -2)
        s += ` ${this.streetAddress2},`
      }

      if (s.slice(-1) == ',') s = s.slice(0, -1)
      return s
    },
    profileHasBeenModified() {
      if (this.selected != null) {
        if (this.selectedProfileId != this.selected['id']) return true
        if (this.firstName != this.selected['first_name']) return true
        if (this.lastName != this.selected['last_name']) return true
        if (this.title != this.selected['title']) return true
        if (this.relationshipToPrimaryContact != this.selected['relationship_to_primary_contact']) return true
        if (this.phoneNumber != this.selected['phone_number']) return true
        if (this.email != this.selected['poc_email']) return true
        if (this.streetAddress1 != this.selected['street_address_1']) return true
        if (this.streetAddress2 != this.selected['street_address_2']) return true
        if (this.city != this.selected['city']) return true
        if (this.state != this.selected['state']) return true
        if (this.zip != this.selected['zip_code']) return true
      }

      return false
    },
  },
  methods: {
    handleCreateSave() {
      this.$emit('createProfile', {
        as_cp_profile: this.isCPProfile,
        profile_dataset: {
          first_name: this.firstName,
          last_name: this.lastName,
          title: this.title,
          relationship_to_primary_contact: this.relationshipToPrimaryContact,
          phone_number: this.phoneNumber,
          street_address_1: this.streetAddress1,
          street_address_2: this.streetAddress2,
          country: this.country,
          state: this.state,
          poc_email: this.email,
          city: this.city,
          zip_code: this.zip
        }
      })
    },
    handleUpdateSave() {
      this.$emit('updateProfile', {
        profile_id: this.selected['id'],
        profile_dataset: {
          first_name: this.firstName,
          last_name: this.lastName,
          title: this.title,
          relationship_to_primary_contact: this.relationshipToPrimaryContact,
          phone_number: this.phoneNumber,
          street_address_1: this.streetAddress1,
          street_address_2: this.streetAddress2,
          country: this.country,
          state: this.state,
          poc_email: this.email,
          city: this.city,
          zip_code: this.zip
        }
      })
    },
    handleProfileRemoval() {
      this.$emit('removeProfile', { remove_id: this.selected['id'], as_cp_profile: this.isCPProfile })
    },
    handleCancelCreation() {
      this.$emit('cancelCreation', { as_cp_profile: this.isCPProfile })
    },
    handleChange(e) {
      if (e == 'createNew') {
        if (this.selected != null && this.selected['id'] != null) {
          this.handleProfileRemoval()
          this.updateLocalTrackers(null)
        }
      }
      else if (e != null) {
        if (this.selected == null) {
          this.$emit('addProfile', {
            to_id: e,
            from_id: null,
            as_cp_profile: this.isCPProfile,
            profile_dataset: null,
          })
          this.selectedProfileId = null
        }
        else {
          this.$emit('swapProfile', {
            to_id: e,
            from_id: this.selected['id'],
            as_cp_profile: this.isCPProfile,
            profile_dataset: null,
          })
        }
      }
    },
    updateLocalTrackers(selectedProfile) {
      if (selectedProfile != null) {
        this.isValidated = selectedProfile['validated']
        this.firstName = selectedProfile['first_name']
        this.lastName = selectedProfile['last_name']
        this.title = selectedProfile['title']
        this.relationshipToPrimaryContact = selectedProfile['relationship_to_primary_contact']
        this.selectedProfileId = selectedProfile['id']
        this.phoneNumber = selectedProfile['phone_number']
        this.email = selectedProfile['poc_email']
        this.streetAddress1 = selectedProfile['street_address_1']
        this.streetAddress2 = selectedProfile['street_address_2']
        this.city = selectedProfile['city']
        this.state = selectedProfile['state']
        this.zip = selectedProfile['zip_code']
        this.country = selectedProfile['country'] || 'US'
      }
      else {
        this.panelOpen = true
        this.isValidated = false
        this.firstName = null
        this.lastName = null
        this.title = null
        this.relationshipToPrimaryContact = null
        this.selectedProfileId = null
        this.phoneNumber = null
        this.email = null
        this.streetAddress1 = null
        this.streetAddress2 = null
        this.city = null
        this.state = null
        this.zip = null
        this.country = 'US'
      }
    },
  },
  watch: {
    selected() {
      this.updateLocalTrackers(this.selected)
    },
    profileHasBeenModified(curr) {
      this.$emit('modificationStatusChange', {
        as_cp_profile: this.isCPProfile,
        profileId: this.selected['id'],
        status: curr
      })
    }
  }
}
</script>

<style scoped>
.confirm-contract-text {
  font-size: 18px;
  line-height: 28px;
}
p, h4 {
  color: #000000;
  letter-spacing: normal;
}
:deep(.v-list-item__title) {
  text-transform: none;
}
.showing-spinner {
  opacity: 0.5;
}
form .v-progress-circular {
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}
.lightblue-bg {
  background-color: #1976D21A;
}
.v-btn:not(.border-none) {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #E5E7EB;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04), 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}
:deep(.v-input__prepend-inner) {
  margin: 0;
}
:deep(.inner-select .v-input__prepend-inner) {
  align-self: center;
  padding: 0;
}
:deep(.inner-select .v-input__append-inner) {
  padding: 0;
  margin: 4px 0 0 4px
}
:deep(.inner-select) {
  width: 68px;
  background: #F3F4F6;
  pointer-events: none;
  opacity: 0.5;
}
.v-autocomplete :deep(.v-text-field__details) {
  display: none;
}
.v-autocomplete :deep(.v-input__slot) {
  margin-bottom: 0;
}
.lighter-color {
  color: #6B7280;
}
.v-expansion-panel-header p {
  line-height: 1.5;
}
.v-expansion-panel-header i {
  font-size: 28px;
}
</style>
