<template>
  <div class="row footer-row">
    <div class="col-12 btn-row d-flex justify-content-end">
      <v-btn
        v-if="isClosingWithoutSaving"
        class="ma-1"
        @click="$emit('on-close-without-saving')"
      >
        Close without Saving
      </v-btn>

      <v-btn
        class="ma-1"
        color="grey"
        @click="$emit('on-cancel')"
      >
        {{ cancelText }}
      </v-btn>

      <v-btn
        v-if="confirmText"
        class="ma-1"
        color="success"
        :disabled="disabled"
        @click="$emit('on-confirm')"
      >
        {{ confirmText }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArvaModalSaveFooter",
  props: {
    confirmText: { type: String },
    cancelText: { type: String },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    isClosingWithoutSaving: { type: Boolean },
  },
}
</script>

<style scoped>
.footer-row {
  border-top: 1px solid #e9ecef;
  margin: 0px;
}

.btn-row {
  padding: 10px 40px;
}

.btn-secondary {
  color: #fff;
}
</style>
