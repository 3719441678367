import Vue from 'vue'
import { EnrollmentGroups } from "@/store/modules"
import {
  parseGroupData,
  chunkIntoClientData,
  mergeFSSMetadata
} from "@/utility/contractGroups"
import {
  getRollup,
  listOverview,
  getFssMetadata,
  submitSupplyRequests,
  applyPendingSupplyStates,
  submitEvidencingLink,
  submitConfirmRequests,
  fetchClientProfiles,
  fetchGroupClientProfiles,
  addProfileToGroup,
  removeProfileFromGroup,
  swapGroupProfile,
  updateProfile,
  createProfile,
  getContractTabMetadata
} from "@/api/ContractGroupAPI"
import {
  NO,
  YES,
  CP_VIEW,
  ADD_PROFILE,
  REMOVE_PROFILE,
  UPDATE_PROFILE,
  SWAP_PROFILE,
  CREATE_PROFILE,
  ROLLUP_TABLE,
  FSS_INDIVIUAL,
  ENROLLMENT_TABLE,
  CLIENT_PROFILES,
} from "@/constants/contractGroups"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"


const tableLoadingState = {}
tableLoadingState[CLIENT_PROFILES] = { loading: true, error: null }
tableLoadingState[ENROLLMENT_TABLE] = { loading: true, error: null }
tableLoadingState[ROLLUP_TABLE] = { loading: true, error: null }
tableLoadingState[FSS_INDIVIUAL] = []

const state = {
  ...tableLoadingState,
  allClientProfiles: null,
  groupData: {},
  rollupData: [],
  clientData: {},
  programs: null,
  view: CP_VIEW,
  org: null
}

const getters = {
  [EnrollmentGroups.Getters.allUniqueRegions]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { regions }) => {
        for (const region of regions) {
          const found = US_STATES.find(({ text }) => text == region)
          if (found == null && !accum.includes("Canada")) accum.push(region)
          if (found != null && !accum.includes(found["value"]))
            accum.push(found["value"])
        }

        return accum
      }, [])
      .sort()
  },
  [EnrollmentGroups.Getters.allUniqueCrops]: state => {
    return Object.values(state.groupData)
      .reduce((accum, { crops }) => {
        for (const { id, harvest_type } of crops) {
          const found = accum.find(c => c["id"] == id)

          // look what they did to my boy
        if (found == null) accum.push({ id, harvest_type: null, itemKey: `${id}-null` })
        else if (
          found['id'] == 4
          && accum.find(c => c['id'] == 4 && c['harvest_type'] == 'Silage') == null
          && harvest_type == 'Silage'
        ) {
          accum.push({ id, harvest_type, itemKey: `${id}-${'Silage'}` })
        }
      }

        return accum
      }, [])
      .sort((a, b) =>
        CROP_DISPLAY_NAME[a["id"]].localeCompare(CROP_DISPLAY_NAME[b["id"]])
      )
  },
}

const mutations = {
  [EnrollmentGroups.Mutations.setPrograms](state, programs) {
    state.programs = Object.freeze(programs)
  },
  [EnrollmentGroups.Mutations.setClientProfiles](state, profiles) {
    state.allClientProfiles = Object.freeze(profiles)
  },
  [EnrollmentGroups.Mutations.setOrg](state, orgId) {
    state.org = orgId
  },
  [EnrollmentGroups.Mutations.setView](state, view) {
    state.view = view
  },
  [EnrollmentGroups.Mutations.setRollupData](state, rollupData) {
    Vue.set(state, "rollupData", rollupData)
  },
  [EnrollmentGroups.Mutations.setAllGroupData](state, groupData) {
    state.groupData = Object.freeze(groupData)
  },
  [EnrollmentGroups.Mutations.setAllClientData](state, clientData) {
    state.clientData = Object.freeze(clientData)
  },
  [EnrollmentGroups.Mutations.setFssLoading](state, { fssIds, loading }) {
    const newLoading = [...state[FSS_INDIVIUAL]]

    if (loading) {
      for (const fssId of fssIds)
        if (!newLoading.includes(fssId)) newLoading.push(fssId)
    } else {
      for (const fssId of fssIds)
        if (newLoading.includes(fssId))
          newLoading.splice(newLoading.indexOf(fssId), 1)
    }

    state[FSS_INDIVIUAL] = Object.freeze(newLoading)
  },
  [EnrollmentGroups.Mutations.setFssMetadata](state, { groupId, data }) {
    const newState = { ...state.groupData }
    const newGroupData = newState[groupId]

    for (const [fssId, practices, farmName, thumbnail, orderIds] of data) {
      newGroupData["fieldsupply"].find(({ id }) => id == fssId)["metadata"] = {
        practices,
        farmName,
        thumbnail,
        orderIds
      }
    }

    state.groupData = Object.freeze(newState)

    const newClientState = { ...state.clientData }
    for (const clientId in newClientState) {
      for (const [fssId, practices, farmName, thumbnail, orderIds] of data) {
      const found = newClientState[clientId]['fieldsupply'].find(({ id }) => fssId == id)
      if (found != null) {
          found["metadata"] = {
            practices,
            farmName,
            thumbnail,
            orderIds
          }
        }
      }
    }

    state.clientData = Object.freeze(newClientState)
  },
  [EnrollmentGroups.Mutations.setIndividualGroupData](state, groupData) {
    state.groupData = Object.freeze({ ...state.groupData, ...groupData })
  },
  [EnrollmentGroups.Mutations.setGroupCanGenerateContract](
    state,
    groupsCanGenerateContract
  ) {
    for (const groupId in groupsCanGenerateContract) {
      const canGenerate = groupsCanGenerateContract[groupId]
      if (state.groupData.hasOwnProperty(groupId)) {
        Vue.set(state.groupData[groupId], "can_generate_contract", canGenerate)
      }
    }
  },
  [EnrollmentGroups.Mutations.setGroupHasMaxCap](state, groupsHasMaxCap) {
    for (const groupId in groupsHasMaxCap) {
      const hasMaxCap = groupsHasMaxCap[groupId]
      if (state.groupData.hasOwnProperty(groupId)) {
        Vue.set(state.groupData[groupId], "has_maxcap", hasMaxCap)
      }
    }
  },
  [EnrollmentGroups.Mutations.setGroupSupplyChanges](
    state,
    { groupId, supplyState, fssIds }
  ) {
    const idx = state.groupData.findIndex(g => g["id"] == groupId)
    if (idx != -1) {
      Vue.set(state.groupData[idx]["supplyChanges"], supplyState, fssIds)
    }
  },
  [EnrollmentGroups.Mutations.setTableLoadingState](state, { target, loading, error = null }) {
    Vue.set(state, target, { loading, error })
  }
}

const actions = {
  [EnrollmentGroups.Actions.listOverview]({
    state,
    commit,
    rootState,
    dispatch,
  }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      listOverview({ org_node_id: organization.id, year })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setOrg, organization.id)

          const parsedGroupData = parseGroupData(data, state.rollupData)
          commit(EnrollmentGroups.Mutations.setAllGroupData, parsedGroupData)

          const clientChunkedData = chunkIntoClientData(parsedGroupData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          resolve()
        })
        .catch(error => {
          console.log("Error fetching group data overview: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.getContractTabMetadata]({
    commit,
    state,
    rootState,
  }) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })

      const group_ids = Object.keys(state.groupData)

      getContractTabMetadata({ group_ids }).then(
        ({
          data: {
            program_ids,
            fss_program_map,
            groups_can_generate_contract,
            groups_has_maxcap,
          },
        }) => {
          commit(
            EnrollmentGroups.Mutations.setGroupCanGenerateContract,
            groups_can_generate_contract
          )
          commit(
            EnrollmentGroups.Mutations.setGroupHasMaxCap,
            groups_has_maxcap
          )
          commit(EnrollmentGroups.Mutations.setPrograms, program_ids)

          const curGroupData = { ...state.groupData }
          for (const groupId in curGroupData) {
            for (const fss of curGroupData[groupId]["fieldsupply"]) {
              fss["matched_program_id"] = fss_program_map[fss["id"]]
            }
          }

          commit(EnrollmentGroups.Mutations.setAllGroupData, curGroupData)

          const clientChunkedData = chunkIntoClientData(curGroupData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          resolve()
        }
      )
    })
  },
  [EnrollmentGroups.Actions.fetchFssMetadata](
    { commit, state, rootState },
    { groupId, clientId }
  ) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      const groupIds = []

      if (groupId == null && clientId != null) {
        // fetch metadata from a client-based view... just find all legal entity
        // ids that the client's fss exist in and get those
        for (const fss of state.clientData[clientId]["fieldsupply"]) {
          for (const gId in state.groupData) {
            if (groupIds.includes(gId)) continue

            if (
              state.groupData[gId]["fieldsupply"].find(
                ({ id }) => id == fss["id"]
              ) != null
            ) {
              groupIds.push(gId)
            }
          }
        }
      } else groupIds.push(groupId)

      Promise.all(
        groupIds.map(gId => {
          return new Promise((innerResolve, innerReject) => {
            const fssIds = state.groupData[gId]["fieldsupply"].map(
              ({ id }) => id
            )
            commit(EnrollmentGroups.Mutations.setTableLoadingState, {
              target: ENROLLMENT_TABLE,
              loading: true,
            })

            getFssMetadata({
              fss_ids: fssIds,
              org_node_id: organization.id,
              year,
            })
              .then(({ data }) => {
                commit(EnrollmentGroups.Mutations.setFssMetadata, {
                  groupId: gId,
                  data,
                })
                innerResolve()
              })
              .catch(error => {
                console.log("Error fetching group metadata: ", error)
                innerReject(error)
              })
          })
        })
      )
        .then(_ => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          resolve()
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.fetchRollup](
    { commit, rootState, dispatch },
    { fetchOverview }
  ) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ROLLUP_TABLE,
        loading: true,
      })
      getRollup({ org_node_id: organization.id, year })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setRollupData, data)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ROLLUP_TABLE,
            loading: false,
          })

          if (!fetchOverview) {
            resolve()
          } else {
            dispatch(EnrollmentGroups.Actions.listOverview).then(() =>
              resolve()
            )
          }
        })
        .catch(error => {
          console.log("Error fetching program rollup: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ROLLUP_TABLE,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitSupplyChanges]({ commit, dispatch, state }, { clientId, cropId=null }) {
    const fssToSubmit = state.clientData[clientId]['fieldsupply']
      .filter(({ crop_id }) => cropId == null || cropId == crop_id)
      .map(({ id }) => id)

    commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds: fssToSubmit, loading: true })
    commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true })

    return new Promise((resolve, reject) => {
      submitSupplyRequests({ fssToSubmit })
        .then(({ data }) => {
          for (const groupId in data) {
            const toParse = {}
            toParse[groupId] = data[groupId]
  
            toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
              toParse[groupId]["fieldsupply"],
              state.groupData[groupId]["fieldsupply"]
            )
            const parsedData = parseGroupData(toParse, state.rollupData)
            commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
          }
  
          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)
          commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds: fssToSubmit, loading: false })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false })

          dispatch(EnrollmentGroups.Actions.fetchRollup, { fetchOverview: false })

          resolve()
        })
        .catch(error => {
          console.log("Error fetching group data after supply submission", error)
          commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds: fssToSubmit, loading: false })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false, error })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.applyPendingFssState]({ commit, state }, { supplyChanges }) {
    const fssIds = []
    fssIds.push(...supplyChanges[YES])
    fssIds.push(...supplyChanges[NO])
    commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds, loading: true })

    return new Promise((resolve, reject) => {
      applyPendingSupplyStates({ supplyChanges })
      .then(({ data }) => {
        for (const groupId in data) {
          const toParse = {}
          toParse[groupId] = data[groupId]

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
        }

        const newAllGroupsParsedData = state.groupData
        const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
        commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)
        commit(EnrollmentGroups.Mutations.setFssLoading, { fssIds, loading: false, })
        resolve()
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  [EnrollmentGroups.Actions.submitEvidencingLink](
    { commit, state },
    { group_id, updated_link_obj }
  ) {
    commit(EnrollmentGroups.Mutations.setTableLoadingState, {
      target: ENROLLMENT_TABLE,
      loading: true,
    })
    submitEvidencingLink({ group_id, updated_link_obj })
      .then(() => {
        const newGroupData = {}
        newGroupData[group_id] = {
          ...state.groupData[group_id],
          evidencing_links: updated_link_obj,
        }
        commit(EnrollmentGroups.Mutations.setIndividualGroupData, newGroupData)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: ENROLLMENT_TABLE,
          loading: false,
        })
      })
      .catch(error => {
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: ENROLLMENT_TABLE,
          loading: false,
          error,
        })
      })
  },
  [EnrollmentGroups.Actions.confirmSupplyChanges]({ commit, state }, { fss_to_confirm }) {
    commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: true })
    submitConfirmRequests({ fss_to_confirm })
      .then(({ data }) => {
        for (const groupId in data) {
          const toParse = {}
          toParse[groupId] = data[groupId]

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)
        }

        const newAllGroupsParsedData = state.groupData
        const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
        commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, { target: ENROLLMENT_TABLE, loading: false })
        resolve()
      })
      .catch(err => console.log("error confirming requests:", err))
  },
  [EnrollmentGroups.Actions.fetchClientProfiles](
    { rootState, commit },
    { group_id }
  ) {
    return new Promise((resolve, reject) => {
      const { year, organization } = rootState.Organization
      if (year == null || organization == null || !("id" in organization)) {
        reject(`Organization or year is null: ${organization}, ${year}`)
        return
      }

      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })
      new Promise((innerResolve, innerReject) => {
        if (state.allClientProfiles != null) innerResolve()
        else {
          fetchClientProfiles()
            .then(({ data }) => {
              commit(EnrollmentGroups.Mutations.setClientProfiles, data)
              innerResolve()
            })
            .catch(error => {
              console.log("Error fetching client profiles: ", error)
              commit(EnrollmentGroups.Mutations.setTableLoadingState, {
                target: CLIENT_PROFILES,
                loading: false,
                error,
              })
              innerReject(error)
            })
        }
      })
        .then(() => {
          if (group_id == null) {
            commit(EnrollmentGroups.Mutations.setTableLoadingState, {
              target: CLIENT_PROFILES,
              loading: false,
            })
            resolve()
            return
          }
          fetchGroupClientProfiles({ group_id }).then(({ data }) => {
            const toParse = {}
            toParse[group_id] = state.groupData[group_id]
            toParse[group_id]["cp_profiles"] = data.filter(
              ({ profile_type }) => profile_type == "cp"
            )
            toParse[group_id]["grower_profiles"] = data.filter(
              ({ profile_type }) => profile_type == "grower"
            )
            toParse[group_id]["landowner_profiles"] = data.filter(
              ({ profile_type }) => profile_type == "landowner"
            )
            const parsedData = parseGroupData(toParse, state.rollupData)
            commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

            const newAllGroupsParsedData = state.groupData
            const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
            commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)
            
            commit(EnrollmentGroups.Mutations.setTableLoadingState, {
              target: CLIENT_PROFILES,
              loading: false,
            })
            resolve()
          })
        })
        .catch(error => {
          console.log("Error 2 fetching profiles: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },

  [EnrollmentGroups.Actions.fetchGroupClientProfiles](
    { commit, state },
    { group_id }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })
      fetchGroupClientProfiles({ group_id })
        .then(({ data }) => {
          const toParse = {}
          toParse[group_id] = state.groupData[group_id]
          toParse[group_id]["cp_profiles"] = data.filter(
            ({ profile_type }) => profile_type == "cp"
          )
          toParse[group_id]["grower_profiles"] = data.filter(
            ({ profile_type }) => profile_type == "grower"
          )
          toParse[group_id]["landowner_profiles"] = data.filter(
            ({ profile_type }) => profile_type == "landowner"
          )
          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          resolve()
        })
        .catch(error => {
          console.log("Error fetching group client profiles: ", error)
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },

  [EnrollmentGroups.Actions.editClientProfiles](
    { commit, state },
    { type, group_id, payload }
  ) {
    let api = null

    if (type == ADD_PROFILE) api = addProfileToGroup
    if (type == REMOVE_PROFILE) api = removeProfileFromGroup
    if (type == UPDATE_PROFILE) api = updateProfile
    if (type == SWAP_PROFILE) api = swapGroupProfile
    if (type == CREATE_PROFILE) api = createProfile

    commit(EnrollmentGroups.Mutations.setTableLoadingState, {
      target: CLIENT_PROFILES,
      loading: true,
    })
    return api(payload)
      .then(({ data }) => {
        const toParse = {}
        toParse[group_id] = data

        toParse[group_id]['fieldsupply'] = mergeFSSMetadata(
          toParse[group_id]["fieldsupply"],
          state.groupData[group_id]["fieldsupply"]
        )

        const parsedData = parseGroupData(toParse, state.rollupData)
        commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

        const newAllGroupsParsedData = state.groupData
        const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
        commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: CLIENT_PROFILES,
          loading: false,
        })
        return parsedData
      })
      .catch(error => {
        console.log("error editing client profiles", error)
        commit(EnrollmentGroups.Mutations.setTableLoadingState, {
          target: CLIENT_PROFILES,
          loading: false,
          error,
        })
      })
  },
  [EnrollmentGroups.Actions.submitContractGeneration](
    { commit, state },
    { groupId, landOwnership }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })

      CarbonReadyAPI.postGenerateInsetContract({ groupId, landOwnership })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitVoidContract](
    { commit, state },
    { groupId, enrollmentId, voidedReason }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })
      CarbonReadyAPI.postVoidContract({ groupId, enrollmentId, voidedReason })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitContractEsign](
    { commit, state },
    { groupId, enrollmentId }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })

      CarbonReadyAPI.postESignContract({ groupId, enrollmentId })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
  [EnrollmentGroups.Actions.submitContractAdditionalEsign](
    { commit, state },
    { groupId, enrollmentId }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })

      CarbonReadyAPI.postAdditionalESignContract({
        groupId,
        enrollmentId,
      })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },

  [EnrollmentGroups.Actions.submitContractCounterEsign](
    { commit, state },
    { groupId, enrollmentId }
  ) {
    return new Promise((resolve, reject) => {
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: ENROLLMENT_TABLE,
        loading: true,
      })
      commit(EnrollmentGroups.Mutations.setTableLoadingState, {
        target: CLIENT_PROFILES,
        loading: true,
      })

      CarbonReadyAPI.postCounterESignContract({
        groupId,
        enrollmentId,
      })
        .then(({ data }) => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
          })
          const toParse = {}
          toParse[groupId] = data

          toParse[groupId]['fieldsupply'] = mergeFSSMetadata(
            toParse[groupId]["fieldsupply"],
            state.groupData[groupId]["fieldsupply"]
          )

          const parsedData = parseGroupData(toParse, state.rollupData)
          commit(EnrollmentGroups.Mutations.setIndividualGroupData, parsedData)

          const newAllGroupsParsedData = state.groupData
          const clientChunkedData = chunkIntoClientData(newAllGroupsParsedData)
          commit(EnrollmentGroups.Mutations.setAllClientData, clientChunkedData)

          resolve(data)
        })
        .catch(error => {
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: ENROLLMENT_TABLE,
            loading: false,
            error,
          })
          commit(EnrollmentGroups.Mutations.setTableLoadingState, {
            target: CLIENT_PROFILES,
            loading: false,
            error,
          })
          reject(error)
        })
    })
  },
}

export default {
    state,
    mutations,
    getters,
    actions
  }
