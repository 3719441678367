export const Dashboard = {
  Actions: {
    fetchAgts: "Dashboard/fetchAgts",
    fetchAgtPerformance: "Dashboard/fetchAgtPerformance",
    fetchAgtSummary: "Dashboard/fetchAgtSummary",
    fetchSeedCompanies: "Dashboard/fetchSeedCompanies",
    setSelectedHybrid: "Dashboard/setSelectedHybrid",
    setSelectedCrop: "Dashboard/setSelectedCrop",
    setTargetYield: "Dashboard/setTargetYield",
    updateSeedingRate: "Dashboard/updateSeedingRate",
    updateCarbonCreditDollarValue: "Dashboard/updateCarbonCreditDollarValue",
  },
  Getters: {
    getRoiSummary: "Dashboard/getRoiSummary",
    getAgtPerformanceSummary: "Dashboard/getAgtPerformanceSummary",
    getAgtSummary: "Dashboard/getAgtSummary",
    getCropDesignerSummary: "Dashboard/getCropDesignerSummary",
    getCropAgtVarietySummary: "Dashboard/getCropAgtVarietySummary",
    getTopPerformingVarieties: "Dashboard/getTopPerformingVarieties",
  },
  Mutations: {
    initializeCropDesignerSettings: "Dashboard/initializeCropDesignerSettings",
    setAgts: "Dashboard/setAgts",
    setAgtSelectedCrop: "Dashboard/setAgtSelectedCrop",
    setAgtPerformance: "Dashboard/setAgtPerformance",
    setAgtSummary: "Dashboard/setAgtSummary",
    setCropDesignerHybrid: "Dashboard/setCropDesignerHybrid",
    setNextCrop: "Dashboard/setNextCrop",
    setSeedCompanies: "Dashboard/setSeedCompanies",
    setSeedingRate: "Dashboard/setSeedingRate",
    setTargetYield: "Dashboard/setTargetYield",
    setCarbonCreditDollarValue: "Dashboard/setCarbonCreditDollarValue",
    setCIScore: "Dashboard/setCIScore",
  },
}

export const Defaults = {
  Actions: {
    fetchDefaults: "Defaults/fetchDefaults",
    fetchFieldSettings: "Defaults/fetchFieldSettings",
    fetchNewFieldSettings: "Defaults/fetchNewFieldSettings",
    fetchCarbonSettingsByField: "Defaults/fetchCarbonSettingsByField",
    fetchOpsSettings: "Defaults/fetchOpsSettings",
    fetchFertSettings: "Defaults/fetchFertSettings",
    fetchPracticeChoices: "Defaults/fetchPracticeChoices",
    updateMultipleFieldSettings: "Defaults/updateMultipleFieldSettings",
    updateFieldSettingsByFieldYear: "Defaults/updateFieldSettingsByFieldYear",
    saveDataSpreadsheet: "Defaults/saveDataSpreadsheet",
    updateLiming: "Defaults/updateLiming",
    updateTillage: "Defaults/updateTillage",
    updateIrrigation: "Defaults/updateIrrigation",
    updateFertilizer: "Defaults/updateFertilizer",
    updateProtection: "Defaults/updateProtection",
    updateHarvest: "Defaults/updateHarvest",
    updateCoverCropping: "Defaults/updateCoverCropping",
    updateSoilSampling: "Defaults/updateSoilSampling",
    updateWizardDataCompletionSummary: "Defaults/updateWizardDataCompletionSummary",
  },
  Getters: {
    getDefaultsList: "Defaults/getDefaultsList",
    getOneFieldSettings: "Defaults/getOneFieldSettings",
    getNewFieldSettings: "Defaults/getNewFieldSettings",
    getRecentWizardData: "Defaults/getRecentWizardData",
    getPracticeChoices: "Defaults/getPracticeChoices",
    getHarvestTypeChoices: "Defaults/getHarvestTypeChoices",
    getWizardDataCompletionSummary: "Defaults/getWizardDataCompletionSummary",
  },
  Mutations: {
    setDefaults: "Defaults/setDefaults",
    setFieldSettings: "Defaults/setFieldSettings",
    setNewFieldSettings: "Defaults/setNewFieldSettings",
    addToFieldSettings: "Defaults/addToFieldSettings",
    cleanFieldSettings: "Defaults/cleanFieldSettings",
    setOpsSettings: "Defaults/setOpsSettings",
    setFertSettings: "Defaults/setFertSettings",
    addFieldSettingRow: "Defaults/addFieldSettingRow",
    setPracticeChoices: "Defaults/setPracticeChoices",
    updateFieldSetting: "Defaults/updateFieldSetting",
    deleteNewFieldSetting: "Defaults/deleteNewFieldSetting",
    updateNewFieldSetting: "Defaults/updateNewFieldSetting",
    saveDataSpreadsheet: "Defaults/saveDataSpreadsheet",
    updateMultipleNewFieldSetting: "Defaults/updateMultipleNewFieldSetting",
    updatePlanting: "Defaults/updatePlanting",
    spreadsheetMutate: "Defaults/spreadsheetMutate",
    resetNeedsSave: "Defaults/resetNeedsSave",
    setRecentWizardData: "Defaults/setRecentWizardData",
    resetRecentWizardData: "Defaults/resetRecentWizardData",
    setWizardDataCompletionSummary: "Defaults/setWizardDataCompletionSummary",
  },
}

export const Enrollment = {
  Mutations: {
    setPreviousEnrollment: "Enrollment/setPreviousEnrollment",
    clearPreviousEnrollment: "Enrollment/clearPreviousEnrollment",
  },
}

export const Fertilizer = {
  Actions: {
    fetchFertilizers: "Fertilizer/fetchFertilizers",
  },
  Getters: {
    getFertilizers: "Fertilizer/getFertilizers",
  },
  Mutations: {
    setFertilizers: "Fertilizer/setFertilizers",
  },
}

export const Fields = {
  Actions: {
    batchDelete: "Fields/batchDelete",
    batchUpdate: "Fields/batchUpdate",
    ignoreGeoPackages: "Fields/ignoreGeoPackages",
    fetchField: "Fields/fetchField",
    fetchFields: "Fields/fetchFields",
    fetchFieldsSkinny: "Fields/fetchFieldsSkinny",
    fetchGeoPkgsByDataType: "Fields/fetchGeoPkgsByDataType",
    updateRegenPractice: "Fields/updateRegenPractice",
    updateUserSustainability: "Fields/updateUserSustainability",
    clearUserSustainability: "Fields/clearUserSustainability",
    fetchFeatureFlags: "Fields/fetchFeatureFlags",
  },
  Getters: {
    getFields: "Fields/getFields",
    getFeatureFlags: "Fields/getFeatureFlags",
  },
  Mutations: {
    setFields: "Fields/setFields",
    updateFields: "Fields/updateFields",
    updateRegenPractice: "Fields/updateRegenPractice",
    updateUserSustainability: "Fields/updateUserSustainability",
    clearUserSustainability: "Fields/clearUserSustainability",
    setFeatureFlags: "Fields/setFeatureFlags",
  },
}

export const Filter = {
  Getters: {
    getCrops: "Filter/getCrops",
    getDashboardCrops: "Filter/getDashboardCrops",
    getSelectedCrops: "Filter/getSelectedCrops",
    getSelectedCropsCount: "Filter/getSelectedCropsCount",
    getOrgNodes: "Filter/getOrgNodes",
    getOpen: "Filter/getOpen",
    getSelectedOrgNodeCount: "Filter/getSelectedOrgNodeCount",
    getClients: "Filter/getClients",
    getSelectedClientsCount: "Filter/getSelectedClientsCount",
    getFarms: "Filter/getFarms",
    getSelectedFarmsCount: "Filter/getSelectedFarmsCount",
    getFields: "Filter/getFields",
    getFieldForCheck: "Filter/getFieldForCheck",
    getSelectedFields: "Filter/getSelectedFields",
    getSelectedFieldsCount: "Filter/getSelectedFieldsCount",
    getSelectedFieldsAcreage: "Filter/getSelectedFieldsAcreage",
  },
  Mutations: {
    clearAll: "Filter/clearAll",
    closeDropdowns: "Filter/closeDropdowns",
    initialize: "Filter/initialize",
    selectAll: "Filter/selectAll",
    selectAllOfType: "Filter/selectAllOfType",
    clearAllOfType: "Filter/clearAllOfType",
    setClosed: "Filter/setClosed",
    setOrgNodes: "Filter/setOrgNodes",
    setSelectedFields: "Filter/setSelectedFields",
    setFields: "Filter/setFields",
    toggleItem: "Filter/toggleItem",
    toggleOpen: "Filter/toggleOpen",
  },
}

export const Map = {
  Actions: {
    fetchMapData: "Map/fetchMapData",
    updateAgtCrops: "Map/updateAgtCropsAction",
  },
  Getters: {
    getIncludeDefaults: "Map/getIncludeDefaults",
    getArvaLayers: "Map/getArvaLayers",
    getDataLayers: "Map/getDataLayers",
    getAgtCrops: "Map/getAgtCrops",
    getShowCropIcons: "Map/getShowCropIcons",
    getCropIconOpacity: "Map/getCropIconOpacity",
    getOpacities: "Map/getOpacities",
    getElevationScale: "Map/getElevationScale",
    getActiveFerts: "Map/getActiveFerts",
    getShowFert: "Map/getShowFert",
    getShowNewFertOptions: "Map/getShowNewFertOptions",
    getActiveSeeds: "Map/getActiveSeeds",
    getShowSeed: "Map/getShowSeed",
    getShowNewSeedOptions: "Map/getShowNewSeedOptions",
    getActiveHarvests: "Map/getActiveHarvests",
    getShowHarvest: "Map/getShowHarvest",
    getShowNewHarvestOptions: "Map/getShowNewHarvestOptions",
    getShowMicroclimate: "Map/getShowMicroclimate",
    getMicroclimateOpacity: "Map/getMicroclimateOpacity",
  },
  Mutations: {
    resetState: "Map/resetState",
    autoZoom: "Map/autoZoom",
    disableCropIcons: "Map/disableCropIcons",
    setActiveLayerIds: "Map/setActiveLayerIds",
    setDeck: "Map/setDeck",
    setFieldBounds: "Map/setFieldBounds",
    setIsFetching: "Map/setIsFetching",
    setVoxelBounds: "Map/setVoxelBounds",
    setMicroclimates: "Map/setMicroclimates",
    setMVTYear: "Map/setMVTYear",
    setMVTURLs: "Map/setMVTURLs",

    setDeactivatedDataLayers: "Map/setDeactivatedDataLayers",
    setOrgAgtCrops: "Map/setOrgAgtCrops",
    setOrganizationId: "Map/setOrganizationId",
    setHoverData: "Map/setHoverData",
    setRightClickData: "Map/setRightClickData",
    setShowFert: "Map/setShowFert",
    setShowSeed: "Map/setShowSeed",
    setShowHarvest: "Map/setShowHarvest",
    togglePitchMode: "Map/togglePitchMode",
    updateIncludeDefaults: "Map/updateIncludeDefaults",
    updateAgtCrops: "Map/updateAgtCrops",
    updateMap: "Map/updateMap",
    updateSingleFieldExtentBounds: "Map/updateSingleFieldExtentBounds",
    updateShowCropIcons: "Map/updateShowCropIcons",
    updateCropIconOpacity: "Map/updateCropIconOpacity",
    updateOpacity: "Map/updateOpacity",
    updateElevationScale: "Map/updateElevationScale",
    updateBearingPitch: "Map/updateBearingPitch",
    updateZoom: "Map/updateZoom",
    updateShowMicroclimate: "Map/updateShowMicroclimate",
    updateMicroclimateOpacity: "Map/updateMicroclimateOpacity",
  },
}

export const Notifications = {
  Actions: {
    fetchAllNotifications: "Notifications/fetchAllNotifications",
    fetchAlertNotifications: "Notifications/fetchAlertNotifications",
    fetchTaskNotifications: "Notifications/fetchTaskNotifications",
    initializeNotifications: "Notifications/initializeNotifications",
    syncUserFirebaseTokens: "Notifications/syncUserFirebaseTokens",
  },
  Mutations: {
    setAlertNotifications: "Notifications/setAlertNotifications",
    addTemporaryAlertNotification:
      "Notifications/addTemporaryAlertNotification",
    setTaskNotifications: "Notifications/setTaskNotifications",
    addTemporaryTaskNotification: "Notifications/addTemporaryTaskNotification",
    setLoadingStatus: "Notifications/setLoadingStatus",
    setNotificationEnableState: "Notifications/setNotificationEnableState",
    setNotificationRequestState: "Notifications/setNotificationRequestState",
    setIncompleteTasks: "Notifications/setIncompleteTasks",
    setUnreadAlerts: "Notifications/setUnreadAlerts",
  },
  Getters: {
    getAggregateAlertNotifications:
      "Notifications/getAggregateAlertNotifications",
    getAggregateTaskNotifications:
      "Notifications/getAggregateTaskNotifications",
  },
}

export const Organization = {
  Actions: {
    updateYear: "Organization/updateYear",
    initialFetch: "Organization/initialFetch",
  },
  Getters: {
    getYear: "Organization/getYear",
  },
  Mutations: {
    setOrganization: "Organization/setOrganization",
    setYear: "Organization/setYear",
    setShowBase: "Organization/setShowBase",
    setShowSustainability: "Organization/setShowSustainability",
    setShowInsetEnrollment: "Organization/setShowInsetEnrollment",
    setShowImagery: "Organization/setShowImagery",
    setShowRxZones: "Organization/setShowRxZones",
    setShowCropPlanner: "Organization/setShowCropPlanner",
    setShowSeedSelector: "Organization/setShowSeedSelector",
  },
}

export const Placements = {
  Actions: {
    fetchCropsWithTrials: "Placements/fetchCropsWithTrials",
    fetchSeedPlacementPlans: "Placements/fetchSeedPlacementPlans",
  },
  Getters: {
    getPlacementsList: "Placements/getPlacementsList",
  },
  Mutations: {
    setCropsWithTrials: "Placements/setCropsWithTrials",
    setPlacements: "Placements/setPlacements",
  },
}

export const SoilSampling = {
  Actions: {
    fetchSamplePlans: "SoilSampling/fetchSamplePlans",
  },
  Mutations: {
    setSamplingPlans: "SoilSampling/setSamplingPlans",
  },
}

export const Theme = {
  Actions: {
    resetThemeState: "Theme/resetThemeState",
  },
  Getters: {},
  Mutations: {
    resetState: "Theme/resetState",
    setSidePanelOpen: "Theme/setSidePanelOpen",
    setSideNavOpen: "Theme/setSideNavOpen",
    setInitialDataFetch: "Theme/setInitialDataFetch",
  },
}

export const Uploads = {
  Actions: {
    create: "Uploads/create",
    updateBoundary: "Uploads/updateBoundary",
    fetch: "Uploads/fetch",
    fetchJohnDeereStage: "Uploads/fetchJohnDeereStage",
    listDatasetTypes: "Uploads/listDatasetTypes",
  },
  Mutations: {
    setAccountConnections: "Uploads/setAccountConnections",
    setDatasetTypes: "Uploads/setDatasetTypes",
    setDetailsModalUploadID: "Uploads/setDetailsModalUploadID",
    setDetailsModalUploadFieldID: "Uploads/setDetailsModalUploadFieldID",
    setJohnDeereStage: "Uploads/setJohnDeereStage",
    setMappingModalUploadID: "Uploads/setMappingModalUploadID",
    setReviewModalUploadID: "Uploads/setReviewModalUploadID",
    setUploadPercentage: "Uploads/setUploadPercentage",
    setUploads: "Uploads/setUploads",
  },
}

export const User = {
  Actions: {
    authenticate: "User/authenticate",
    logInToAdmin: "User/logInToAdmin",
    fetchUser: "User/fetchUser",
  },
  Getters: {},
  Mutations: {
    setToken: "User/setToken",
    setUser: "User/setUser",
    setAuthErrors: "User/setAuthErrors",
    setLoading: "User/setLoading",
    setDataOpen: "User/setDataOpen",
    setFieldsOpen: "User/setFieldsOpen",
    setPlanningOpen: "User/setPlanningOpen",
    setInsightsOpen: "User/setInsightsOpen",
    setImageryOpen: "User/setImageryOpen",
    setSustainOpen: "User/setSustainOpen",
    setInsetOpen: "User/setInsetOpen",
    setOffsetOpen: "User/setOffsetOpen",
    setZonesOpen: "User/setZonesOpen",
    setUserManagement: "User/setUserManagement",
    setOrgSharingTransactions: "User/setOrgSharingTransactions",
    removeSelectedAssignedOrg: "User/removeSelectedAssignedOrg",
    addSelectedAssignedOrg: "User/addSelectedAssignedOrg",
    updateSelectedAssignedOrg: "User/updateSelectedAssignedOrg",
    setUserRole: "User/setUserRole",
    setUserAssignedOrg: "User/setUserAssignedOrg",
    setUserSharedOrg: "User/setUserSharedOrg",
    logout: "User/logout",
  },
}

export const Evidencing = {
  Getters: {
    allFields: "Evidencing/allFields",
    allEvidencingTypesWithFields: "Evidencing/allEvidencingTypesWithFields",
    allFieldIds: "Evidencing/allFieldIds",
    getFormattedEvidencing: "Evidencing/getFormattedEvidencing",
    getAllFieldCrops: "Evidencing/getAllFieldCrops",
  },
  Actions: {
    fetchAllOrgEvidencingData: "Evidencing/fetchAllOrgEvidencingData",
    fetchEvidencingGroupData: "Evidencing/fetchEvidencingGroupData",
    fetchEvidencingByOrgNode: "Evidencing/fetchEvidencingByOrgNode",
    deleteEvidencingGroup: "Evidencing/deleteEvidencingGroup"
  },
  Mutations: {
    setViewingOrgWideEvidencing: "Evidencing/setViewingOrgWideEvidencing",
    setOrgWideEvidencing: "Evidencing/setOrgWideEvidencing",
    setActiveEvidencingGroup: "Evidencing/setActiveEvidencingGroup",
    setEvidencingGroups: "Evidencing/setEvidencingGroups",
    removeEvidencingGroup: "Evidencing/removeEvidencingGroup",
    setEvidencingError: "Evidencing/setEvidencingError",
    resetEvidencingError: "Evidencing/resetEvidencingError",
    setLoadingState: "Evidencing/setLoadingState",
  },
}

export const EnrollmentGroups = {
  Getters: {
    allUniqueRegions: "EnrollmentGroups/allUniqueRegions",
    allUniqueCrops: "EnrollmentGroups/allUniqueCrops",
  },
  Actions: {
    fetchRollup: "EnrollmentGroups/fetchRollup",
    listOverview: "EnrollmentGroups/listOverview",
    fetchFssMetadata: "EnrollmentGroups/fetchFssMetadata",
    applyPendingFssState: "EnrollmentGroups/applyPendingFssState",
    submitSupplyChanges: "EnrollmentGroups/submitSupplyChanges",
    submitEvidencingLink: "EnrollmentGroups/submitEvidencingLink",
    confirmSupplyChanges: "EnrollmentGroups/confirmSupplyChanges",
    fetchClientProfiles: "EnrollmentGroups/fetchClientProfiles",
    fetchGroupClientProfiles: "EnrollmentGroups/fetchGroupClientProfiles",
    editClientProfiles: "EnrollmentGroups/editClientProfiles",
    submitContractGeneration: "EnrollmentGroups/submitContractGeneration",
    submitVoidContract: "EnrollmentGroups/submitVoidContract",
    submitContractEsign: "EnrollmentGroups/submitContractEsign",
    submitContractAdditionalEsign:
      "EnrollmentGroups/submitContractAdditionalEsign",
    submitContractCounterEsign: "EnrollmentGroups/submitContractCounterEsign",
    getContractTabMetadata: "EnrollmentGroups/getContractTabMetadata",
  },
  Mutations: {
    setPrograms: "EnrollmentGroups/setPrograms",
    setGroupCanGenerateContract: "EnrollmentGroups/setGroupCanGenerateContract",
    setGroupHasMaxCap: "EnrollmentGroups/setGroupHasMaxCap",
    setOrg: "EnrollmentGroups/setOrg",
    setView: "EnrollmentGroups/setView",
    setRollupData: "EnrollmentGroups/setRollupData",
    setAllGroupData: "EnrollmentGroups/setAllGroupData",
    setAllClientData: "EnrollmentGroups/setAllClientData",
    setIndividualGroupData: "EnrollmentGroups/setIndividualGroupData",
    setGroupSupplyChanges: "EnrollmentGroups/setGroupSupplyChanges",
    setGroupRequestCount: "EnrollmentGroups/setGroupRequestCount",
    setTableLoadingState: "EnrollmentGroups/setTableLoadingState",
    setFssLoading: "EnrollmentGroups/setFssLoading",
    setFssMetadata: "EnrollmentGroups/setFssMetadata",
    setClientProfiles: "EnrollmentGroups/setClientProfiles",
  },
}
