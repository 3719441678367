import { CROPS } from "@/constants"
import { CROP_YIELD_LIMITS, US_STATES } from "@/constants"
import { AGT_COLORS } from "@/constants/agts"

const US_NUM = new Intl.NumberFormat('en-US')

export const numberToLocale = (number, locale='en-US') => {
  if (locale == 'en-US') return US_NUM.format(number)
}

export const arrayAverage = arr => {
  const sum = arr.reduce((acc, curr) => acc + curr, 0)
  const avg = sum / arr.length
  return avg
}

export const findMinMax = arr => {
  let min = arr[0],
    max = arr[0]

  for (let i = 1, len = arr.length; i < len; i++) {
    let v = arr[i]
    min = v < min ? v : min
    max = v > max ? v : max
  }

  return [min, max]
}

// Standard deviation calculation:
// Find avg
// For each number, subtract avg and square the result
// Find sqrt(avg) of those numbers
export const standardDeviation = values => {
  const avg = arrayAverage(values)
  const meanSqrd = values.map(val => Math.pow(val - avg, 2))
  const stdDeviation = Math.sqrt(arrayAverage(meanSqrd))
  return stdDeviation
}

export const successMessage = (vueInstance, message, position) => {
  const toast = vueInstance.$toasted.success(message, {
    position,
  })
  toast.goAway(5000)
}

export const hexToRgb = hex => {
  if (hex == undefined) {
    return { r: 0, g: 0, b: 0 }
  }
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null
}

export const getAgtColor = agtNum => AGT_COLORS[agtNum % AGT_COLORS.length]

export const getInsetGeoidColor = (id, colorLookup) => {

  if (id in colorLookup) {
    return colorLookup[id]['value']
  } else {
    return "transparent"
  }

}

export const getAgtColorRGB = agtNum => {
  const hex = getAgtColor(agtNum)

  const rgb = hexToRgb(hex)
  return [rgb.r, rgb.g, rgb.b]
}

export const limitTextLength = (text, maxLength) =>
  text.length >= maxLength ? `${text.slice(0, maxLength)}...` : text

export const yieldScaleFactor = (predictedYield, targetYield) =>
  targetYield / predictedYield

export const fertScaleFactor = (cropId, predictedYield, targetYield) => {
  cropId = parseInt(cropId)
  const linearScaleCrops = [
    CROPS.Corn,
    CROPS.Soybeans,
    CROPS.Wheat,
    CROPS.Cotton,
    CROPS.Rice,
  ]
  if (linearScaleCrops.includes(cropId)) {
    return yieldScaleFactor(
      predictedYield,
      Math.min(targetYield, CROP_YIELD_LIMITS[cropId])
    )
  }
  return 1
}

export const getYearsToNow = ({ beginningYear = 2018 }) => {
  const thisYear = (new Date()).getFullYear();
  return Array.from({ length: 1 + thisYear - beginningYear }, (_, y) => y + beginningYear);
}

export const numberValidator = (value) => {
  if (value == undefined || value == null) return true;

  if (/^[+]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value)) {
    return true;
  }
  return 'Only numbers are allowed';
}

export const requiredValidator = value => {
  if (value == undefined || value == null) return "This field is required"
  if (value.length > 0) {
    return true
  }
  return "This field is required"
}

export const emailValidator = value => {
  if (value == undefined || value == null) return true
  if (/^\S+@\S+\.\S+$/.test(value)) {
    return true
  }
  return "E-mail must be valid"
}

export const isMobileUserAgent = () => /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

/**
 * Take a base64 string + MIME type and convert it to a "src"-able blob url for viewing in embeds, imgs, etc.
 */
export const base64ToBlob = (b64, type='application/octet-stream') => {
  const binStr = atob(b64);
  const len = binStr.length;
  const arr = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr], { type: type });
}

export const regionAbbrevToFull = (region) => {
  const found = US_STATES.find(({ value }) => value == region)
  if (found != null) return found['text']
  return region
}

export const getMinutesCopySinceDate = (date) => {
  // this was going to be much more interesting
  // keeping it here in case it ends up that way
  const minutesSince = Math.floor((Date.now() - (date == null ? Date.now() : date))/60000)

  let copy = "Over an hour ago"
  if (minutesSince < 60) copy = `${minutesSince} minute${minutesSince == 1 ? '' : 's'} ago`

  return copy
}
