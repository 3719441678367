import API from "./API"

const getEnrolledFields = () => API.get("/enrollment-fields/")
const getEnrollmentHistoryByField = payload =>
  API.get("/enrollment-history/", {
    params: payload,
  })
const listEnrollments = () => API.get("/enroll/")
const enrollFields = payload => API.post("/enroll/", payload)
const unenrollField = payload => API.put(`/enroll/${payload.id}/`, { payload })
const triggerDaycentSimulation = "/trigger-daycent-simulation/"
const additionalSpreadsheetYear = "/add-carbon-spreadsheet-year/"
const postTriggerDaycentSimulation = payload =>
  API.post(triggerDaycentSimulation, payload)
const postAdditionalSpreadsheetYear = payload =>
  API.post(additionalSpreadsheetYear, payload)
const fieldSelectionPdf = fieldSelectionJson =>
  API.post("/field-selection-pdf-data/", {
    field_selection_data: fieldSelectionJson,
  })
const fetchProgramExport = payload =>
  API.post("/fetch-ea-program-export/", payload)
const getRegenPractice = () => API.get("/regen-practice/")
const postPracticePreference = payload =>
  API.post("/update-practices/", payload)
const postInsetFilter = payload => API.post("/ea-order-search/", payload)

const postInsetOrder = payload => API.post("/ea-orders/", payload)

const updateInsetOrder = payload =>
  API.put(`/ea-orders/${payload.order_id}/`, payload)

const deleteInsetOrder = payload =>
  API.delete(`/ea-orders/${payload.id}`, { payload })

const getInsetOrder = payload =>
  API.get(`/ea-orders/${payload.id}`, { payload })

const getInsetOrders = () => API.get("/ea-orders/")
const deleteRequestedEnrollment = payload =>
  API.delete(`/enroll/${payload.id}`, { payload })

const postProjectReports = payload => API.post("/project-report/", payload)
const postOrderRerun = payload => API.post("/request-plan-cp-rerun/", payload)
const postAllocationData = payload => API.post("/retrieve_allocation/", payload)
const postSupplyAllocationData = payload =>
  API.post("/retrieve_supply_allocation/", payload)

const getEnrollmentSamplingSummary = payload =>
  API.get("/request-plan-cp-rerun/", { params: payload })

const getFieldSupplySummary = payload =>
  API.get("/field-supply-summary/", { params: payload })

const getRefreshEnrollments = () => API.get("/refresh-enrollment/")

const enrollFieldsInset = payload => API.post("/field-supply-summary/", payload)

const getFSSRecommendations = payload =>
  API.get("/fss-details/", { params: payload })

const postFSSRecommendations = payload => API.post("/fss-details/", payload)

const getEAOrderFSS = payload =>
  API.get(
    `/ea_order_fss/?order_id=${payload.order_id}&restrict_corporation=${payload.restrict_corporation}`
  )

const postEAOrderFSS = payload => API.post("/ea_order_fss/", payload)
const getInsetOrderRollups = () => API.get("/ea-order-rollup/")
const getInsetOrderRollup = payload =>
  API.get(`/ea-order-rollup/${payload.order_id}/`)

const postGenerateInsetContract = payload =>
  API.post("/generate_inset_contract/", payload)
const postESignContract = payload =>
  API.post("/send-contract-for-signature/", payload)
const postAdditionalESignContract = payload =>
  API.post("/send-contract-for-additional-signature/", payload)
const postCounterESignContract = payload =>
  API.post("/send-contract-for-counter-signature/", payload)
const autoAllocateOnePool = payload =>
  API.post("/auto-allocate-one-pool/", payload)
const postVoidContract = payload => API.post("/void_contract/", payload)
const getHumanReadableParams = payload =>
  API.get(`/eaorder-params-human-readable/?order_id=${payload.order_id}`)

const postEAOrderLock = payload => API.post("/bulk-locked-eao/", payload)
const getEAOrderForCorp = () => API.get("/bulk-locked-eao/")

const getCounterSignApprovals = () => API.get("/countersign-enrollment/")
const getCounterSignEnrollment = payload =>
  API.get(`/countersign-enrollment/?enrollment_id=${payload.enrollment_id}`)
const getContractGroupW9 = payload =>
  API.get(
    `/countersign-enrollment/?contract_group_id=${payload.contract_group_id}`
  )
const postCounterSignApprovals = payload =>
  API.post("/countersign-enrollment/", payload)

const getEICExplorerDataset = ({ startDate, endDate }) => {
  let route = `/quantification-tracking/list_overview`

  if (startDate != null && endDate != null) {
    route += `?start_date=${startDate}&end_date=${endDate}`
  }

  return API.get(route)
}

const getEICFilters = ({ fss_ids }) =>
  API.post("/quantification-tracking/get_eic_filters/", { fss_ids })

export default {
  getEnrolledFields,
  getEnrollmentHistoryByField,
  getRegenPractice,
  enrollFields,
  fetchProgramExport,
  unenrollField,
  postTriggerDaycentSimulation,
  postAdditionalSpreadsheetYear,
  postPracticePreference,
  fieldSelectionPdf,
  listEnrollments,
  deleteRequestedEnrollment,
  postProjectReports,
  postOrderRerun,
  getEnrollmentSamplingSummary,
  getRefreshEnrollments,
  getFieldSupplySummary,
  enrollFieldsInset,
  postInsetFilter,
  postInsetOrder,
  postAllocationData,
  updateInsetOrder,
  getInsetOrder,
  getInsetOrders,
  deleteInsetOrder,
  getFSSRecommendations,
  postFSSRecommendations,
  getEAOrderFSS,
  postEAOrderFSS,
  postSupplyAllocationData,
  getInsetOrderRollups,
  getInsetOrderRollup,
  postGenerateInsetContract,
  postESignContract,
  postAdditionalESignContract,
  postCounterESignContract,
  autoAllocateOnePool,
  postVoidContract,
  getEICExplorerDataset,
  getHumanReadableParams,
  postEAOrderLock,
  getEAOrderForCorp,
  getCounterSignApprovals,
  postCounterSignApprovals,
  getCounterSignEnrollment,
  getContractGroupW9,
}
