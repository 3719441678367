<template>
  <div class="d-flex position-relative">
    <svg></svg>
    <div class="position-absolute pa-3" id="histogram-tooltip" ref="histogramTooltip" style="display: none">
      <span>
        <b class="mr-2">{{ tooltipRange }}</b>
        <span>{{ tooltipNumFields }} Field{{ tooltipNumFields == '1' ? '' : 's' }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'

export default {
  name: "HistogramD3",
  props: {
    chartData: { type: Array, required: true },
    date1: { type: String, required: true },
    date2: { type: String, required: true },
  },
  data() {
    return {
      histogram: null,
      x: null,
      y: null,
      renderedChart: null,
      tooltipRange: null,
      tooltipNumFields: null,
      histogramTooltip: null,
    }
  },
  methods: {
    displayTooltip(tooltip) {
      tooltip.style.display = "block"
    },
    moveTooltip(tooltip, mouse, data) {
      tooltip.style.display = "block"
      tooltip.style.top = `${mouse['offsetY'] - 24}px`
      
      // extra 24 buffer jic
      if ((mouse['pageX'] + 200 + 24 + 24) > window.innerWidth)
        tooltip.style.left = `${mouse['offsetX'] - 200 - 24}px`
      else
        tooltip.style.left = `${mouse['offsetX'] + 24}px`

      this.tooltipRange = `${data['x0']} - ${data['x1']}`
      this.tooltipNumFields = data.length
    },
    hideTooltip(tooltip) {
      tooltip.style.display = "none"
    },
    drawChart() {
      const width = 800;
      const height = 500;
      const margins = { top: 30, right: 20, bottom: 40, left: 40 }

      const tooltip = this.$refs['histogramTooltip']
      const scopedDisplayTooltip = this.displayTooltip
      const scopedMoveTooltip = this.moveTooltip
      const scopedHideTooltip = this.hideTooltip

      const svg = d3.select("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto;");
      
      const bins = d3.bin()
        .domain([-5, 5])
        .thresholds(20)
        .value(d => d['diff'])
        (this.chartData);
      
      this.x = d3.scaleLinear()
        .domain([bins[0].x0, bins[bins.length - 1].x1])
        .range([margins['left'], width - margins['right']])
      
      this.y = d3.scaleLinear()
        .domain([0, d3.max(bins, d => d.length)])
        .range([height - margins['bottom'], margins['top']])
      
      this.histogram = svg.append("g")
        .attr('id', 'binsContainer')
        .attr("fill", "steelblue")
        .selectAll()
        .data(bins)
        .join("rect")
        .attr("x", d => this.x(d['x0']) + 1)
        .attr("width", d => this.x(d['x1']) - this.x(d['x0']) - 1)
        .attr("y", d => this.y(d.length))
        .attr("height", d => this.y(0) - this.y(d.length))
        .on('mouseover', function() {
          this.style.opacity = "0.8"
          scopedDisplayTooltip(tooltip)
        })
        .on('mousemove', function(mouse, data) {
          this.style.opacity = "0.8"
          scopedMoveTooltip(tooltip, mouse, data)
        })
        .on('mouseout', function() {
          this.style.opacity = "1.0"
          scopedHideTooltip(tooltip)
        })
      
      svg.append("g")
        .attr('id', 'xaxis')
        .attr("transform", `translate(0,${height - margins['bottom']})`)
        .call(d3.axisBottom(this.x).ticks(20))
        .call((g) => g.append("text")
          .attr("x", width)
          .attr("y", margins['bottom'] - 4)
          .attr("fill", "currentColor")
          .attr("text-anchor", "end")
          .attr('font-size', '16px')
          .text(`EIC Change between ${this.date1} and ${this.date2}`)
        );
      
      svg.append("g")
        .attr('id', 'yaxis')
        .attr("transform", `translate(${margins['left']},0)`)
        .call(d3.axisLeft(this.y))
        .call((g) => g.select(".domain").remove())
        .call((g) => g.append("text")
          .attr("x", -margins['left'] / 2 - 4)
          .attr("y", 12)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr('font-size', '16px')
          .text("# of Fields")
        );
      
      d3.selectAll('g.tick')
        .select('text')
        .style('font-size', '12px');
      
      this.renderedChart = true
    },
    renderChart() {
      if (!this.renderedChart) this.drawChart()
      else {
        const svg = d3.select("svg")
        const bins = d3.bin()
          .domain([-5, 5])
          .thresholds(20)
          .value(d => d['diff'])
          (this.chartData)

        this.x = this.x.domain([bins[0].x0, bins[bins.length - 1].x1])
        this.y = this.y.domain([0, d3.max(bins, d => d.length)])

        const transition = d3.transition()
          .duration(500)
          .ease(d3.easeCircleInOut)

        this.histogram
          .data(bins)
          .join('rect')
          .transition(transition)
          .attr("x", d => this.x(d['x0']) + 1)
          .attr("width", d => this.x(d['x1']) - this.x(d['x0']) - 1)
          .attr("y", d => this.y(d.length))
          .attr("height", d => this.y(0) - this.y(d.length)) 

        svg.select("#xaxis")
          .call(d3.axisBottom(this.x).ticks(20))
          .call((g) => g.append("text")
            .text(`EIC Change between ${this.date1} and ${this.date2}`)
          );

        svg.select("#yaxis")
          .call(d3.axisLeft(this.y))
          .call((g) => g.select(".domain").remove())
      }
    }
  },
  mounted() {
    this.renderChart()
  },
  watch: {
    chartData() {
      this.renderChart()
    }
  }
}
</script>

<style scoped>
#histogram-tooltip {
  width: 200px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  pointer-events: none;
}
</style>
