<template>
  <b-sidebar
    width="90vw"
    id="sidebar-right"
    right
    shadow
    backdrop
    :title="getSidebarTitle"
    bg-variant="white"
    v-model="show"
  >
    <v-snackbar v-model="snackbar" color="red lighten-1" top>{{
      snackbarText
    }}</v-snackbar>

    <v-overlay :value="loading" opacity="0.3">
      <v-progress-circular
        indeterminate
        size="30"
        color="primary"
      ></v-progress-circular>
      <div class="mt-6 text-bold">Fetching Data...</div>
    </v-overlay>

    <div class="px-8 sidebar-body-inner-padding-bottom">
      <div v-if="pdfUrl">
        <div class="px-2">
          <v-row class="justify-end mb-3">
            <v-col v-if="!contractExpanded" cols="4" height="65vh">
              <v-toolbar flat
                ><span
                  ><b>Current Status:</b>
                  <v-chip class="ml-3" :color="statusColor">{{
                    contractStatus.toUpperCase()
                  }}</v-chip></span
                >
              </v-toolbar>
              <v-data-table
                v-if="validationItems.length > 0"
                :items="validationItems"
                :headers="headers"
                disable-sort
                hide-default-footer
              >
                <template v-slot:header.is_valid="{ header }">
                  <span class="d-flex">
                    <v-simple-checkbox
                      @input="checkValid($event)"
                      v-model="selectAllStatus"
                      :disabled="enableUpload"
                      :indeterminate="selectAllStatus == null"
                    />
                    <span>{{ header.text }}</span>
                  </span>
                </template>
                <template v-slot:item.is_valid="{ item }">
                  <v-simple-checkbox
                    v-model="item.is_valid"
                    :disabled="enableUpload" /></template
              ></v-data-table>
              <v-textarea
                class="mt-4"
                v-model="notes"
                outlined
                label="Notes (Required to Request Changes)"
                :disabled="enableUpload"
              ></v-textarea>
              <v-layout class="d-flex bg-surface-variant mt-1">
                <v-btn
                  class="mr-3"
                  :disabled="!enableReject"
                  @click="requestChangeOnContract"
                >
                  <v-icon class="mr-1">mdi-chat-processing-outline</v-icon>
                  Change request
                </v-btn>

                <v-btn
                  class="mr-3"
                  :disabled="canVoid"
                  @click="openVoidedReasonDialog(groupID)"
                >
                  <v-icon class="mr-1">mdi-file-document-remove-outline</v-icon>
                  Reject & Void
                </v-btn>

                <v-btn
                  color="light-green"
                  :disabled="!enableApproval"
                  @click="approveContract"
                >
                  <v-icon class="mr-1">mdi-check-circle-outline</v-icon>
                  Approve
                </v-btn>
              </v-layout>
            </v-col>
            <v-col :cols="contractExpanded ? 12 : 8" height="65vh">
              <v-toolbar class="mb-3" flat>
                <v-tabs
                  grow
                  v-model="tab"
                  active-class="active-tab font-weight-bold"
                >
                  <v-tab v-for="item in tabTitles" :key="item.index">
                    {{ item.name }}
                  </v-tab>
                </v-tabs>
                <v-btn icon @click="contractExpanded = !contractExpanded">
                  <v-icon>{{
                    contractExpanded ? "mdi-arrow-collapse" : "mdi-arrow-expand"
                  }}</v-icon>
                </v-btn>
              </v-toolbar>
              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in tabTitles" :key="item.index">
                  <v-card
                    flat
                    v-if="item.name == 'Contract'"
                    :height="contractExpanded ? '85vh' : '65vh'"
                  >
                    <iframe
                      :src="pdfUrl"
                      width="100%"
                      height="100%"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </v-card>
                  <v-card
                    flat
                    v-if="item.name == 'W9' && w9Url != null"
                    height="65vh"
                  >
                    <iframe
                      :src="w9Url"
                      width="100%"
                      height="100%"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </v-card>
                  <v-card
                    class="ma-2"
                    v-if="
                      item.name == 'FSS Payment Rectification' &&
                      comparisonByFSS.length > 0
                    "
                  >
                    <v-data-table
                      v-if="comparisonByFSS.length > 0"
                      :items="comparisonByFSS"
                      :headers="comparisonByFSSHeaders"
                    >
                    </v-data-table>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
          <div class="footer">
            <footer class="content-footer pl-0">
              <div class="footer"></div>

              <v-layout class="d-flex bg-surface-variant">
                <v-btn
                  class="mr-3"
                  :disabled="!enableCounterSign"
                  @click="sendEsign"
                  >Send E-Sign For Countersignature</v-btn
                >
                <v-btn
                  class="mr-3"
                  :disabled="!enableUpload"
                  @click="openUploadDialog(groupID)"
                  >Upload Countersigned Contract</v-btn
                >
                <v-btn class="mr-3" @click="downloadContract"
                  >Download Contract</v-btn
                >

                <v-btn class="mr-3" @click="openW9UploadDialog(groupID)"
                  >Upload W9</v-btn
                >

                <!--<v-btn v-if="w9Url != null && false" @click="handleDownloadW9"
                  >Download W9</v-btn
                >-->
                <v-btn class="mr-3" @click="show = false">Exit to List</v-btn>
                <v-btn class="mr-3" @click="loadContract"
                  ><v-icon>mdi-refresh</v-icon></v-btn
                >
              </v-layout>
            </footer>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="showUploadDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            >Upload Countersigned Contract for {{ legalEntityDisplay }}</span
          >
        </v-card-title>

        <v-card-text>
          <v-file-input
            :truncate-length="200"
            v-model="selectedFile"
            label="Choose a file"
            outlined
            dense
            accept=".pdf"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeUploadDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            :disabled="!selectedFile"
            @click="handleUploadContract"
          >
            Upload Countersigned Contract
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showW9UploadDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Upload W9 for {{ legalEntityDisplay }}</span>
        </v-card-title>

        <v-card-text>
          <v-file-input
            :truncate-length="200"
            v-model="selectedFile"
            label="Choose a file"
            outlined
            dense
            accept=".pdf, .jpg, .jpeg, .png, .heic"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeW9UploadDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            :disabled="!selectedFile"
            @click="handleUploadW9"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="paymentWarning" max-width="650">
      <v-card>
        <v-card-title>
          <span class="text-h6"
            >Payment In Contract May Not Have Latest Data!</span
          >
        </v-card-title>

        <v-card-text>
          <h5>
            <i
              >There May Be More Current CO2e and/or Payment Information in the
              Fieldsupply Database That Is Not Reflected In This PDF.</i
            >
          </h5>
          <h5>
            Please Cross Reference The Field Supplyshed CSV Before Proceeding
            and Review the 'FSS Payment' Tab For Details.
          </h5>
          <h5>
            <b
              >This Contract Will Likely Require The Attachment Of An Exhibit
              C</b
            >
          </h5>
        </v-card-text>
        <v-data-table
          v-if="compareValidationItems.length > 0"
          :items="compareValidationItems"
          :headers="compareValidationItemsHeaders"
          disable-sort
          hide-default-footer
        >
        </v-data-table>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="paymentWarning = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showVoidedReasonDialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="text-h6">Reason Required</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="notes"
            label="Enter reason for voiding contract"
            placeholder="Type your reason here..."
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="closeVoidedReasonDialog"
            >Cancel</v-btn
          >
          <v-btn color="primary" :disabled="!notes" @click="handleVoidContract">
            Void Contract
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </b-sidebar>
</template>

<script>
import { mapActions, mapState } from "vuex"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"

import JSZip from "jszip"
import moment from "moment"
import { EnrollmentGroups, Uploads } from "@/store/modules"
import {
  USER_CAN_COUNTERSIGN, USER_CAN_VOID
} from "@/constants/contractGroups"
import { 
  DEPLOYMENT_ENVIRONMENT 
} from "@/constants"
export default {
  name: "ContractCounterSignSidebar",
  props: {
    enrollmentID: { type: Number | String },
    channelPartnerName: { type: String },
  },
  emits: ["closeSidebar"],
  data() {
    return {
      show: false,
      loading: false,
      visible: false,
      cancelled: true,
      contractExpanded: false,
      showUploadDialog: false,
      showW9UploadDialog: false,
      showVoidedReasonDialog: false,
      notes: null,
      enrollment: null,
      snackbarText:
        "File Uploaded, This May Take a Few Moments to Fully Process",
      paymentWarning: false,
      snackbar: false,
      selectAllStatus: false,
      legalEntityDisplay: "",
      focusedGroupId: null,
      selectedFile: null,
      groupID: null,
      contractStatus: null,
      w9Url: null,
      w9_filename: null,
      contractInputGroup: "groupDetails",
      comparisonByFSS: [],
      comparisonByFSSHeaders: [
        { text: "Field ID", value: "field_id" },
        { text: "Field", value: "field" },
        { text: "Crop", value: "crop" },
        { text: "Stored CO2e", value: "pdf_co2e_per_acre" },
        { text: "Stored Payment", value: "pdf_total_payment" },
        { text: "Latest CO2e", value: "current_co2e_per_acre" },
        { text: "Latest Payment", value: "current_total_payment" },
        { text: "CO2e Difference", value: "co2e_difference" },
        { text: "Payment Difference", value: "payment_difference" },
      ],
      compareValidationItems: [],
      compareValidationItemsHeaders: [
        { text: "Category", value: "category" },
        { text: "Contract Stored Value", value: "value" },
        { text: "Recalculated Value", value: "compare_value" },
        { text: "Net Difference", value: "difference" },
      ],
      validationItems: [],
      tabTitles: [],
      tab: 0,
      headers: [
        { text: "Category", value: "category" },
        { text: "Value", value: "value" },
        { text: "Valid", value: "is_valid", alight: "left" },
      ],
      USER_CAN_COUNTERSIGN,
      USER_CAN_VOID,
      DEPLOYMENT_ENVIRONMENT
    }
  },
  computed: {
    ...mapState({
      user: state => state.User.user,
      year: state => state.Organization.year,
      org: state => state.Organization.organization,
      featureFlags: state => state.Fields.featureFlags,
    }),
    getSidebarTitle() {
      if (
        this.enrollment == null ||
        this.enrollment["legal_entity_name"] == null
      )
        return "Grower Contract Review"
      return `Grower Contract Review for ${this.enrollment["legal_entity_name"]}`
    },

    pdfUrl() {
      if (this.enrollment != null) {
        return this.enrollment["enroll_agreement_pdf"]
      }
    },
    filename() {
      if (this.enrollment != null) {
        return this.enrollment["enroll_agreement_pdf"].split("/").pop()
      }
    },

    enableReject() {
      if (this.formDisabled) {
        return false
      }
      if (this.notes == null) {
        return false
      }
      if (this.notes.length > 7) {
        return true
      } else {
        return false
      }
    },
    enableCounterSign() {
      if (
        DEPLOYMENT_ENVIRONMENT == "production" &&
        !USER_CAN_COUNTERSIGN.includes(this.user.id)
      ) {
        return false
      } else if (this.contractStatus == "approved") {
        return true
      } else {
        return false
      }
    },
    enableUpload() {
      if (
        DEPLOYMENT_ENVIRONMENT == "production" &&
        !USER_CAN_COUNTERSIGN.includes(this.user.id)
      ) {
        return false
      } else if (
        this.contractStatus == "approved" ||
        this.contractStatus == "contract completed"
      ) {
        return true
      } else {
        return false
      }
    },
    enableApproval() {
      this.syncSelectAll()

      if (this.formDisabled) {
        return false
      }

      let arr = this.validationItems
        .filter(f => {
          return ["Entity", "Payment"].includes(f.category)
        })
        .map(e => {
          return e.is_valid
        })
      if (
        arr.every(Boolean) &&
        ["awaiting review", "approved"].includes(this.contractStatus)
      ) {
        return true
      } else {
        return false
      }
    },
    formDisabled() {
      if (
        [
          "contract voided",
          "contract generated",
          "contract completed",
        ].includes(this.contractStatus)
      ) {
        return true
      } else {
        return false
      }
    },
    canVoid() {
      if (this.contractStatus != "contract voided" && USER_CAN_VOID.includes(this.user.id)) {
        return false
      }
      return this.formDisabled
    },
    statusColor() {
      if (this.contractStatus == "awaiting review") {
        return "amber"
      } else if (this.contractStatus == "approved") {
        return "light-green"
      } else if (this.contractStatus == "contract completed") {
        return "green"
      } else if (this.contractStatus == "contract voided") {
        return "red"
      } else if (this.contractStatus == "changes requested") {
        return "orange"
      } else if (this.contractStatus == "contract generated") {
        return "primary"
      }
    },
  },
  methods: {
    ...mapActions({
      submitVoidContract: EnrollmentGroups.Actions.submitVoidContract,
      submitContractEsign: EnrollmentGroups.Actions.submitContractEsign,
      submitContractCounterEsign:
        EnrollmentGroups.Actions.submitContractCounterEsign,
      createUpload: Uploads.Actions.create,
    }),

    syncSelectAll() {
      let arr = this.validationItems.map(e => {
        return e.is_valid
      })

      if (arr.every(Boolean)) {
        this.selectAllStatus = true
      } else {
        if (arr.every(element => element === false)) {
          this.selectAllStatus = false
        } else {
          this.selectAllStatus = null
        }
      }
    },

    checkValid(e) {
      if (e) {
        this.validationItems.forEach(item => {
          item.is_valid = true
        })
      } else {
        this.validationItems.forEach(item => {
          item.is_valid = false
        })
      }
    },

    async handleDownloadContract(url, fileName) {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          this.snackbarText = "Error downloading Contract"
          this.snackbar = true
        }
        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = objectURL
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(objectURL)
      } catch (error) {
        this.snackbarText = "Error downloading Contract"
        this.snackbar = true
      }
    },

    async handleDownloadW9() {
      let fileName = this.w9_filename.split("/").pop()
      try {
        const response = await fetch(this.w9Url)
        if (!response.ok) {
          this.snackbarText = "Error downloading W9"
          this.snackbar = true
        }
        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.href = objectURL
        a.download = fileName
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(objectURL)
      } catch (error) {
        this.snackbarText = "Error downloading W9"
        this.snackbar = true
      }
    },
    async sendEsign() {
      const groupId = this.groupID
      const enrollmentId = this.enrollmentID
      this.loading = true
      await CarbonReadyAPI.postCounterESignContract({
        groupId,
        enrollmentId,
      })
        .then(({ data }) => {
          this.enrollment = data
          this.contractStatus = this.enrollment.enrollment_status
        })
        .catch(error => {
          this.snackbarText = "Error sending E-Sign for Countersignature"
          this.snackbar = true
        })
      this.loading = false
    },
    openUploadDialog(groupId) {
      this.selectedFile = null
      this.showUploadDialog = true

      this.legalEntityDisplay = this.enrollment.legal_entity_name
      this.focusedGroupId = groupId
    },
    closeUploadDialog() {
      this.showUploadDialog = false
      this.selectedFile = null
      //this.notes = ""
    },

    openW9UploadDialog(groupId) {
      this.selectedFile = null

      this.showW9UploadDialog = true

      this.legalEntityDisplay = this.enrollment.legal_entity_name
      this.focusedGroupId = groupId
    },
    closeW9UploadDialog() {
      this.showW9UploadDialog = false
      this.selectedFile = null
    },

    async handleUploadW9() {
      this.loading = true

      this.showW9UploadDialog = false
      //this.setTableLoadingState({ target: ENROLLMENT_TABLE, loading: true })

      if (this.selectedFile) {
        const uploadSpec = {
          datasetType: "w9",
          orgId: this.org["id"],
          specs: {
            contractGroupID: this.focusedGroupId,
          },
        }

        const zip = new JSZip()
        zip.file(this.selectedFile.name, await this.selectedFile.arrayBuffer())
        const result = await zip.generateAsync({ type: "blob" })
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
        const newZipName = `${uploadSpec.specs.contractGroupID}-w9-${datetimeStr}.zip`
        uploadSpec["file"] = new File([result], newZipName)
        this.createUpload(uploadSpec)
          .then(_ => {
            this.loading = false
            this.snackbarText = "Successfully uploaded W9!"
            this.snackbar = true
            this.loadContract()
          })
          .catch(error => {
            this.loading = false

            this.snackbarText = "Error uploading W9!"
            this.snackbar = true
          })
      }
    },
    async handleUploadContract() {
      this.loading = true
      this.showUploadDialog = false

      //this.setTableLoadingState({ target: ENROLLMENT_TABLE, loading: true })
      const enrollment_id = this.enrollmentID
      if (this.selectedFile) {
        const uploadSpec = {
          datasetType: "grower-contract",
          orgId: this.enrollment.fieldsupply.map(e => e.org_node_id)[0],
          specs: {
            signatureType: "arva-signed-contract",
            enrollmentId: enrollment_id,
          },
        }

        const zip = new JSZip()
        zip.file(this.selectedFile.name, await this.selectedFile.arrayBuffer())
        const result = await zip.generateAsync({ type: "blob" })
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
        const newZipName = `${uploadSpec.specs.enrollmentId}-arva-signed-grower-contract-${datetimeStr}.zip`
        uploadSpec["file"] = new File([result], newZipName)

        this.createUpload(uploadSpec)
          .then(_ => {
            this.loading = false
            this.$emit("refreshContractList")
            this.snackbarText = "Successfully uploaded Contract"
            this.snackbar = true
            this.loadContract()
          })
          .catch(error => {
            this.loading = false
            this.snackbarText = "Error uploading Contract"
            this.snackbar = true
          })
      }
    },

    openVoidedReasonDialog(groupId) {
      this.voidedContractGroupId = groupId
      this.showVoidedReasonDialog = true
    },
    closeVoidedReasonDialog() {
      this.showVoidedReasonDialog = false
      //this.notes = ""
      this.voidedContractGroupId = null
    },
    async handleVoidContract() {
      this.showVoidedReasonDialog = false
      this.loading = true
      const groupId = this.voidedContractGroupId
      const enrollmentId = this.enrollmentID
      const voidedReason = this.notes
      await CarbonReadyAPI.postVoidContract({ groupId, enrollmentId, voidedReason })
      this.voidedContractGroupId = null
      this.contractStatus = "contract voided"
      this.loading = false
      this.$emit("refreshContractList")
      this.snackbarText = "Contract Voided"
      this.snackbar = true
    },

    check_valid_value(obj, key) {
      if (obj == null) {
        return false
      }
      if (obj.hasOwnProperty(key)) {
        return obj[key].is_valid
      } else {
        return false
      }
    },

    removeSidebar() {
      this.$emit("closeSidebar", this.cancelled)
      this.cancelled = true
    },
    cancel() {
      this.cancelled = true
      this.show = false
    },

    downloadContract() {
      this.handleDownloadContract(this.pdfUrl, this.enrollment.pdf_name)
    },
    voidContract() {
      this.handleVoidContract()
    },
    approveContract() {
      let payload = {
        enrollment_id: this.enrollmentID,
        status: "approved",
        notes: this.notes,
        validations: this.validationItems,
      }
      CarbonReadyAPI.postCounterSignApprovals(payload).then(resp => {
        this.contractStatus = "approved"
        //this.notes = ""
        this.$emit("refreshContractList")

        //emit list refresh
      })
    },
    requestChangeOnContract() {
      let payload = {
        enrollment_id: this.enrollmentID,
        status: "changes requested",
        notes: this.notes,
        validations: this.validationItems,
      }
      CarbonReadyAPI.postCounterSignApprovals(payload).then(resp => {
        this.contractStatus = "changes requested"
        //this.notes = ""
        this.$emit("refreshContractList")

        //emit list refresh
      })
    },
    async loadContract() {
      this.loading = true
      let payload = { enrollment_id: this.enrollmentID }
      await CarbonReadyAPI.getCounterSignEnrollment(payload).then(resp => {
        this.enrollment = resp.data

        if (this.enrollment.payment_errors.length > 0) {
          this.paymentWarning = true
        }
        this.groupID = this.enrollment.contract_group_id
        this.contractStatus = this.enrollment.enrollment_status
        let valid_obj = this.enrollment.notes
        if (this.validationItems.length < 1) {
          this.validationItems.push({
            category: "Entity",
            value: this.enrollment.legal_entity_name,
            is_valid: this.check_valid_value(valid_obj, "Entity"),
          })
          this.validationItems.push({
            category: "UUID",
            value: this.enrollment.uuid,
            is_valid: this.check_valid_value(valid_obj, "UUID"),
          })
          this.validationItems.push({
            category: "# of FSS",
            value: this.enrollment.fieldsupply.length,
            is_valid: this.check_valid_value(valid_obj, "# of FSS"),
          })
          this.validationItems.push({
            category: "Total AC",
            value: this.enrollment.enrolled_ac.toLocaleString(),
            is_valid: this.check_valid_value(valid_obj, "Total AC"),
          })
          this.validationItems.push({
            category: "Payment",
            value: "$" + this.enrollment.total_payment.toLocaleString(),
            is_valid: this.check_valid_value(valid_obj, "Payment"),
          })
          this.tabTitles.push({ index: 0, name: "Contract" })
          this.notes = this.enrollment.reason
        }
        if (
          this.compareValidationItems.length < 1 &&
          this.enrollment.payment_errors.length > 0
        ) {
          this.compareValidationItems.push({
            category: "Payment",
            value: "$" + this.enrollment.total_payment.toLocaleString(),
            compare_value:
              "$" + this.enrollment.compare_grand_total.toLocaleString(),
            difference:
              "$" +
              (
                this.enrollment.compare_grand_total -
                this.enrollment.total_payment
              ).toLocaleString(),
          })
          this.compareValidationItems.push({
            category: "Enrolled Acreage",
            value: this.enrollment.enrolled_ac.toLocaleString(),
            compare_value:
              this.enrollment.compare_total_acreage.toLocaleString(),
            difference: (
              this.enrollment.compare_total_acreage -
              this.enrollment.enrolled_ac
            ).toLocaleString(),
          })

          if (this.enrollment.hasOwnProperty("by_fss_comparison")) {
            this.comparisonByFSS = this.enrollment.by_fss_comparison
            this.tabTitles.push({
              index: this.tabTitles.length + 1,
              name: "FSS Payment Rectification",
            })
          }
        }

        payload = { contract_group_id: this.enrollment.contract_group_id }
        CarbonReadyAPI.getContractGroupW9(payload).then(resp => {
          this.w9Url = resp.data.w9_file
          this.w9_filename = resp.data.w9_filename
          if (this.w9Url != null && this.tabTitles.length < 3) {
            this.tabTitles.push({
              index: this.tabTitles.length + 1,
              name: "W9",
            })
          }
        })
      })
      this.loading = false
    },
  },
  watch: {
    visible(curr) {
      this.show = curr
    },
    show(curr) {
      if (!curr) this.removeSidebar()
    },
  },
  mounted() {
    this.visible = true
    this.loadContract()

    //this.validationItems.append({"Data Check":"# FSS","Data":this.enrollment.fieldsupply.length})

    //build validationItems
  },
  //on mounted create the v-data-table items to check the validation concerns
}
</script>

<style scoped>
#sidebar-right {
  background: #ffffff !important;
}

p {
  color: #000000;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.contracts-button-toggle > button {
  text-transform: none;
  padding: 12px 24px !important;
  letter-spacing: normal;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
  color: #374151;
}

.contracts-button-toggle
  > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.v-btn .v-btn__content > i {
  font-size: 20px;
}

.contracts-button-toggle > button.v-item--active {
  color: #61af02;
  border-bottom: 2px solid #61b100 !important;
  background: #f1fddf !important;
}

.contracts-button-toggle > button.v-item--active::before {
  display: none;
}

.contract-input-group p {
  color: #212121;
}

.contract-navigation-handlers button {
  border-color: rgba(0, 0, 0, 0.5);
}

.contract-navigation-handlers > div > button:not(:last-child) {
  margin-right: 12px;
}

.text-caption {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: -4px;
}

p.disabled-label {
  opacity: 0.5;
}

::v-deep .fips-option-not-supported + ::before {
  background-color: red !important;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep #sidebar-right {
  overflow: hidden;
}

.sidebar-body-inner-padding-bottom {
  padding-bottom: 12px;
}

::v-deep .contract-navigation-handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
}
::v-deep header.b-sidebar-header {
  padding: 32px 32px 0;
  position: relative;
}
::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
}
::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #000000;
  margin-left: 12px;
}
.confirm-contract-text {
  font-size: 23px;
  line-height: 33px;
}
.legal-business-name-text {
  font-size: 10px;
  line-height: 20px;
}
:deep(.v-label) {
  color: #000000;
}
:deep(.v-data-table-header) {
  z-index: 1;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.contract-table-header {
  font-size: 24px;
  line-height: 32px;
}
.contract-table-subheader {
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}
:deep(.v-data-table__empty-wrapper > td) {
  padding-top: 16px !important;
}
:deep(.v-list-item__title) {
  text-transform: lowercase;
}
.v-expansion-panels {
  z-index: 0;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
}
.v-tooltip__content {
  background: rgba(255, 255, 255, 0.9);
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.v-tooltip__content :deep(ul) {
  padding: 0;
}
.v-tooltip__content :deep(ul li) {
  list-style: none;
}
.v-tooltip__content :deep(i) {
  color: #000000;
}
</style>
