<template>
  <div :class="`${view == CSM_VIEW ? 'superuser-view' : ''} pb-6 enrollment-container`">
    <Permissions package="inset-enrollment" :superUserOnly="false" />
    <div>
      <div class="d-flex justify-space-between align-center mb-2 mb-md-4">
        <div class="d-flex align-center">
          <h1 class="mb-0 font-weight-bold">Enrollment Groups</h1>
        </div>

        <div>
          <v-btn class="h-44 mr-3" :ripple="false" outlined href="https://www.loom.com/share/0ab36823ce6c4445b1fe35caa5bc3b53?sid=4a5d6533-732a-46e5-99f8-ecca64d33a2a" target='_blank'>
            <v-icon>mdi-play-outline</v-icon>
            3 Min Training
          </v-btn>

          <v-menu offset-y v-if="isSuperuser">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="h-44 mr-3" :ripple="false" outlined v-bind="attrs" v-on="on">
                <v-icon>mdi-chevron-down</v-icon>
                <span class="ml-1">{{ view == CSM_VIEW ? 'CSM View' : 'CP View' }}</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item-group @change="setView" mandatory color="primary">
                <v-list-item>
                  <v-list-item-title>CP View</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>CSM View</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </div>
      </div>
  
      <RollupTable
        :selectedRegions="selectedRegions"
        :selectedCrops="selectedCrops"
        @updateSelected="handleUpdateSelected"
        @updateRollupTableHeight="updateRollupTableHeight"
      />

      <v-card outlined class="px-3 py-1 px-md-6 py-md-3 position-relative">
        <v-progress-circular v-if="enrollmentTableLoading['loading']" indeterminate :size="48" color="#79c61c" />

        <div v-if="enrollmentTableLoading['error'] != null">
          <p>Error: {{ enrollmentTableLoading['error'] }}</p>
          <p v-if="year != 2024">You are currently viewing <b>year {{ year }}</b>, which may not have sufficient enrollment data.</p>
        </div>

        <v-data-table
          v-else
          hide-default-footer
          disable-pagination
          item-key="cropIdKey"
          :class="`enrollment-group-table ${enrollmentTableLoading['loading'] ? 'loading' : ''} ${view == CSM_VIEW ? 'csm-view' : ''}`"
          :style="`--table-height-modifier: ${allOtherHeights}px`"
          :search="search"
          :custom-filter="handleDataTableFiltering"
          :headers="headers"
          :items="shimmedFilteredFormattedItems"
          v-model="selectedItems"
          @current-items="(items) => filteredItems = items"
        >
          <template v-slot:top="props">
            <div
              v-bind="props"
              class="d-flex align-center flex-wrap mb-1 mb-md-4"
            >
              <div class="d-flex w-100 justify-space-between">
                <div class="d-flex align-center flex-wrap">
                  <v-card-title class="pa-0 ma-0 w-100 order-1">
                    {{ 
                      enrollmentTableLoading['loading']
                        ? 'Loading group data...'
                        : `${allUniqueRenderedGroupIds.length} Enrollment Group${allUniqueRenderedGroupIds.length == 1 ? '' : 's'}`
                    }}
                  </v-card-title>
                </div>

                <div class="d-flex justify-end">
                  <div id="contract-status-selector" class="position-relative" v-if="isContractTab">
                    <v-select
                      solo
                      multiple
                      clearable
                      hide-details
                      attach="#contract-status-selector"
                      placeholder="All Statuses"
                      :items="TEMP_CONTRACT_STATUSES"
                      v-model="selectedContractStatuses"
                      :class="`mr-2 ${selectedContractStatuses.length == 0 ? 'all-selected' : ''}`"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template v-slot:selection="{ item, index }">
                        <div v-if="index == 0">
                          <p class="ma-0 text-subtitle-2 font-weight-regular">
                            Statuses <span>{{ selectedContractStatuses.length }}</span>
                          </p>
                        </div>
                      </template>
                    </v-select>
                  </div>

                  <div id="region-selector" class="position-relative">
                    <v-select
                      solo
                      multiple
                      clearable
                      hide-details
                      attach="#region-selector"
                      placeholder="All Regions"
                      :items="allUniqueRegions"
                      v-model="selectedRegions"
                      :class="`mr-2 ${selectedRegions.length == 0 ? 'all-selected' : ''}`"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template v-slot:selection="{ item, index }">
                        <div v-if="index == 0">
                          <p class="ma-0 text-subtitle-2 font-weight-regular">
                            Regions <span>{{ selectedRegions.length }}</span>
                          </p>
                        </div>
                      </template>
    
                      <template v-slot:item="{ on, attrs, item }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox :input-value="active" />
                            </v-list-item-action>
    
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ regionAbbrevToFull(item) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>

                  <div id="crop-selector" class="position-relative">
                    <v-select
                      solo
                      multiple
                      clearable
                      hide-details
                      item-value="itemKey"
                      v-model="selectedCrops"
                      attach="#crop-selector"
                      placeholder="All Crops"
                      :items="allUniqueCrops"
                      :class="`mr-2 ${selectedCrops.length == 0 ? 'all-selected' : ''}`"
                      :menu-props="{ bottom: true, offsetY: true }"
                    >
                      <template v-slot:selection="{ item, index }">
                        <div v-if="index == 0">
                          <p class="ma-0 text-subtitle-2 font-weight-regular">
                            Crops <span>{{ selectedCrops.length }}</span>
                          </p>
                        </div>
                      </template>
    
                      <template v-slot:item="{ on, attrs, item }">
                        <v-list-item v-on="on" v-bind="attrs">
                          <template v-slot:default="{ active }">
                            <v-list-item-action>
                              <v-checkbox :input-value="active" />
                            </v-list-item-action>
    
                            <v-list-item-content>
                              <v-list-item-title v-if="item['id'] == 4 && item['harvest_type'] == 'Silage'">
                                Corn (Silage)
                              </v-list-item-title>
                              <v-list-item-title v-else>
                                {{ CROP_DISPLAY_NAME[item['id']] }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-list-item>
                      </template>
                    </v-select>
                  </div>

                  <v-text-field
                    :ripple="false"
                    class="mr-3 search-input"
                    outlined
                    v-model="search"
                    hide-details
                    placeholder="Search"
                  >
                    <template v-slot:prepend-inner>
                      <v-icon>mdi-magnify</v-icon>
                    </template>
                    <span class="ml-1">Search</span>
                  </v-text-field>
                </div>
              </div>
              <div class="d-flex w-100">
                <v-tabs
                  v-model="activeTab"
                  background-color="transparent"
                  slider-color="#4caf50"
                  class="mb-0"
                >
                  <v-tab tab-value="enrollment">Enrollment</v-tab>
                  <v-tab
                    tab-value="contract"
                    v-if="
                      (view == CSM_VIEW && featureFlags?.grower_contract) ||
                      (featureFlags?.grower_contract &&
                      enableCPGrowerContract)
                    "
                    >Contract</v-tab>
                    <v-tab tab-value="legalEntity">Legal Entity</v-tab>
                </v-tabs>
              </div>
            </div>
          </template>

          <template v-slot:item="{ index, item, select }">
            <tr :data-is-first="isFirstInRow(item, index) ? '1' : '0'" @click="handleClick(item)">
              <td
                v-for="cell, idx in headers.map(({ value }) => value)"
                :key="cell + '-' + idx"
                @click="(e) => { PREVENT_CELL_EVENT.includes(cell) ? basePrevent(e) : null }"
              >
                <div v-if="cell == 'legalEntity' && isFirstInRow(item, index)">
                  <v-chip class="legal-entity-needed" v-if="!item['legalEntity']">Legal Entity Needed</v-chip>
                  <span v-else>{{ item['name'] }}</span>
                </div>

                <div v-if="cell == 'name' && isFirstInRow(item, index)">
                  {{ item['name'] }}
                </div>

                <div v-if="cell == 'clientNames' && isFirstInRow(item, index)">
                  <!--{{ item['clientNames'].join(', ') }} -->
                  {{item['clientNames']}}
                </div>

                <div v-if="cell == 'regions'" class="d-flex position-relative">
                  <span v-if="item['regions'] != null">
                    {{
                      item['regions']['value'].map(stateName => {
                        const found = US_STATES.find(({ text }) => stateName == text);
                        if (found != null) return found['value'];
                        return stateName;
                      }).join(', ')
                    }}
                  </span>
                </div>

                <div v-if="cell == 'crop'" class="d-flex position-relative">
                  <span v-if="item['crop']['id'] == 4 && item['crop']['harvest_type'] == 'Silage'">
                    Corn (Silage)
                  </span>
                  <span v-else>
                    {{ item['crop']['id'].map(i => CROP_DISPLAY_NAME[i]).join(', ') }}
                  </span>
                </div>

                <div v-if="cell == 'totalAcres' && isFirstInRow(item, index)" class="text-right">
                  <span v-if="item['totalAcres'] != null && item['totalAcres'] != 0">
                    {{
                      item['totalAcres'] != null && item['totalAcres'] != 0
                        ? item['totalAcres'].toFixed(2).toLocaleString()
                        : null
                    }}
                  </span>
                </div>

                <div v-if="cell == CONFIRMED" class="text-right">
                  <span v-if="item[CONFIRMED] != null && item[CONFIRMED] != 0">
                    {{
                      item[CONFIRMED] != null && item[CONFIRMED] != 0
                        ? item[CONFIRMED].toFixed(2).toLocaleString()
                        : null
                    }}
                  </span>
                </div>

                <div v-if="cell == LOCKED_FOR_PAYMENT" class="text-right locked-for-payment-color">
                  <span v-if="item[LOCKED_FOR_PAYMENT] != null && item[LOCKED_FOR_PAYMENT] != 0">
                    {{
                      item[LOCKED_FOR_PAYMENT] != null && item[LOCKED_FOR_PAYMENT] != 0
                        ? item[LOCKED_FOR_PAYMENT].toFixed(2).toLocaleString()
                        : null
                    }}
                  </span>
                </div>

                <div v-if="cell == REQUESTED_PENDING" :class="`text-right ${item[REQUESTED_PENDING] < 0 ? 'negative-color' : 'pending-color'} text-right`">
                  {{
                    item[REQUESTED_PENDING] != null &&
                    item[REQUESTED_PENDING] != 0
                      ? item[REQUESTED_PENDING].toFixed(2).toLocaleString()
                      : null
                  }}
                </div>

                <div v-if="cell == REQUESTED" class="text-right">
                  <span v-if="item[REQUESTED] != null && item[REQUESTED] != 0">
                    {{
                      item[REQUESTED] != null && item[REQUESTED] != 0
                        ? item[REQUESTED].toFixed(2).toLocaleString()
                        : null
                    }}
                  </span>
                </div>

                <div v-if="cell == 'enrolled'" class="text-right">
                  <span v-if="item['enrolled'] != null && item['enrolled'] != 0">
                    {{
                      item['enrolled'] != null && item['enrolled'] != 0
                        ? item['enrolled'].toFixed(2).toLocaleString()
                        : null
                    }}
                  </span>
                </div>

                <div v-if="cell == 'submit'" class="py-0 py-md-2">
                  <v-btn
                    class="rounded-lg"
                    :disabled="item[REQUESTED_PENDING] == 0"
                    outlined
                    @click.stop.prevent="submitSupplyChanges({
                      clientId: item['clientId'],
                      cropId: item['crop']['id'][0]
                    })"
                  >
                    Send for review
                  </v-btn>
                </div>

                <div v-if="cell == 'statusUpdate'">
                  <div class="d-flex flex-wrap" v-if="item['statusUpdate'] != null">
                    <p class="w-100 mb-0">{{ item['statusUpdate'] }}</p>
                    <p class="w-100 mb-0 submitted-subline">Submitted</p>
                  </div>
                  <div class="d-flex" v-else>
                    <p class="w-100 mb-0 submitted-subline">Unsubmitted</p>
                  </div>
                </div>

                <div v-if="cell == 'confirmedPending'" class="d-flex position-relative">
                  <v-chip
                    v-if="(item[REQUESTED] || 0) != 0"
                    :class="`${(item[REQUESTED] || 0) < 0 ? 'red' : 'green'}`"
                  >
                    {{ (item[REQUESTED] || 0) < 0 ? '' : '+' }} 
                    {{ (item[REQUESTED] || 0).toFixed(2).toLocaleString() }}
                  </v-chip>
                </div>

                <div v-if="cell == 'confirmAction'" class="py-2">
                  <v-btn
                    @click="handleConfirm(item)"
                    class="rounded-lg"
                    :disabled="(item[REQUESTED] || 0) == 0 
                      || item['evidencing_link'] == null 
                      || item['evidencing_link'].length == 0"
                    outlined
                  >
                    Confirm
                  </v-btn>
                </div>

                <div v-if="cell == 'enrolledAC' && isFirstInRow(item, index)" class="text-right">
                  {{ formatAcreage(item['enrolledAC']) }}
                </div>

                <div v-if="cell == 'enrolledFieldsCount' && isFirstInRow(item, index)" class="text-right">
                  {{ item['enrolledFieldsCount'] }}
                </div>

                <div v-if="cell == 'driveLink'">
                  <v-text-field
                    flat
                    solo
                    hide-details
                    variant="outlined"
                    label="Enter Evidencing link"
                    :value="item['evidencing_link']"
                    @change="v => saveEvidencingLink(v, item)"
                  />
                </div>

                <div v-if="cell == 'enrollmentFile' && isFirstInRow(item, index)">
                   <div v-if="item['enrollment_file_link']" class="py-2">
                    <v-btn
                      @click="handleEnrollmentFileClick(item['groupId'])"
                      class="rounded-lg"
                      outlined
                    >
                      View Contract
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      @click="handleContractGeneration(item)"
                      class="rounded-lg"
                      :disabled="item['canGenerateContract'] == false"
                      outlined
                    >
                      Generate Contract
                    </v-btn>
                  </div>
                </div>

                <div v-if="cell == 'contractId' && isFirstInRow(item, index)" class="text-right">
                  {{ item['contractId'] }}
                </div>

                <div v-if="cell == 'contractStatus' && isFirstInRow(item, index)">
                  <v-chip outlined small :color="CONTRACT_STATUS_MAP[item['contractStatus']['value']]['color']">
                    {{ CONTRACT_STATUS_MAP[item['contractStatus']['value']]['text'] }}
                  </v-chip>
                </div>

                <div v-if="cell == 'contractPreReqs' && isFirstInRow(item, index)">
                  <ul v-if="['contract generated', 'awaiting review', 'approved', 'contract completed'].includes(item['contractStatus']['value'])">
                    <li class="smol-list">{{ item['lockedFields'] }} Locked for Payment</li>
                    <li class="smol-list">{{ item['totalFields'] }} Total</li>
                    <li class="smol-list" v-if="!item['hasMaxcap']">No Payout Info Set</li>
                    <li class="smol-list" v-if="item['programs']['programs'].length == 0">
                      No Programs in group
                    </li>
                    <li class="smol-list" v-else>
                      {{ item['programs']['locked'].length }} / {{ item['programs']['programs'].length }} Programs Locked
                    </li>
                  </ul>

                  <ul v-else>
                    <li class="smol-list">{{ item['confirmedFields'] }} Confirmed</li>
                    <li class="smol-list">{{ item['allocatedFields'] }} Allocated</li>
                    <li class="smol-list">{{ item['requestedFields'] }} Requested</li>
                    <li class="smol-list">{{ item['totalFields'] }} Total</li>
                    <li class="smol-list" v-if="!item['hasMaxcap']">No Payout Info Set</li>
                    <li class="smol-list" v-if="item['programs']['programs'].length == 0">
                      No Programs in group
                    </li>
                    <li class="smol-list" v-else>
                      {{ item['programs']['locked'].length }} / {{ item['programs']['programs'].length }} Programs Locked
                    </li>
                  </ul>
                </div>

                <div v-if="cell == 'notes' && isFirstInRow(item, index)">
                  <div v-if="item['contractStatus']['value'] != 'contract completed'" class="d-flex align-center notes-container">
                    <img
                      v-if="item['notes'] && item['contractStatus']['value'] == 'changes requested'"
                      :src="changeRequestIcon"
                      contain
                      max-height="40"
                      max-width="40"
                      class="notes-icon mr-2"
                    />
                    <img
                      v-else-if="item['notes'] && item['contractStatus']['value'] == 'contract voided'"
                      :src="voidChangeRequestIcon"
                      contain
                      max-height="40"
                      max-width="40"
                      class="notes-icon mr-2"
                    />
                    <v-tooltip bottom max-width="250px">
                      <template v-slot:activator="{ on, attrs }">
                        <div class="truncate-text" v-bind="attrs" v-on="on">
                          {{ item.notes }}
                        </div>
                      </template>
                      <span>{{ item.notes }}</span>
                    </v-tooltip>
                  </div>
                </div>

                <div v-if="cell == 'actions' && isFirstInRow(item, index)">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mx-1"
                        :disabled="item['enrollment_file_link'] == null"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          handleDownloadContract(
                            item['enrollment_file_link'],
                            item['enrollment_file_name']
                          )
                        "
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span v-if="item['contractStatus']['value'] === 'contract completed'">Download Contract</span>
                    <span v-else-if="item['contractStatus']['value'] === 'contract voided'">Download Contract</span>
                    <span v-else>Download Contract</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        small
                        class="mx-1"
                        :disabled="item['enrollment_file_link'] == null || item['contractStatus']['value'] === 'contract completed' || item['contractStatus']['value'] === 'contract voided'"
                        v-bind="attrs"
                        v-on="on"
                        @click="openUploadDialog(item['groupId'])"
                      >
                        mdi-arrow-up
                      </v-icon>
                    </template>
                    <span>Upload Contract</span>
                  </v-tooltip>
                </div>

                <div v-if="cell == 'dateGenerated' && isFirstInRow(item, index)" class="date-cell">
                  <div class="font-weight-bold">
                    {{ getRelativeTime(item['dateGenerated']) }}
                  </div>
                  <div>{{ formatFullDate(item['dateGenerated']) }}</div>
                </div>

                <div v-if="cell == 'dateCountersigned' && isFirstInRow(item, index)" class="date-cell">
                  <div class="font-weight-bold">
                    {{ getRelativeTime(item['dateCountersigned']) }}
                  </div>
                  <div>{{ formatFullDate(item['dateCountersigned']) }}</div>
                </div>

                <div v-if="cell == 'payment' && isFirstInRow(item, index)" class="font-weight-bold text-right">
                  {{ formatCurrency(item['payment']) }}
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <GrowerContractSidebar
      :visible="showGenerateContractSidebar"
      :groupId="focusedGroupId"
      :channelPartnerName="org.name"
      @closeSidebar="handleGrowerContractSidebarClose"
      @generate-contract="handleGenerateContract"
      @send-esign="sendAdditionalESign"
      @download-contract="handleDownloadContract"
      @void-contract="openVoidedReasonDialog"
      @upload-contract="openUploadDialog"
    />

    <v-dialog v-model="showUploadDialog" max-width="650px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Upload Grower Contract for {{ legalEntityDisplay }}</span>
        </v-card-title>

        <v-card-text>
          <v-file-input
            :truncate-length="200"
            v-model="selectedFile"
            label="Choose a file"
            outlined
            dense
            accept=".pdf"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeUploadDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            :disabled="!selectedFile"
            @click="handleUploadContract"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showVoidedReasonDialog" max-width="400">
      <v-card>
        <v-card-title>
          <span class="text-h6">Reason Required</span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-model="voidedReason"
            label="Enter reason for voiding contract"
            placeholder="Type your reason here..."
            outlined
            dense
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="closeVoidedReasonDialog">Cancel</v-btn>
          <v-btn
            color="primary"
            :disabled="!voidedReason"
            @click="handleVoidContract"
          >
            Void Contract
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showESignUrlDialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h6">E-Signature URL</span>
        </v-card-title>

        <v-card-text>
          <div class="d-flex align-center">
            <v-text-field
              v-model="esignUrl"
              label="URL"
              readonly
              outlined
              dense
              class="flex-grow-1"
            ></v-text-field>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="copyToClipboard(esignUrl)"
                >
                  mdi-content-copy
                </v-icon>
              </template>
              <span>{{ copyURLText }}</span>
            </v-tooltip>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="closeESignUrlDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import chevronUp from "@/assets/images/chevron-up.svg"
import Permissions from "@/components/permissions/Permissions"
import RollupTable from "@/components/contractGroups/RollupTable.vue"
import GrowerContractSidebar from "@/components/contractGroups/GrowerContractSidebar.vue"
import { regionAbbrevToFull } from "@/utility"
import { CROP_DISPLAY_NAME, US_STATES } from "@/constants"
import { EnrollmentGroups, Uploads } from "@/store/modules"
import { mapState, mapActions, mapGetters, mapMutations } from "vuex"
import JSZip from "jszip"
import moment from "moment"
import {
  REQUESTED,
  CONFIRMED,
  LOCKED_FOR_PAYMENT,
  REQUESTED_PENDING,
  ENROLLMENT_TABLE,
  CP_VIEW,
  CSM_VIEW,
  YES,
  CONTRACT_STATUS_MAP
} from "@/constants/contractGroups"
import changeRequestIcon from "@/assets/images/change-request.png"
import voidChangeRequestIcon from "@/assets/images/void-change-request.png"

const COMMON_HEADERS = [
  { text: "Regions", value: "regions", sortable: false, class: 'xsmall-cell', filter: v => {
      if (v['selectedRegions'].length == 0) return true
      if (v['selectedRegions'].some(r => v['value'].includes(regionAbbrevToFull(r)))) return true
      return false
    }
  },
  { text: "Crops", value: "crop", sortable: false, class: 'large-cell', filter: v => {
      if (v['selectedCrops'].length == 0) return true
      for (const itemKey of v['selectedCrops']) {
        const [cropId, harvest_type] = itemKey.split('-')

        if (v['clientBased']) {
          const found = v['clientCrops'].find(vv => {
            return vv['id'] == cropId && (
              harvest_type == 'null' && v['harvest_type'] == null
              || harvest_type == v['harvest_type']
            )
          })

          if (found != null) return true
        }
        else {
          if (v['id'].includes(Number(cropId))) {
            if (Number(cropId) != 4) return true
            if (harvest_type == 'null' && v['harvest_type'] == null) return true
            if (harvest_type == v['harvest_type']) return true
          }
        }
      }
      return false
    }
  },
  // { text: "Total Acres", value: "totalAcres", sortable: false, class: 'medium-cell' },
  { text: "Confirmed", value: CONFIRMED, sortable: false, class: 'small-cell' },
  { text: "Locked For Payment", value: LOCKED_FOR_PAYMENT, sortable: false, class: 'small-cell' },

]

const CP_HEADERS = [
  { text: "Pending Request", value: REQUESTED_PENDING, sortable: false, class: 'medium-cell' },
  { text: "Requested AC", value: REQUESTED, sortable: false, class: 'small-cell' },
  { text: "Submit", value: "submit", sortable: false, class: 'medium-cell' },
  { text: "Status update", value: "statusUpdate", sortable: false, class: 'xlarge-cell' }
]

const CSM_HEADERS = [
  { text: "Submitted AC Pending", value: "confirmedPending", sortable: false, class: 'large-cell' },
  { text: "Confirm Fields", value: "confirmAction", sortable: false, class: 'small-cell' },
  { text: "Evidencing Link", value: "driveLink", sortable: false, class: 'xlarge-cell' },
]

const CONTRACT_HEADERS = [
  { text: "Acres in Contract", value: "enrolledAC", class: 'medium-cell' },
  { text: "Fields in Contract", value: "enrolledFieldsCount", class: 'medium-cell' },
  { text: "Contract Pre-reqs", value: "contractPreReqs", class: 'xlarge-cell' },
  { text: "Contract", value: "enrollmentFile", class: 'xlarge-cell' },
  { text: "Contract status", value: "contractStatus", class: 'xlarge-cell', filter: v =>
      v['selectedContractStatuses'].length == 0 || v['selectedContractStatuses'].includes(v['value'])
  },
  { text: "Notes", value: "notes", class: 'xlarge-cell' },
  { text: "Actions", value: "actions", class: 'xsmall-cell' },
  { text: "Contract ID", value: "contractId", class: 'xsmall-cell' },
  { text: "Date generated", value: "dateGenerated", class: 'xlarge-cell' },
  { text: "Countersigned", value: "dateCountersigned", class: 'xlarge-cell' },
  { text: "Payment", value: "payment", class: 'small-cell' },
]

const TEMP_CONTRACT_STATUSES = [
  { text: "Not Ready Yet", value: "not ready yet" },
  { text: "Ready For Generation", value: "ready for generation"},
  { text: "Contract Generated", value: "contract generated" },
  { text: "Awaiting Review", value: "awaiting review" },
  { text: "Changes Requested", value: "changes requested" },
  { text: "Approved", value: "approved" },
  { text: "Contract Completed", value: "contract completed" },
  { text: "Contract Voided", value: "contract voided" },
]

const basePrevent = (e) => {
  e.stopPropagation()
  e.preventDefault()
}

const PREVENT_CELL_EVENT = [
  'submit', 'confirmAction', 'driveLink', 'enrollmentFile', 'contractId', 'contractStatus',
  'actions', 'dateGenerated', 'dateCountersigned', 'payment', 'enrolledAC', 'enrolledFieldsCount'
]

export default {
  name: "ContractGroups",
  components: { Permissions, RollupTable, GrowerContractSidebar },
  data() {
    return {
      changeRequestIcon,
      voidChangeRequestIcon,
      groupDataLoaded: false,
      permissionsLoaded: false,
      focusedGroupId: null,
      voidedContractGroupId: null,
      uploadedContractGroupId: null,
      search: '',
      menu: false,
      nudgeLeft: 0,
      nudgeTop: 0,
      activeTab: '',
      showUploadDialog: false,
      legalEntityDisplay: null,
      selectedFile: null,
      showVoidedReasonDialog: false,
      showESignUrlDialog: false,
      esignUrl: '',
      copyURLText: 'Copy',
      voidedReason: '',
      rollupTableHeight: 222,
      filteredItems: [],
      selectedItems: [],
      selectedCrops: [],
      selectedRegions: [],
      selectedContractStatuses: [],
      entityData: null,
      showGenerateContractSidebar: false,
      CP_HEADERS,
      CSM_HEADERS,
      CROP_DISPLAY_NAME,
      CONFIRMED,
      REQUESTED,
      REQUESTED_PENDING,
      LOCKED_FOR_PAYMENT,
      US_STATES,
      chevronUp,
      CP_VIEW,
      CSM_VIEW,
      PREVENT_CELL_EVENT,
      TEMP_CONTRACT_STATUSES,
      CONTRACT_STATUS_MAP
    }
  },
  computed: {
    ...mapState({
      org: state => state.Organization.organization,
      year: state => state.Organization.year,
      view: state => state.EnrollmentGroups.view,
      isSuperuser: state => state.User.user != null && state.User.user['is_superuser'],
      programData: state => state.EnrollmentGroups.programs,
      allGroupData: state => state.EnrollmentGroups.groupData,
      allClientData: state => state.EnrollmentGroups.clientData,
      enrollmentGroupOrgId: state => state.EnrollmentGroups.org,
      enrollmentTableLoading: state => state.EnrollmentGroups[ENROLLMENT_TABLE],
      featureFlags: state => state.Fields.featureFlags,
    }),
    ...mapGetters({
      allUniqueCrops: EnrollmentGroups.Getters.allUniqueCrops,
      allUniqueRegions: EnrollmentGroups.Getters.allUniqueRegions,
    }),
    corporationPackage() {
      return (
        this.org &&
        this.org.corporation &&
        this.org.corporation.package
      )
    },
    enableCPGrowerContract() {
      return (
        this.org &&
        this.org.app_views &&
        this.corporationPackage.includes("cp-enabled-grower-contract")
      )
    },
    isClientBased() {
      return this.activeTab == 'enrollment'
    },
    isLegalEntityTab() {
      return this.activeTab == 'legalEntity'
    },
    isContractTab() {
      return this.activeTab == 'contract'
    },
    fssData() {
      return this.isClientBased ? this.allClientData : this.allGroupData
    },
    headers() {
      // this is getting out of hand (Jan 10, 2025)
      // checking in here again (Jan 30, 2025)
      const h = []

      if (this.isClientBased) {
        h.push(
          { text: "Enrollment Group Name", value: "name", sortable: false, class: 'xxlarge-cell' },
          ...COMMON_HEADERS,
          ...CP_HEADERS
        )
      }
      else {
        h.push(
          { text: "Legal Entity", value: "legalEntity", sortable: false, class: 'xxlarge-cell'}, 
          { text: "Clients In Entity", value: "clientNames", sortable: false, class: 'xxlarge-cell' },
          ...COMMON_HEADERS,
        )

        if (this.isContractTab) {
          h.push(...CONTRACT_HEADERS)
          h.splice(h.findIndex(({ value }) => value == CONFIRMED), 1)
          h.splice(h.findIndex(({ value }) => value == LOCKED_FOR_PAYMENT), 1)

          if (this.view == CP_VIEW) {
            h.splice(h.findIndex(({ value }) => value == "contractPreReqs"), 1)
          }
        }
        else if (this.isLegalEntityTab) {
          if (this.view == CSM_VIEW) h.push(...CSM_HEADERS)
          else {
            h.splice(-1, 1)
            h.push(
              { text: "Requested AC", value: REQUESTED, sortable: false, class: 'small-cell' },
              { text: "Enrolled AC", value: 'enrolled', sortable: false, class: 'small-cell' },
            )
          }
        }
      }

      return h
    },
    allUniqueRenderedGroupIds() {
      return this.filteredItems.reduce((accum, { groupId, clientId }) => {
        if (this.isClientBased && !accum.includes(clientId)) accum.push(clientId)  
        else if (!this.isClientBased && !accum.includes(groupId)) accum.push(groupId)
        return accum
      }, [])
    },
    allOtherHeights() {
      // navbar + page container margin top + h1 + h1 margin + table + table result + table margin
      const external = 60 + 20 + 48 + 16 + this.rollupTableHeight + 65 + 24

      // table borders, table y padding + "top" slot + "top" slot margin
      const internal = 2 + 24 + 60 + 12

      const vSlotOffset = this.featureFlags?.grower_contract ? 24 : 0

      const rollupHead = 58

      // 24px of artificial padding at bottom
      const extra = 30

      return external + internal + vSlotOffset + rollupHead + extra
    },
    shimmedFilteredFormattedItems() {
      //console.log(this.items)

      // if contract tab, combine like-group rows
      //    also remove rows of unconfirmed groups that have 0 confirmed/matched fields
      if (this.isContractTab) {


        return this.items.reduce((accum, curr) => {
          const found = accum.find(({ groupId }) => curr['groupId'] == groupId)
          if (found == null) {
            if (curr['legalEntity']) accum.push(curr)
            else if (curr['allocatedFields'] != 0 || curr['confirmedFields'] != 0) accum.push(curr)
          }
          else {
            for (const cropToCombine of curr['crop']['clientCrops']) {
              const foundCrop = found['crop']['id'].find(id => cropToCombine['id'] == id)
              if (foundCrop == null) {
                found['crop']['id'].push(cropToCombine['id'])
                found['crop']['clientCrops'].push(cropToCombine)
                found['cropIdKey'] = `${found['cropIdKey']}-${cropToCombine['id']}`
              }
            }
            for (const regionToCombine of curr['regions']['value']) {
              const foundRegion = found['regions']['value'].find(r => regionToCombine == r)
              if (foundRegion == null) {
                found['regions']['value'].push(regionToCombine)
              }
            }
          }

          return accum
        }, [])
      }

     
      return this.items
    },
    items() {

      // client-based gets crop/region breakout rows
      // entity-based gets one row per entity
      const rows = []

      for (const id in this.fssData) {
        const fssRows = []
        const {
          name, timestamps, cropRegionMap, crops, regions, acreageValues, clientNames,
          fieldsupply, evidencing_links, enrollment, status, contract_outdated,
          can_generate_contract, has_maxcap, is_confirmed
        } = this.fssData[id]

 

        // calculate top-level contract pre-reqs
        const topLevelStatuses = { allocated: 0 }
        topLevelStatuses['allocated'] = fieldsupply
          .filter(({ status, matched_program_id }) => status == CONFIRMED && matched_program_id != null)
          .length
        
        topLevelStatuses[LOCKED_FOR_PAYMENT] = fieldsupply
          .filter(({ status, matched_program_id }) => status == LOCKED_FOR_PAYMENT && matched_program_id != null)
          .length

        for (const s of [CONFIRMED, REQUESTED]) {
          topLevelStatuses[s] = 0
          if (acreageValues.hasOwnProperty(s)) {
            for (const cropId in acreageValues[s]) {
              for (const harvestType in acreageValues[s][cropId]) {
                for (const reg in acreageValues[s][cropId][harvestType]) {
                  topLevelStatuses[s] += acreageValues[s][cropId][harvestType][reg]['numFields']
                }
              }
            }
          }
        }

        for (const cropId in cropRegionMap) {
          for (const harvest_type in cropRegionMap[cropId]) {
            const programs = fieldsupply.reduce((accum, { matched_program_id }) => {
              if (matched_program_id != null) {
                if (!accum['programs'].includes(matched_program_id)) {
                  accum['programs'].push(matched_program_id)
                }
                if (this.programData[matched_program_id] && !accum['locked'].includes(matched_program_id)) {
                  accum['locked'].push(matched_program_id)
                }
              }

              return accum
            }, { programs: [], locked: [] })

            const enrolledCropSpecific = fieldsupply.reduce((accum, curr) => {
              if ([CONFIRMED, LOCKED_FOR_PAYMENT].includes(curr['status'])) {
                if (
                  curr['crop_id'] == cropId && (
                    (curr['harvest_type'] == harvest_type)
                    || (curr['harvest_type'] == null && harvest_type == 'null')
                  )
                ) accum += curr['acreage']
              }
              return accum
            }, 0)

            const result = {
              name,
              clientNames,
              cropIdKey: `${id}-${cropId}`,
              totalAcres: fieldsupply.reduce((accum, { acreage }) => accum += acreage, 0),
              totalFields: fieldsupply.length,
              programs,
              allocatedFields: topLevelStatuses['allocated'],
              confirmedFields: topLevelStatuses[CONFIRMED],
              requestedFields: topLevelStatuses[REQUESTED],
              lockedFields: topLevelStatuses[LOCKED_FOR_PAYMENT],
              enrolled: enrolledCropSpecific, // crop-specific
              crop: {
                harvest_type,
                id: [Number(cropId)],
                clientCrops: crops,
                selectedCrops: this.selectedCrops,
                clientBased: this.isClientBased
              },
              regions: {
                value: cropRegionMap[cropId][harvest_type]['regions'].sort((a, b) => a.localeCompare(b)),
                selectedRegions: this.selectedRegions,
              },
              statusUpdate: null,
              evidencing_link: evidencing_links != null ? evidencing_links[cropId] : null,
              legalEntity: is_confirmed,
              groupId: !this.isClientBased ? id : null,
              clientId: this.isClientBased ? id : null,
              contractStatus: {
                value: status != null ? status : null,
                selectedContractStatuses: this.selectedContractStatuses
              },
              isContractOutdated: contract_outdated != null ? contract_outdated : null,
              canGenerateContract: can_generate_contract != null ? can_generate_contract : null,
              hasMaxcap: has_maxcap != null ? has_maxcap : null,
              contractId: enrollment != null && enrollment['file_link'] ? enrollment['contract_id'] : null,
              enrollment_file_name: enrollment != null && enrollment['file_name'] ? this.displayFileName(enrollment['file_name']) : null,
              enrollment_file_link: enrollment != null && enrollment['file_link'] ? enrollment['file_link'] : null,
              dateGenerated: enrollment != null && enrollment['date_generated'] ? enrollment['date_generated'] : null,
              dateCountersigned: enrollment != null && enrollment['date_counter_signed'] ? enrollment['date_counter_signed'] : null,
              signnowURL: enrollment != null && enrollment['signnow_url'] ? enrollment['signnow_url'] : null,
              notes: enrollment != null && enrollment['notes'] ? enrollment['notes'] : null,
              enrolledAC: enrollment != null && enrollment['enrolled_ac'] ? enrollment['enrolled_ac'] : null,
              enrolledFieldsCount: enrollment != null && enrollment['enrolled_fields_ct'] ? enrollment['enrolled_fields_ct'] : null,
              payment: enrollment != null && enrollment['total_payment'] ? enrollment['total_payment'] : null,
            }

            if (result['clientNames']!= undefined){
              result['clientNames'] = result['clientNames'].join(", ")
            }

            for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING,LOCKED_FOR_PAYMENT]) {
              if (!result.hasOwnProperty(s)) result[s] = 0
  
              for (const region of result['regions']['value']) {
                const found = US_STATES.find(({ text }) => region == text);
                if (found != null && this.selectedRegions.length != 0 && !this.selectedRegions.includes(found['value']))
                  continue
  
                if (acreageValues[s].hasOwnProperty(cropId)) {
                  if (this.selectedCrops.length != 0) {
                    const found = this.selectedCrops.find(key => {
                      const [crop, harvestType] = key.split('-')
                      const cropMatch = Number(cropId) == Number(crop)
                      if (cropMatch) {
                        if (harvestType == 'null' && harvest_type == null) return true
                        return cropMatch && harvestType == harvest_type
                      }
                    })
  
                    if (found == null) continue
                  }
  
                  if (region in acreageValues[s][cropId][harvest_type]) {
                    result[s] += acreageValues[s][cropId][harvest_type][region]['acreage']
                  }
                }
              }
            }

            fssRows.push(result)
          }
        }

        fssRows.sort((a, b) => 
          CROP_DISPLAY_NAME[a['crop']['id'][0]].localeCompare(CROP_DISPLAY_NAME[b['crop']['id'][0]])
        )

        // doing this after the fact as it's a shim and the above logic should be correct long-term
        // take all winter wheat rows and combine them with wheat rows, if they both exist
        const wheatRow = fssRows.find(({ crop }) => crop['id'].includes(7))
        const winterWheatRow = fssRows.find(({ crop }) => crop['id'].includes(30))

        if (winterWheatRow != null && wheatRow != null) {
          winterWheatRow['remove'] = true

          wheatRow['crop']['id'] = [7, 30]
          wheatRow['evidencing_link'] = evidencing_links != null ? evidencing_links['7-30'] : null

          for (const s of [CONFIRMED, REQUESTED, REQUESTED_PENDING, LOCKED_FOR_PAYMENT]) {
            wheatRow[s] += winterWheatRow[s]
          }

          for (const r of winterWheatRow['regions']['value']) {
            if (!wheatRow['regions']['value'].includes(r)) {
              wheatRow['regions']['value'].push(r)
            }
          }
          wheatRow['regions']['value'].sort((a, b) => a.localeCompare(b))
        }

        const filteredGroupRows = fssRows.filter(({ remove }) => {
          if (remove) return false
          return true
        })

        rows.push(...filteredGroupRows)
      }
      
      rows.sort((a, b) => a['name'].localeCompare(b['name']))
      rows.sort((a, b) => b['legalEntity'] - a['legalEntity'])
      return rows
    }
  },
  methods: {
    basePrevent,
    regionAbbrevToFull,
    ...mapActions({
      fetchRollup: EnrollmentGroups.Actions.fetchRollup,
      getContractTabMetadata: EnrollmentGroups.Actions.getContractTabMetadata,
      submitSupplyChanges: EnrollmentGroups.Actions.submitSupplyChanges,
      confirmSupplyChanges: EnrollmentGroups.Actions.confirmSupplyChanges,
      submitEvidencingLink: EnrollmentGroups.Actions.submitEvidencingLink,
      submitContractGeneration: EnrollmentGroups.Actions.submitContractGeneration,
      submitVoidContract: EnrollmentGroups.Actions.submitVoidContract,
      submitContractEsign: EnrollmentGroups.Actions.submitContractEsign,
      submitContractAdditionalEsign: EnrollmentGroups.Actions.submitContractAdditionalEsign,
      createUpload: Uploads.Actions.create,
      fetchFssMetadata: EnrollmentGroups.Actions.fetchFssMetadata,
    }),
    ...mapMutations({
      setView: EnrollmentGroups.Mutations.setView,
      setTableLoadingState: EnrollmentGroups.Mutations.setTableLoadingState,
    }),
    isFirstInRow(item, index) {
      const idx = this.filteredItems.findIndex(({ groupId, clientId }) => {
        if (this.isClientBased) {
          return clientId == item['clientId']
        }
        else return groupId == item['groupId']
      }) 
      
      return idx == index
    },
    handleConfirm(item) {
      const fss_to_confirm = this.fssData[item['groupId']]['fieldsupply']
      .filter(fss =>
        item['crop']['id'].includes(fss['crop_id'])
        && item['regions']['value'].includes(fss['state_name'])
        && fss['status'] == REQUESTED
        && fss['supply_state'] == YES
        && (
          item['crop']['harvest_type'] == 'null' && fss['harvest_type'] == null 
          || item['crop']['harvest_type'] == fss['harvest_type']
        )
      )
      .map(({ id }) => id)
      
      this.confirmSupplyChanges({ fss_to_confirm })
    },
    saveEvidencingLink(newLink, { crop, groupId }) {
      const { evidencing_links } = this.fssData[groupId]
      const updated_link_obj = { ...evidencing_links }

      const cropKey = crop['id'].join('-')
      updated_link_obj[cropKey] = newLink

      this.submitEvidencingLink({ group_id: groupId, updated_link_obj })
    },
    handleUpdateSelected({ crops, region }) {
      if (
        crops.every(c => this.selectedCrops.includes(c))
        && crops.length == this.selectedCrops.length
        && region.every(r => this.selectedRegions.includes(r))
        && region.length == this.selectedRegions.length
      ) {
        this.selectedCrops = []
        this.selectedRegions = []
      }
      else {
        this.selectedCrops = crops
        this.selectedRegions = region
      }
    },
    handleContractGeneration(item) {
      this.focusedGroupId = item['groupId']
      this.showGenerateContractSidebar = true
    },
    handleGrowerContractSidebarClose() {
      this.focusedGroupId = null
      this.showGenerateContractSidebar = false
    },
    updateRollupTableHeight(height) {
      this.rollupTableHeight = height
    },
    handleDataTableFiltering(value, search, item) {
      if (search == null || search.length == 0) return true

      const groupName = item['name'].toLowerCase()
     
      if (groupName.includes(search)) return true
      if ( item['clientNames'] != undefined){
        const clientName = item['clientNames'].toLowerCase()
        if (clientName.includes(search)) return true
      }
      return false
    },
    handleClick({ groupId, clientId }) {
      if (this.isClientBased) this.$router.push(`/enrollment-groups/client/${clientId}`)
      else this.$router.push(`/enrollment-groups/${groupId}`)
    },
    getRelativeTime(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      const now = new Date();
      const diffInMs = now - date;
      const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

      if (diffInDays === 0) {
        return 'Today';
      } else if (diffInDays < 30) {
        return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`
      } else if (diffInDays < 365) {
        const months = Math.floor(diffInDays / 30)
        return `${months} month${months > 1 ? 's' : ''} ago`
      } else {
        const years = Math.floor(diffInDays / 365)
        return `${years} year${years > 1 ? 's' : ''} ago`
      }
    },
    formatFullDate(dateString) {
      if (!dateString) return ''
      const date = new Date(dateString)
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      })
    },
    formatCurrency(amount) {
      if (!amount) return ''
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      }).format(amount)
    },
    formatAcreage(acreage) {
      if (acreage == null) return ''
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(acreage);
    },
    displayFileName(filePath) {
      return filePath.split('/').pop();
    },
    handleGenerateContract(groupId, landOwnership) {
      this.submitContractGeneration({ groupId, landOwnership })
        .then(data => {
          console.log('Contract generated successfully:', data)
        })
        .catch(error => {
          console.error('Error generating contract:', error)
        })
    },
    async handleSendESignRequest(groupId) {
      const enrollment = this.fssData[groupId]['enrollment']
      const enrollmentId = enrollment['id'] || null
      await this.submitContractEsign({ groupId, enrollmentId })
      this.openESignUrlDialog(this.fssData[groupId]['enrollment']['signnow_url'])
    },
    async sendAdditionalESign(groupId) {
      const enrollment = this.fssData[groupId]['enrollment']
      const enrollmentId = enrollment['id'] || null
      await this.submitContractAdditionalEsign({ groupId, enrollmentId })
    },
    openESignUrlDialog(url) {
      this.copyURLText = 'Copy'
      this.esignUrl = url
      this.showESignUrlDialog = true
    },
    closeESignUrlDialog() {
      this.showESignUrlDialog = false
      this.esignUrl = ''
    },
    copyToClipboard(text) {
      this.copyURLText = 'Copied!'
      navigator.clipboard.writeText(text)
    },
    async handleDownloadContract(url, fileName) {
      try {
        const response = await fetch(url)
        if (!response.ok) {
          this.setTableLoadingState({ target: ENROLLMENT_TABLE, loading: false, error: error })
        }
        const blob = await response.blob()
        const objectURL = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = objectURL
        a.download = fileName;
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(objectURL)
      } catch (error) {
        this.setTableLoadingState({ target: ENROLLMENT_TABLE, loading: false, error: error })
      }
    },
    openUploadDialog(groupId) {
      this.legalEntityDisplay = this.items.filter(item => item['groupId'] == groupId)[0]['name']
      this.uploadedContractGroupId = groupId
      this.showUploadDialog = true
    },
    closeUploadDialog() {
      this.showUploadDialog = false
      this.selectedFile = null
      this.uploadedContractGroupId = null
    },
    async handleUploadContract() {
      this.showUploadDialog = false
      this.setTableLoadingState({ target: ENROLLMENT_TABLE, loading: true })
      const groupData = this.fssData[this.uploadedContractGroupId]
      const enrollment = groupData.enrollment || {}
      const enrollment_id = enrollment.id || null
      if (this.selectedFile) {
        const uploadSpec = {
          datasetType: "grower-contract",
          orgId: this.org["id"],
          specs: {
            signatureType: "grower-signed-contract",
            enrollmentId: enrollment_id,
          },
        }
        const zip = new JSZip()
        zip.file(this.selectedFile.name, await this.selectedFile.arrayBuffer())
        const result = await zip.generateAsync({ type: "blob" })
        const datetimeStr = moment().format("YYYY-MM-DD_h:mm:ss")
        const newZipName = `${uploadSpec.specs.enrollmentId}-grower-contract-${datetimeStr}.zip`
        uploadSpec["file"] = new File([result], newZipName)
        this.createUpload(uploadSpec)
          .then(_ => {
            this.fetchFssMetadata({ groupId: this.uploadedContractGroupId, clientId: null })
          })
          .catch(error => {
            console.log('Error uploading contract:', error)
          })
        }
    },
    openVoidedReasonDialog(groupId) {
      this.voidedContractGroupId = groupId
      this.showVoidedReasonDialog = true
    },
    closeVoidedReasonDialog() {
      this.showVoidedReasonDialog = false
      this.voidedReason = ''
      this.voidedContractGroupId = null
    },
    handleVoidContract() {
      const groupId = this.voidedContractGroupId
      const groupData = this.fssData[groupId]
      const enrollment = groupData.enrollment || {}
      const enrollmentId = enrollment.id || null
      const voidedReason = this.voidedReason
      this.submitVoidContract({ groupId, enrollmentId, voidedReason })
      this.closeVoidedReasonDialog()
    },
    handleViewContractOnSignNow() {
      console.log('view contract on sign now')
    },
    handleEnrollmentFileClick(groupId) {
      this.focusedGroupId = groupId
      this.showGenerateContractSidebar = true
    },
    getTabParam() {
      const url = new URL(window.location.href)
      const tab = url.searchParams.get('tab')
      return tab
    }
  },
  mounted() {
    const badOrg = this.org != null && this.enrollmentGroupOrgId != null && this.org.id != this.enrollmentGroupOrgId
    if (Object.keys(this.fssData).length == 0 || badOrg) {
      if (this.org != null && this.year != null) this.fetchRollup({ fetchOverview: true })
    }

    if (Object.keys(this.allGroupData).length > 0) {
      this.groupDataLoaded = true
      this.permissionsLoaded = true
      this.activeTab = this.getTabParam()
    }

    if (this.org && this.org.app_views) {
      this.permissionsLoaded = true
    }
  },
  watch: {
    org() {
      if (this.year != null) this.fetchRollup({ fetchOverview: true })
    },
    year() {
      if (this.org != null) this.fetchRollup({ fetchOverview: true })
    },
    enableCPGrowerContract() {
      this.permissionsLoaded = true
    },
    'enrollmentTableLoading.loading'(curr, prev) {
      if (!curr && prev) {
        this.groupDataLoaded = true
        this.activeTab = this.getTabParam()
      }
    },
    activeTab(curr, prev) {
      if (!this.permissionsLoaded || !this.groupDataLoaded) return
      if (
        curr != 'contract' 
        || (
          curr == 'contract' 
          && (
            this.enableCPGrowerContract
            || this.view == CSM_VIEW
          )
        )
      ) {
        const curUrl = new URL(window.location.href)
        curUrl.searchParams.set('tab', curr)
        window.history.replaceState(null, '', curUrl)
      }
      if (curr == 'contract' && this.groupDataLoaded) {
        this.getContractTabMetadata()
      }
    },
    showUploadDialog(curr) {
      if (!curr) {
        this.selectedFile = null
      }
    }
  }
}
</script>

<style scoped>
p {
  color: #000000;
}
h1 {
  color: #000;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  transition: color 0.25s;
}
.superuser-view h1 {
  color: #ffffff;
}
.v-data-table :deep(tr) {
  cursor: pointer;
}
.enrollment-group-table > :deep(.v-data-table__wrapper) {
  transition: height 0.25s ease-in-out;
  height: calc(100vh - var(--table-height-modifier, 0));
  overflow-y: scroll;
}
:deep(.v-data-table-header) {
  background: #ffffff;
  position: sticky;
  top: 0;
  z-index: 4;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: fill;
}
.v-card__title {
  color: #20292f;
  font-size: 24px;
}
.v-card__subtitle {
  color: #6b7280;
  line-height: 20px;
}
.v-chip.v-size--default {
  height: 20px !important;
}
.enrollment-group-table :deep(table) {
  table-layout: fixed;
  border-collapse: separate;
}
.enrollment-group-table :deep(th.xxlarge-cell) {
  width: 250px;
}
.enrollment-group-table :deep(th.xlarge-cell) {
  width: 200px;
}
.enrollment-group-table :deep(th.large-cell) {
  width: 175px;
}
.enrollment-group-table :deep(th.medium-cell) {
  width: 150px;
}
.enrollment-group-table :deep(th.small-cell) {
  width: 125px;
}
.enrollment-group-table :deep(th.xsmall-cell) {
  width: 125px;
}
.enrollment-group-table :deep(.v-data-table__selected td),
.enrollment-group-table :deep(tr:hover td) {
  background: #f5f5f5 !important;
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(td:nth-of-type(9)),
.enrollment-group-table :deep(th:nth-of-type(1)) {
  position: sticky;
  z-index: 2;
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(td:nth-of-type(2)),
.enrollment-group-table :deep(th:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(2)) {
  background: #ffffff;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.enrollment-group-table :deep(td:nth-of-type(1)),
.enrollment-group-table :deep(th:nth-of-type(1)) {
  left: 0;
  z-index: 3;
}
.enrollment-group-table :deep(.v-btn) {
  color: #487f06;
  letter-spacing: normal;
  text-transform: none;
  border-color: #c7c9ce;
}
.enrollment-group-table :deep(tr[data-is-first="1"]:has(+ tr[data-is-first="0"]) td),
.enrollment-group-table :deep(tr[data-is-first="0"]:has(+ tr[data-is-first="0"]) td) {
  border-bottom: none !important;
}
.enrollment-group-table :deep(tr td:nth-of-type(9) p) {
  line-height: 1.5 !important;
}
/* v-select overrides */
.v-input.v-select {
  width: 144px;
  height: 40px;
  max-width: unset;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  border-radius: 8px;
}
:deep(.v-menu__content) {
  max-width: 300px;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(div[role="button"]) {
  background: #6b7280;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(.v-input__append-inner:nth-last-of-type(2) > div) {
  opacity: 1;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(.v-input__append-inner:last-of-type) {
  display: none;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(div[role="button"] p),
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(div[role="button"] i),
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(div[role="button"] button.mdi-close) {
  color: white;
}
.v-input.v-select.v-input--is-label-active:not(.all-selected)
  :deep(div[role="button"] p span) {
  padding: 1px 2px;
  color: black;
  background: white;
  border-radius: 2px;
}
.v-input.v-select :deep(div[role="button"]) {
  min-height: 40px;
}
.v-chip.gray {
  background: #F3F4F6 !important;
}
.v-chip.gray :deep(span) {
  color: #374151;
}
.v-chip.green {
  background: transparent !important;
}

.v-chip.green :deep(span) {
  color: #61B100;
  font-weight: bold;
}
.v-chip.red {
  background: #FEF2F2 !important;
}
.v-chip.red :deep(span) {
  color: #EF4444;
  font-weight: bold;
}
.v-chip.legal-entity-needed {
  background: #FFFBEB !important
}
.v-chip.legal-entity-needed :deep(span) {
  color: #D97706 !important
}
.v-chip.orange {
  background: #dbb482 !important;
}
.v-chip.orange :deep(span) {
  color: #f97316;
  font-weight: bold;
}
.v-tabs {
  margin-bottom: 20px;
  min-width: 200px;
  border-bottom: none;
  box-shadow: none;;
}
.v-tab {
  font-size: 14px;
  text-transform: none;
  padding: 0 16px;
  color: #4caf50;
  transition: color 0.3s ease-in-out;
  letter-spacing: normal;
}
.vertical-top {
  vertical-align: top;
}
.v-tab:hover {
  color: #388e3c;
}
.v-tab--active {
  color: #4caf50 !important;
  font-weight: bold;
}
.v-tabs-slider {
  background-color: #4caf50 !important;
  height: 2px;
}
.search-input :deep(.v-input__slot) {
  min-height: unset !important;
  height: 40px;
}
.search-input :deep(.v-input__prepend-inner) {
  margin: 2px 0 0;
  align-self: center;
}
.all-selected :deep(::placeholder) {
  color: #374151 !important;
  opacity: 1;
}
::v-deep(.v-input__control input) {
  cursor: pointer;
}
.v-progress-circular {
  position: absolute;
  z-index: 1;
  left: calc(50% - 24px);
  top: calc(50% - 24px);
}
.loading {
  opacity: 0.5;
  pointer-events: none;
}
.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  background: #ffffff;
}
.v-btn.no-click-events {
  pointer-events: none;
}
.v-btn p {
  margin: 0;
  line-height: 1.125;
  text-align: left;
  font-weight: normal;
}
.v-btn p:last-of-type {
  font-size: 12px;
}
.v-icon {
  cursor: pointer;
  transition: background-color 0.2s ease;
  border-radius: 50%;
  padding: 4px;
}
.v-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.v-icon:active {
  background-color: rgba(0, 0, 0, 0.2);
}
.v-menu {
  z-index: 2000;
}
.submitted-subline {
  color: #4b5563;
  font-size: 14px;
  line-height: 20px;
}
.enrollment-container {
  height: calc(100vh - 80px);
}
.pending-color {
  color: #0e7490;
}
.negative-color {
  color: #ef4444 !important;
}
.locked-for-payment-color{
  color: #436f0e;
  font-weight: bold;
}
.h-44 {
  height: 44px !important;
}
#enrollment-heading, #contract-heading {
  scroll-margin-left: 275px;
}
.clickable-link {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
.smol-list {
  font-size: 12px;
}
.notes-container {
  display: flex;
  align-items: center;
  max-width: 250px;
  position: relative;
}
.truncate-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  max-width: 200px;
}

@media screen and (max-width: 1276px) {
  /* just for testing, basically */
  h1 {
    font-size: 24px;
    line-height: 1.5;
  }

  .h-44 {
    height: 36px !important;
  }

  .v-card__title {
    font-size: 20px;
    line-height: 1;
  }

  /* v-select overrides */
  .v-input.v-select {
    height: 24px;
    border-radius: 6px;
    font-size: 16px;
  }
  .v-input.v-select :deep(div[role="button"]) {
    min-height: 24px;
  }

  .search-input :deep(.v-input__slot) {
    height: 32px;
  }

  .enrollment-group-table > :deep(.v-data-table__wrapper) {
    height: calc(calc(100vh - var(--table-height-modifier, 0)) + 168px);
  }

  .enrollment-group-table :deep(td) {
    padding: 0 8px !important;
    height: 24px !important;
    min-height: unset !important;
  }

  .enrollment-group-table :deep(th) {
    height: 32px !important;
    padding: 0 8px !important;
  }

  table td,
  table span,
  .enrollment-group-table :deep(.v-btn__content) {
    font-size: 12px !important;
  }

  table .v-btn:not(.v-btn--round).v-size--default {
    height: 24px;
  }

  .enrollment-container {
    margin-top: 12px !important;
  }
}
</style>