<template>
  <b-sidebar
    width="80vw"
    id="sidebar-right"
    :title="getSidebarTitle"
    right
    shadow
    backdrop
    bg-variant="white"
    v-model="show"
  >
    <v-progress-circular
      v-if="profilesLoading['loading']"
      indeterminate
      :size="64"
      color="#79c61c"
    />
    <div
      :class="`px-8 sidebar-body-inner-padding-bottom ${
        profilesLoading['loading'] ? 'loading' : ''
      }`"
    >
      <v-snackbar v-model="showSuccessPopup" top timeout="3000" color="success">
        E-sign sent successfully!
      </v-snackbar>
      <div v-if="pdfUrl && !showClientProfileView" class="mb-4">
        <iframe
          :src="pdfUrl"
          width="100%"
          height="700px"
          frameborder="0"
          allowfullscreen
        ></iframe>
        <v-card
          v-if="
            groupData['status'] != 'contract voided' &&
            featureFlags?.e_sign_for_multi_signers
          "
          width="100%"
          outlined
          class="mt-4 pa-2 custom-rounded"
        >
          <v-card-title class="text-h6 font-weight-bold"
            >E-sign status</v-card-title
          >
          <v-card-subtitle>
            After the primary signer completes their signature, you'll have the
            option to send this document to more signers and update their
            contact details.
            <br />
            If you check "Landowner Signature Required", the contract cannot be
            completed until the landowner has signed.
            <br />
            Be sure to validate emails before sending as
            <strong
              >contracts may only be sent to additional signers once.</strong
            >
          </v-card-subtitle>

          <div class="e-sign-divider"></div>

          <div v-if="groupData['enrollment']['signnow_url'] == null" class="mt-4">
            <v-btn @click="getEsignURL">
              <v-icon>mdi-pencil</v-icon>
              Enable e-sign
            </v-btn>
          </div>
          <v-data-table
            v-else
            :items="signers"
            :headers="headers"
            dense
            hide-default-footer
            class="mt-4"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td :class="{ 'disabled-row': isSignerDisabled(item) }">
                  {{ item.participant }}
                </td>
                <td :class="{ 'disabled-row': isSignerDisabled(item) }">
                  <v-chip
                    :class="{
                      required: isSignerDisabled(item),
                      'status-chip signed':
                        item.has_signed && !isSignerDisabled(item),
                      'status-chip not-signed':
                        !item.has_signed && !isSignerDisabled(item),
                    }"
                    small
                  >
                    {{ item.has_signed ? "Signed" : "Not signed" }}
                  </v-chip>
                </td>

                <td
                  :class="{
                    'disabled-row': isSignerDisabled(
                      item,
                      'signature_required'
                    ),
                  }"
                >
                  <v-chip
                    v-if="
                      item.participant === 'Authorized Rep.' ||
                      item.participant === 'Additional Signer' ||
                      item.has_signed || hasSentEsign || onlyLandOwnerNotRequiredExists
                    "
                    small
                    :class="{
                      'status-chip signed': item.signature_required,
                      'status-chip required': !item.signature_required,
                    }"
                  >
                    {{ item.signature_required ? "Required" : "Not Required" }}
                  </v-chip>
                  <v-select
                    v-else
                    v-model="signers[index].signature_required"
                    :items="[
                      { text: 'Required', value: true },
                      { text: 'Not Required', value: false },
                    ]"
                    dense
                    outlined
                    hide-details
                    @change="
                      updateAdditionalSigner(
                        index,
                        'signature_required',
                        $event
                      )
                    "
                  ></v-select>
                </td>

                <td>
                  <span
                    v-if="
                      item.participant === 'Authorized Rep.' ||
                      item.has_signed || hasSentEsign || onlyLandOwnerNotRequiredExists
                    "
                  >
                    {{ item.first_name }}</span
                  >
                  <v-text-field
                    v-else
                    v-model="signers[index].first_name"
                    placeholder="Add first name"
                    dense
                    outlined
                    hide-details
                    :rules="[requiredValidator]"
                    :disabled="isSignerDisabled(item, 'first_name')"
                    @change="
                      updateAdditionalSigner(index, 'first_name', $event)
                    "
                  ></v-text-field>
                </td>

                <td>
                  <span
                    v-if="
                      item.participant === 'Authorized Rep.' ||
                      item.has_signed || hasSentEsign || onlyLandOwnerNotRequiredExists
                    "
                  >
                    {{ item.last_name }}</span
                  >
                  <v-text-field
                    v-else
                    v-model="signers[index].last_name"
                    placeholder="Add last name"
                    dense
                    outlined
                    hide-details
                    :rules="[requiredValidator]"
                    :disabled="isSignerDisabled(item, 'last_name')"
                    @change="updateAdditionalSigner(index, 'last_name', $event)"
                  ></v-text-field>
                </td>

                <td>
                  <div v-if="item.participant === 'Authorized Rep.'">
                    <v-btn
                      v-if="!item.has_signed && !item.url"
                      color="green"
                      dark
                      @click="getEsignURL(index)"
                    >
                      Get e-sign URL
                    </v-btn>
                    <v-row v-else-if="!item.has_signed">
                      <v-col cols="10">
                        <v-text-field
                          v-model="item.url"
                          label="URL"
                          dense
                          readonly
                          outlined
                          hide-details
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              medium
                              v-bind="attrs"
                              v-on="on"
                              class="mt-2"
                              @click="copyToClipboard(item.url)"
                            >
                              mdi-content-copy
                            </v-icon>
                          </template>
                          <span>{{ copyURLText }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </div>
                  <v-text-field
                    v-else-if="
                      !item.has_signed && !hasSentEsign && !onlyLandOwnerNotRequiredExists
                    "
                    v-model="signers[index].poc_email"
                    placeholder="Add email"
                    dense
                    outlined
                    hide-details
                    :rules="[requiredValidator, emailValidator]"
                    :disabled="isSignerDisabled(item, 'poc_email')"
                    @change="updateAdditionalSigner(index, 'poc_email', $event)"
                  ></v-text-field>
                </td>
              </tr>
            </template>
          </v-data-table>

          <!-- Action Buttons -->
          <v-row class="pa-4 justify-end">
            <v-btn
              v-if="
                additionalSignerExists &&
                !hasSentEsign &&
                !hasAdditionalSignerOrLandOwnerSigned
              "
              color="green darken-1 mr-4"
              :class="{ 'disabled-row': isSendESignDisabled }"
              dark
              @click="sendESign()"
            >
              <v-icon left>mdi-send</v-icon> Send to additional participants
            </v-btn>
          </v-row>
        </v-card>
      </div>

      <div v-else class="contract-input-group pb-16">
        <div class="mt-5">
          <p class="ma-0 mb-3 font-weight-bold confirm-contract-text">
            Overview
          </p>
          <v-row class="mb-2">
            <v-col cols="6">
              <p class="font-weight-bold">Legal Entity Name:</p>
              <p>{{ entityName || "N/A" }}</p>
            </v-col>
            <v-col cols="6">
              <p class="font-weight-bold">Channel Partner Name:</p>
              <p>{{ channelPartnerName || "N/A" }}</p>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-0 mb-2">
          <v-col>
            <p class="ma-0 font-weight-bold confirm-contract-text">
              Authorized representative
            </p>
          </v-col>
        </v-row>

        <SingularClientProfile
          :isCPProfile="true"
          :selected="
            groupData['cp_profiles'].length > 0
              ? groupData['cp_profiles'][0]
              : null
          "
          @updateProfile="updateGroupProfile"
        />

        <div class="pt-8 mb-5">
          <p class="ma-0 font-weight-bold confirm-contract-text">
            Land Ownership
          </p>
          <v-radio-group v-model="landOwnership">
            <v-row>
              <v-col cols="4" class="d-flex align-center">
                <div>
                  <v-radio label="Rented" value="rented"></v-radio>
                  <p class="text-caption text-grey ml-8">
                    Land is rented by signatory
                  </p>
                </div>
              </v-col>
              <v-col cols="4" class="d-flex align-center">
                <div>
                  <v-radio label="Owned" value="owned"></v-radio>
                  <p class="text-caption text-grey ml-8">
                    Land is owned by signatory
                  </p>
                </div>
              </v-col>
            </v-row>
          </v-radio-group>
        </div>

        <v-expansion-panels
          multiple
          accordion
          v-model="growerProfilePanelIndices"
          v-if="
            makingNewGrowerProfile || groupData['grower_profiles'].length > 0
          "
          class="mb-4"
        >
          <ClientProfileEditor
            v-for="(profile, idx) in groupData['grower_profiles']"
            :key="profile + idx"
            :selected="profile"
            :clientProfiles="clientProfiles"
            :isCPProfile="false"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />

          <ClientProfileEditor
            v-if="makingNewGrowerProfile"
            :selected="null"
            :clientProfiles="clientProfiles"
            :isCPProfile="false"
            @modificationStatusChange="handleProfileStatusChange"
            @addProfile="addProfile"
            @swapProfile="swapProfile"
            @removeProfile="removeProfile"
            @createProfile="createGroupProfile"
            @updateProfile="updateGroupProfile"
            @cancelCreation="cancelCreation"
          />
        </v-expansion-panels>

        <v-row class="mt-0 mb-0">
          <v-col cols="4" class="d-flex flex-wrap py-0">
            <v-btn
              @click="makingNewGrowerProfile = true"
              :disabled="makingNewCPProfile || makingNewGrowerProfile"
              :ripple="false"
              outlined
            >
              <v-icon>mdi-plus-circle-outline</v-icon>
              <span class="ml-2">Add additional signer</span>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </div>

    <div
      :class="`contract-navigation-handlers ${
        profilesLoading['loading'] ? 'loading' : ''
      }`"
    >
      <div v-if="pdfUrl && !showClientProfileView">
        <v-btn
          outlined
          height="40"
          class="custom-btn mr-2"
          @click="removeSidebar()"
        >
          <v-icon left>mdi-close</v-icon> Close
        </v-btn>
        <v-btn
          v-if="showVoidBtn"
          outlined
          height="40"
          class="custom-btn void-btn mr-2"
          @click="voidContract()"
        >
          <img
            :src="voidIcon"
            contain
            max-height="20"
            max-width="20"
            class="mr-2"
          />
          Void
        </v-btn>
        <v-btn
          v-if="showUploadBtn"
          outlined
          height="40"
          class="custom-btn void-btn mr-2"
          @click="uploadContract()"
        >
          <img 
            :src="uploadIcon"             
            contain
            max-height="20"
            max-width="20"
            class="mr-2"
          />
          Upload
        </v-btn>
        <v-btn
          color="green"
          height="40"
          class="custom-btn download-btn white--text"
          @click="downloadContract()"
        >
          <img
            :src="downloadIcon"
            contain
            max-height="20"
            max-width="20"
            class="mr-2"
          />
          Download
        </v-btn>
        <v-btn
          v-if="!showVoidBtn && generateContractBtnText"
          color="green"
          height="40"
          class="custom-btn download-btn white--text"
          @click="showClientProfileView = true"
          >{{ generateContractBtnText }}</v-btn
        >
        <!-- <v-btn
          outlined
          height="40"
          class="custom-btn mr-2"
          @click="refreshSidebar()"
        >
          <v-icon left>mdi-refresh</v-icon> Refresh
        </v-btn> -->
      </div>
      <div v-else>
        <v-btn
          outlined
          height="40"
          class="custom-btn mr-2"
          @click="removeSidebar()"
        >
          <v-icon left>mdi-close</v-icon> Close
        </v-btn>
        <v-btn
          v-if="generateContractBtnText"
          color="green"
          height="40"
          class="custom-btn download-btn white--text"
          :disabled="!generateContractIsEnabled"
          @click="generateContract()"
          >{{ generateContractBtnText }}</v-btn
        >
        <!-- <v-btn
          outlined
          height="40"
          class="custom-btn mr-2"
          @click="refreshSidebar()"
        >
          <v-icon left>mdi-refresh</v-icon> Refresh
        </v-btn> -->
      </div>
    </div>
  </b-sidebar>
</template>

<script>
import Vue from "vue"
import ClientProfileEditor from "@/components/contractGroups/ClientProfileEditor.vue"
import SingularClientProfile from "@/components/contractGroups/SingularClientProfile.vue"
import { EnrollmentGroups } from "@/store/modules"
import { mapActions, mapMutations, mapState } from "vuex"
import {
  GROWER,
  ADD_PROFILE,
  REMOVE_PROFILE,
  UPDATE_PROFILE,
  SWAP_PROFILE,
  CREATE_PROFILE,
  CHANNEL_PARTNER,
  BLANK_GROUP_DATA,
  CLIENT_PROFILES,
} from "@/constants/contractGroups"
import { requiredValidator, emailValidator } from "@/utility"
import uploadIcon from "@/assets/images/upload-icon.png"
import downloadIcon from "@/assets/images/download-icon.png"
import voidIcon from "@/assets/images/void-icon.png"

const CONTRACT_HEADERS = [
  { text: "Date generated", valuef: "dateGenerated" },
  { text: "Due", value: "due" },
  { text: "Contract Owners", value: "contractOwners" },
  { text: "Status", value: "status" },
  { text: "LOI File", value: "loiFile" },
  { text: "LOI Last Sent", value: "loiLastSent" },
  { text: "Actions", value: "actions" },
]

const FIELD_HEADERS = [
  { text: "Field", value: "field" },
  { text: "Crop", value: "crop" },
  { text: "Farm", value: "farm" },
  { text: "Field ID", value: "fieldId" },
  { text: "Acres", value: "acres" },
]

export default {
  name: "GrowerContractSidebar",
  props: {
    groupId: { type: Number | String },
    visible: { type: Boolean },
    channelPartnerName: { type: String },
  },
  emits: ["closeSidebar"],
  components: { ClientProfileEditor, SingularClientProfile },
  data() {
    const clientProfileModificationsExist = {}
    clientProfileModificationsExist[CHANNEL_PARTNER] = {}
    clientProfileModificationsExist[GROWER] = {}

    return {
      voidIcon,
      uploadIcon,
      downloadIcon,
      show: false,
      showSuccessPopup: false,
      cancelled: true,
      clientProfileModificationsExist,
      contractInputGroup: "groupDetails",
      dataForLOIModal: {},
      landOwnership: "owned",
      copyURLText: "Copy",
      landOwnershipDetails: "",
      entityName: "",
      makingNewCPProfile: false,
      makingNewGrowerProfile: false,
      showClientProfileView: false,
      cpProfilesOpen: 0,
      growerProfilePanelIndices: [],
      cpProfile: [],
      cpEmailsPreopen: [],
      growerEmailsPreopen: [],
      CONTRACT_HEADERS,
      FIELD_HEADERS,
      CHANNEL_PARTNER,
      GROWER,
      headers: [
        { text: "Participant", value: "participant", width: "16%" },
        { text: "Status", value: "status", width: "11%" },
        { text: "Sign Required", value: "signature_required", width: "18%" },
        { text: "First Name", value: "first_name", width: "16%" },
        { text: "Last Name", value: "last_name", width: "16%" },
        { text: "Actions", value: "poc_email", width: "23%" },
      ],
      requiredValidator,
      emailValidator,
    }
  },
  computed: {
    ...mapState({
      year: state => state.Organization.year,
      org: state => state.Organization.organization,
      profilesLoading: state => state.EnrollmentGroups[CLIENT_PROFILES],
      clientProfiles: state => state.EnrollmentGroups.allClientProfiles,
      groupData: function (state) {
        const data = state.EnrollmentGroups.groupData[this.groupId]
        if (data == null) return structuredClone(BLANK_GROUP_DATA)
        return data
      },
      featureFlags: state => state.Fields.featureFlags,
    }),
    isSignerDisabled() {
      return (signer, field) => {
        if (signer.participant === "Authorized Rep.") return false

        if (signer.participant === "Land Owner") {
          if (field === "signature_required") return false
          if (signer.signature_required === false) return true
        }

        return !this.hasAuthorizedRepSigned
      }
    },
    getSidebarTitle() {
      if (this.groupData == null || this.groupData["name"] == null)
        return "Grower Contract"
      return `Grower Contract for ${this.groupData["name"]}`
    },
    showVoidBtn() {
      return (
        this.groupData["status"] != "contract voided" &&
        this.groupData["status"] != "contract completed"
      )
    },
    showUploadBtn() {
      return (
        this.groupData["status"] != "contract voided" &&
        this.groupData["status"] != "contract completed"
      )
    },
    hasAuthorizedRepSigned() {
      return this.signers.some(
        signer => signer.participant === "Authorized Rep." && signer.has_signed
      )
    },
    onlyLandOwnerNotRequiredExists() {
      return (
        this.signers.filter(
          signer =>
            (signer.participant === "Additional Signer" ||
            signer.participant === "Land Owner") && !signer.has_signed
        ).length === 1 &&
        this.signers.some(
          signer =>
            signer.participant === "Land Owner" && !signer.signature_required
        )
      )
    },
    hasSentEsign() {
      return this.signers.some(
        signer =>
          (signer.participant === "Additional Signer" ||
            signer.participant === "Land Owner") &&
          this.groupData["enrollment"]["sent_emails"].includes(signer.poc_email)
      )
    },
    additionalSignerExists() {
      return this.signers.some(
        signer =>
          (signer.participant === "Additional Signer" ||
            signer.participant === "Land Owner") &&
          !(signer.participant === "Land Owner" && !signer.signature_required)
      )
    },
    hasAdditionalSignerOrLandOwnerSigned() {
      return this.signers.some(
        signer =>
          (signer.participant === "Additional Signer" ||
            signer.participant === "Land Owner") &&
          signer.has_signed &&
          !(signer.participant === "Land Owner" && !signer.signature_required)
      )
    },
    hasIncompleteAdditionalSigners() {
      return this.signers.some(
        signer =>
          (signer.participant === "Additional Signer" ||
            signer.participant === "Land Owner") &&
          !(
            signer.participant === "Land Owner" && !signer.signature_required
          ) &&
          (!signer.first_name?.trim() ||
            !signer.last_name?.trim() ||
            !signer.poc_email?.trim())
      )
    },
    isSendESignDisabled() {
      return (
        !this.hasAuthorizedRepSigned ||
        this.hasAdditionalSignerOrLandOwnerSigned ||
        this.hasIncompleteAdditionalSigners ||
        !this.additionalSignerExists ||
        this.hasSentEsign
      )
    },
    pdfUrl() {
      return this.groupData["enrollment"]["file_link"]
    },
    filename() {
      return this.groupData["enrollment"]["file_name"].split("/").pop()
    },
    generateContractBtnText() {
      if (
        this.groupData["status"] === "awaiting review" ||
        this.groupData["status"] === "contract completed"
      )
        return null
      if (!this.showClientProfileView && this.pdfUrl)
        return "Re-generate Contract"
      else return "Generate Contract"
    },
    generateContractIsEnabled() {
      // if any of the existing client profiles have unsaved changes, don't allow the
      // contract to be generated... i.e., force profiles to have static, saved changes
      for (const key in this.clientProfileModificationsExist[GROWER]) {
        if (this.clientProfileModificationsExist[GROWER][key] == true)
          return false
      }

      // we need all client profiles to be complete, otherwise the contract will have missing data
      // for (const profile of this.groupData['cp_profiles']) {
      //   if (!profile['validated']) return false
      // }
      return true
    },
    signers() {
      return [
        ...(this.groupData["cp_profiles"]?.map(profile => ({
          ...profile,
          participant: "Authorized Rep.",
          url: this.groupData["enrollment"]["signnow_url"],
        })) || []),
        ...(this.groupData["grower_profiles"]?.map(profile => ({
          ...profile,
          participant: "Additional Signer",
          url: null,
        })) || []),
        ...(this.groupData["landowner_profiles"]?.map(profile => ({
          ...profile,
          participant: "Land Owner",
          url: null,
        })) || []),
      ]
    },
  },
  methods: {
    ...mapActions({
      editClientProfiles: EnrollmentGroups.Actions.editClientProfiles,
      fetchClientProfiles: EnrollmentGroups.Actions.fetchClientProfiles,
      fetchGroupClientProfiles:
        EnrollmentGroups.Actions.fetchGroupClientProfiles,
      getContractTabMetadata: EnrollmentGroups.Actions.getContractTabMetadata,
      submitContractEsign: EnrollmentGroups.Actions.submitContractEsign,
      submitContractAdditionalEsign:
        EnrollmentGroups.Actions.submitContractAdditionalEsign,
    }),
    ...mapMutations({
      setTableLoadingState: EnrollmentGroups.Mutations.setTableLoadingState,
    }),
    cancelCreation({ as_cp_profile }) {
      if (as_cp_profile) this.makingNewCPProfile = false
      else this.makingNewGrowerProfile = false
    },
    addProfile({ as_cp_profile, to_id }) {
      this.editClientProfiles({
        type: ADD_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          client_profile_id: to_id,
          as_cp_profile,
        },
      }).then(data => {
        data = Object.values(data)[0]
        this.maintainExpansionStateAfterModification({ as_cp_profile, data })
      })
    },
    removeProfile({ as_cp_profile, remove_id }) {
      this.editClientProfiles({
        type: REMOVE_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          as_cp_profile,
          remove_id,
        },
      }).then(data => {
        data = Object.values(data)[0]
        this.maintainExpansionStateAfterModification({ as_cp_profile, data })
      })
    },
    swapProfile({ as_cp_profile, from_id, to_id }) {
      this.editClientProfiles({
        type: SWAP_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          as_cp_profile,
          from_id,
          to_id,
        },
      }).then(data => {
        data = Object.values(data)[0]
        this.maintainExpansionStateAfterModification({ as_cp_profile, data })
      })
    },
    updateGroupProfile({ as_cp_profile, profile_dataset, profile_id }) {
      this.editClientProfiles({
        type: UPDATE_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          profile_dataset,
          as_cp_profile,
          profile_id,
        },
      })
    },
    createGroupProfile({ as_cp_profile, profile_dataset }) {
      this.editClientProfiles({
        type: CREATE_PROFILE,
        group_id: this.groupId,
        payload: {
          year: Number(this.year),
          org_node_id: this.org["id"],
          group_id: this.groupId,
          profile_dataset,
          as_cp_profile,
        },
      })
      this.makingNewGrowerProfile = false
    },
    setLocalTrackers() {
      this.entityName = this.groupData["name"]
    },
    handleProfileStatusChange({ as_cp_profile, profileId, status }) {
      if (as_cp_profile)
        Vue.set(
          this.clientProfileModificationsExist[CHANNEL_PARTNER],
          profileId,
          status
        )
      else
        Vue.set(this.clientProfileModificationsExist[GROWER], profileId, status)
    },
    updateAdditionalSigner(index, field, value) {
      Vue.set(this.signers[index], field, value)
      this.updateGroupProfile({
        as_cp_profile: false,
        profile_dataset: this.signers[index],
        profile_id: this.signers[index].id,
      })
    },
    removeSidebar() {
      this.$emit("closeSidebar", this.cancelled)
      this.entityName = ""
      this.cancelled = true
      this.showClientProfileView = false
      this.clientProfileModificationsExist[CHANNEL_PARTNER] = {}
      this.clientProfileModificationsExist[GROWER] = {}
    },
    refreshSidebar() {
      this.fetchGroupClientProfiles({
        group_id: this.groupId,
      })
    },
    cancel() {
      this.cancelled = true
      this.show = false
    },
    apply() {
      this.cancelled = false
      this.show = false
    },
    copyToClipboard(text) {
      this.copyURLText = "Copied"
      navigator.clipboard.writeText(text)

      setTimeout(() => {
        this.copyURLText = "Copy"
      }, 5000)
    },
    async getEsignURL() {
      const groupId = this.groupId
      const enrollmentId = this.groupData["enrollment"]["id"]
      await this.submitContractEsign({ groupId, enrollmentId })
    },
    maintainExpansionStateAfterModification({ as_cp_profile, data }) {
      const targetIndices = as_cp_profile
        ? "cpProfile"
        : "growerProfilePanelIndices"
      const targetMakeKey = as_cp_profile
        ? "makingNewCPProfile"
        : "makingNewGrowerProfile"
      const profileKey = as_cp_profile ? "cp_profiles" : "grower_profiles"

      const newAddition = data[profileKey].find(({ id }) =>
        this.groupData[profileKey].every(c => c["id"] != id)
      )
      const newRemoval = this.groupData[profileKey].find(({ id }) =>
        data[profileKey].every(c => c["id"] != id)
      )

      const indices = this[targetIndices].slice()
      const idsByIndices = this.groupData[profileKey]
        .map(({ id }, idx) => (indices.includes(idx) ? id : null))
        .filter(i => i)

      const newIndices = data[profileKey]
        .map(({ id }, idx) => {
          if (newRemoval && newRemoval["id"] == id) return null
          if (newAddition && newAddition["id"] == id) return idx
          if (idsByIndices.includes(id)) return idx
          return null
        })
        .filter(d => d != null)

      if (this[targetMakeKey]) this[targetMakeKey] = false
      setTimeout(() => Vue.set(this, targetIndices, newIndices))
    },
    generateContract() {
      this.$emit("generate-contract", this.groupId, this.landOwnership)
      this.showClientProfileView = false
    },
    async sendESign() {
      const groupId = this.groupId
      const enrollmentId = this.groupData["enrollment"]["id"]
      await this.submitContractAdditionalEsign({ groupId, enrollmentId })
      this.showSuccessPopup = true
    },
    downloadContract() {
      this.$emit("download-contract", this.pdfUrl, this.filename)
      this.apply()
    },
    voidContract() {
      this.$emit("void-contract", this.groupId)
      this.apply()
    },
    uploadContract() {
      this.$emit("upload-contract", this.groupId)
      this.apply()
    },
  },
  watch: {
    visible(curr) {
      this.show = curr
    },
    show(curr) {
      if (!curr) this.removeSidebar()
      else {
        this.fetchClientProfiles({ group_id: this.groupId })
        this.setLocalTrackers()
      }
    },
    org() {
      this.fetchClientProfiles({ group_id: this.groupId })
    },
    makingNewCPProfile(curr) {
      if (curr) {
        Vue.set(
          this,
          "cpProfile",
          this.groupData["cp_profiles"].length == 0
            ? [0]
            : [...this.cpProfile, this.groupData["cp_profiles"].length]
        )
      }
    },
    makingNewGrowerProfile(curr) {
      if (curr) {
        Vue.set(
          this,
          "growerProfilePanelIndices",
          this.groupData["grower_profiles"].length == 0
            ? [0]
            : [
                ...this.growerProfilePanelIndices,
                this.groupData["grower_profiles"].length,
              ]
        )
      }
    },
  },
}
</script>

<style scoped>
#sidebar-right {
  background: #ffffff !important;
}

p {
  color: #000000;
}

.sticky-top {
  position: sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.contracts-button-toggle > button {
  text-transform: none;
  padding: 12px 24px !important;
  letter-spacing: normal;
  border-radius: 0 !important;
  border: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25) !important;
  color: #374151;
}

.contracts-button-toggle
  > button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background: none !important;
}

.v-btn {
  text-transform: none;
  letter-spacing: normal;
  border-radius: 8px;
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04),
    0px 1px 2px 0px rgba(16, 24, 40, 0.04);
}

.v-btn .v-btn__content > i {
  font-size: 20px;
}

.contracts-button-toggle > button.v-item--active {
  color: #61af02;
  border-bottom: 2px solid #61b100 !important;
  background: #f1fddf !important;
}

.contracts-button-toggle > button.v-item--active::before {
  display: none;
}

.contract-input-group p {
  color: #212121;
}

.contract-navigation-handlers button {
  border-color: rgba(0, 0, 0, 0.5);
}

.contract-navigation-handlers > div > button:not(:last-child) {
  margin-right: 12px;
}

.text-caption {
  font-size: 0.875rem;
  color: #6b7280;
  margin-top: -4px;
}

p.disabled-label {
  opacity: 0.5;
}

::v-deep .fips-option-not-supported + ::before {
  background-color: red !important;
}

::v-deep .b-sidebar-outer {
  z-index: 9999999999;
}

::v-deep #sidebar-right {
  overflow: hidden;
}

.sidebar-body-inner-padding-bottom {
  padding-bottom: 96px;
}

::v-deep .contract-navigation-handlers {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 32px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
}
::v-deep header.b-sidebar-header {
  padding: 32px 32px 0;
  position: relative;
}
::v-deep header.b-sidebar-header > button.close {
  font-size: 32px;
}
::v-deep header.b-sidebar-header > strong {
  width: 100%;
  text-align: left;
  font-size: 32px;
  color: #000000;
  margin-left: 12px;
}
.confirm-contract-text {
  font-size: 23px;
  line-height: 33px;
}
.legal-business-name-text {
  font-size: 10px;
  line-height: 20px;
}
:deep(.v-label) {
  color: #000000;
}
:deep(.v-data-table-header) {
  z-index: 1;
}
.thumbnail {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.contract-table-header {
  font-size: 24px;
  line-height: 32px;
}
.contract-table-subheader {
  font-size: 14px;
  line-height: 20px;
  color: #6b7280;
}
:deep(.v-data-table__empty-wrapper > td) {
  padding-top: 16px !important;
}
:deep(.v-list-item__title) {
  text-transform: lowercase;
}
.v-expansion-panels {
  z-index: 0;
}
.loading {
  pointer-events: none;
  opacity: 0.5;
}
.v-progress-circular {
  position: absolute;
  left: calc(50% - 36px);
  top: calc(50% - 36px);
}
.v-tooltip__content {
  background: rgba(255, 255, 255, 0.9);
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.v-tooltip__content :deep(ul) {
  padding: 0;
}
.v-tooltip__content :deep(ul li) {
  list-style: none;
}
.v-tooltip__content :deep(i) {
  color: #000000;
}
::v-deep(.v-data-table__wrapper tbody tr) {
  display: table-row;
  padding-top: 16px;
  padding-bottom: 16px;
}
::v-deep(.v-data-table__wrapper tbody td) {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.custom-rounded {
  border-radius: 16px;
}
.status-chip {
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 16px;
}
.not-signed {
  background-color: #feecea !important;
  color: #d32f2f !important;
}
.signed {
  background-color: #f0faf2 !important;
  color: #43a047 !important;
}
.required {
  background-color: #f2f3f5 !important;
  color: #2e3138 !important;
}
.e-sign-divider {
  height: 1px;
  background-color: #e5e7eb;
}
.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}
</style>
