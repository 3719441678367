<template>
  <ArvaModal
    name="UploadReviewModal"
    :title="`Review Incoming Changes for ${upload.name_display}`"
    :wide="true"
    :width="1200"
    @close-modal="handleClose"
  >
    <template v-slot:additional-icon>
      <v-btn
        icon
        @click="collapseTop = !collapseTop"
        >
        <v-icon>{{ collapseTop ? "mdi-arrow-expand" : "mdi-arrow-collapse" }}</v-icon>
        </v-btn>
    </template>

    <div v-show="!collapseTop" class="row pt-1">
      <div class="col-md-5 pr-2">
        <div class="detail-row">
          <div>Uploaded at:</div>
          <div>{{ upload.created_at | shortDatetime }}</div>
        </div>
        <div class="detail-row">
          <div>Uploaded by:</div>
          <div>{{ uploadedBy }}</div>
        </div>
        <div class="detail-row">
          <div>Dataset Type:</div>
          <div>{{ upload.dataset_type_display }}</div>
        </div>
        <div class="detail-row">
          <div>Upload #ID:</div>
          <div>{{ upload.id }}</div>
        </div>
        <div v-if="!isReadOnly" class="download">
          <a :href="upload.raw_data">
            <i class="fa fa-download" />Download dataset
          </a>
        </div>
        <div class="parsed-files mt-4">
          <div class="parsed-title">Parsed files:</div>
          <div class="file-status" v-if="parsedFiles.length > 0">
            <i
              class="fa"
              :class="{
                'fa-check': !parsedFiles[0].failed,
                'fa-times': parsedFiles[0].failed,
              }"
            />
            {{ parsedFiles[0].filename }}
          </div>
        </div>
      </div>

      <div
        class="col-md-7"
        v-if="!expired && currentFilename && parseDetails[currentFilename]"
      >
        <div class="d-flex flex-column">
          <div v-if="chartSeries.length > 0" class="chart-container">
            <apexchart
              type="pie"
              height="270"
              :options="chartOptions"
              :series="chartSeries"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 d-flex justify-space-between">

      <div class="d-flex flex-column">

        <div v-if="!expired && currentFilename && totalItems(currentFilename)">
          <div class="changes-summary text-subtitle-1">
            <span v-if="!activeFilter">
              <strong>{{ totalItems(currentFilename) }}</strong> pending changes
            </span>
            <span v-else>
              Showing
              <strong>{{ filteredItems(currentFilename).length }}</strong> out
              of {{ totalItems(currentFilename) }} pending changes
            </span>
            <div v-if="shapefileOverwriteCount(currentFilename) > 0" class="pt-2">
              <span class="red--text font-weight-bold">*</span>
                <strong>{{ shapefileOverwriteCount(currentFilename) }} changes modifying machine-derived values</strong>
            </div>
          </div>
        </div>
        <div v-if="expired">
          <strong>Expired:</strong> This upload has been pending for more than
          24 hours.
        </div>
        <div v-else><span class="text-caption">{{ timeLeft }} left to review changes</span></div>

      </div>

      <div class="d-flex" v-if="!expired && currentFilename && totalItems(currentFilename)">
        <v-select
          v-model="selectedGroup"
          :items="groupOptions"
          dense
          item-text="text"
          item-value="value"
          hide-details
          label="Groups"
          @change="generateChartData"
          style="max-width: 200px"
          class="mr-2"
        ></v-select>

        <v-select
          v-model="activeFilter"
          :items="chartOptions.labels || []"
          dense
          clearable
          hide-details
          label="Filter by"
          @change="handleFilterChange"
          placeholder="N/A"
          style="max-width: 200px"
        ></v-select>
      </div>

    </div>

    <div v-if="currentFilename && totalItems(currentFilename)" class="table-container">
      <v-data-table
        :headers="parseDetails[currentFilename].headers.filter(h => h.value !== 'overwrite_shapefile')"
        :items="filteredItems(currentFilename)"
        multi-sort
        hide-actions
        dense
        :hide-default-footer="filteredItems(currentFilename).length < 10"
        class="changes-table"
      >
        <template v-slot:item.change_type="{ item }">
          <span v-html="getChangeTypeWithIcon(item)"></span>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <span
            class="d-flex text-h6 mb-3 justify-center"
            ><strong>No changes detected.</strong></span
          >
    </div>

    <ArvaModalSaveFooter
      v-if="!expired && filteredItems(currentFilename).length > 0"
      confirmText="Accept Import"
      cancelText="Cancel Import"
      @on-cancel="handleReject"
      @on-confirm="handleConfirm"
    />
    <ArvaModalSaveFooter
      v-else
      cancelText="Cancel Import"
      @on-cancel="handleReject"
    />
  </ArvaModal>
</template>

<script>
import { mapState } from "vuex"
import ArvaModal from "./ArvaModal"
import ArvaModalSaveFooter from "@/components/modals/ArvaModalSaveFooter"
import UploadsAPI from "@/api/UploadsAPI"
import VueApexCharts from "vue-apexcharts"
import { schemeGreens } from "d3-scale-chromatic"
export default {
  name: "UploadReviewModal",

  components: {
    ArvaModal,
    ArvaModalSaveFooter,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      upload: {},
      collapseTop: false,
      selectedGroup: "year",
      selectedPieIndex: null,
      activeFilter: null,
      chartOptions: {
        states: {
          active: {
            filter: {
              type: "none", // disable built-in color xforms on click
            },
          },
        },
        chart: {
          type: "pie",
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              if (config.dataPointIndex === this.selectedPieIndex) {
                this.clearFilter()
              } else {
                this.applyFilter(config.dataPointIndex)
              }
            },
            dataPointMouseEnter: () => {
              document.body.style.cursor = 'pointer';
            },
            dataPointMouseLeave: () => {
              document.body.style.cursor = 'default';
            }
          },
        },
        labels: [],
        colors: [],
        legend: {
          position: "right",
          width: 300,
          formatter: function (seriesName, opts) {
            return seriesName + ": " + opts.w.globals.series[opts.seriesIndex]
          },
        },
        tooltip: {
          y: {
            formatter: function (value) {
              return value + " changes"
            },
          },
        },
      },
      chartSeries: [],
      chartLabels: [],
      groupOptions: [
        { text: "Year", value: "year" },
        { text: "Record Type", value: "record_type" },
        { text: "Client", value: "client" },
        { text: "Change Type", value: "change_type" },
        { text: "Farm", value: "farm" },
      ],
    }
  },

  computed: {
    ...mapState({
      isReadOnly: state => state.User.user.permission === "Read-Only",
      uploadID: state => state.Uploads.reviewModalUploadID,
      uploads: state => state.Uploads.uploads,
    }),

    uploadedBy() {
      const { user } = this.upload
      if (!user) return ""
      if (user.first_name && user.last_name)
        return `${user.first_name} ${user.last_name}`
      return user.email
    },

    msToExpiration() {
      if (!this.upload.created_at) return ""

      const now = new Date()
      const uploadDate = new Date(this.upload.created_at)
      const expirationDate = new Date(
        uploadDate.getTime() + 24 * 60 * 60 * 1000
      ) // 24 hours from creation
      const diffMs = expirationDate - now
      return diffMs
    },

    timeLeft() {
      const diffMs = this.msToExpiration
      const hoursRemaining = Math.max(0, Math.floor(diffMs / (60 * 60 * 1000)))

      if (hoursRemaining <= 0) {
        return "Expired"
      } else if (hoursRemaining > 0) {
        return `${hoursRemaining} hours`
      }
    },

    expired() {
      return this.msToExpiration <= 0
    },

    parsedFiles() {
      const { successful_parses = [], failed_parses = [] } = this.upload

      let successfulParses = []
      if (Array.isArray(successful_parses)) {
        successfulParses = successful_parses.map(filename => ({
          filename,
          failed: false,
        }))
      } else {
        successfulParses = Object.keys(successful_parses).map(filename => ({
          filename,
          failed: false,
        }))
      }

      return successfulParses
    },

    parseDetails() {
      const { successful_parses = [] } = this.upload
      if (successful_parses && !Array.isArray(successful_parses)) {
        let parseDetails = {}

        for (let [filename, items] of Object.entries(successful_parses)) {
          let headers = []
          if (items && items.length > 0) {
            headers = Object.keys(items[0]).map(key => ({
              text: key.replace("_", " "),
              value: key,
            }))
          }

          parseDetails[filename] = {
            items: items,
            headers: headers,
          }

          const requiredHeaders = [
            "farm",
            "year",
            "client",
            "field_id",
            "field_name",
            "record_type",
            "change_type",
          ]
          const missingHeaders = requiredHeaders.filter(
            header => !headers.some(h => h.value === header)
          )
          if (missingHeaders.length > 0) {
            console.error(
              `Missing required headers: ${missingHeaders.join(", ")}`
            )
            return {}
          }
        }
        return Object.freeze(parseDetails)
      } else {
        return {}
      }
    },

    currentFilename() {
      return this.parsedFiles && this.parsedFiles.length > 0
        ? this.parsedFiles[0].filename
        : null
    },
  },

  methods: {
    async handleReject() {
      await UploadsAPI.reviewUpload(this.upload.id, { approved: false, canceled: true })
      this.$emit("close-modal")
    },

    async handleConfirm() {
      await UploadsAPI.reviewUpload(this.upload.id, { approved: true , canceled: false})
      this.$emit("close-modal")
    },

    handleClose() {
      this.$emit("close-modal")
    },

    getChangeTypeWithIcon(item) {
      if (item.overwrite_shapefile === true) {
        return `${item.change_type}<span class="red--text font-weight-bold">*</span>`
      }
      return item.change_type
    },

    shapefileOverwriteCount(filename) {
      const items = this.filteredItems(filename)
      return items.filter(item => item.overwrite_shapefile === true).length
    },

    filteredItems(filename) {
      if (!this.parseDetails[filename]) return []

      const items = this.parseDetails[filename].items

      if (!this.activeFilter) return items

      return items.filter(
        item => String(item[this.selectedGroup]) === String(this.activeFilter)
      )
    },

    totalItems(filename) {
      return this.parseDetails[filename]?.items?.length || 0
    },

    generateChartData() {
      if (!this.currentFilename || !this.parseDetails[this.currentFilename]) {
        this.chartSeries = []
        this.chartOptions.labels = []
        return
      }

      this.activeFilter = null

      const items = this.parseDetails[this.currentFilename].items
      const groupCounts = {}

      // Count occurrences of each value in the selected group
      items.forEach(item => {
        const value = String(item[this.selectedGroup] || "N/A")
        if (!groupCounts[value]) {
          groupCounts[value] = 0
        }
        groupCounts[value]++
      })

      const series = []
      const labels = []
      const colors = []

      Object.entries(groupCounts).forEach(([key, count], index) => {
        series.push(count)
        labels.push(key)
        colors.push(this.generateColorShade(index))
      })

      this.chartSeries = series
      this.chartOptions = {
        ...this.chartOptions,
        labels: labels,
        colors: colors,
      }
    },
    generateColorShade(index) {
      const colors = schemeGreens[6].slice().reverse() // reverse for darkest first
      return colors[Math.min(index % colors.length, colors.length - 1)]
    },

    updateChartColors() {
      if (!this.chartOptions.labels) return

      const highlightColor = "#0f9aee" // a blue we use for selected segment
      const colors = []

      for (let i = 0; i < this.chartOptions.labels.length; i++) {
        if (i == this.selectedPieIndex) {
          colors.push(highlightColor)
        } else {
          colors.push(this.generateColorShade(i))
        }
      }

      this.chartOptions = {
        ...this.chartOptions,
        colors: colors,
      }
    },

    applyFilter(index) {
      if (
        index === undefined ||
        index < 0 ||
        !this.chartOptions.labels ||
        index >= this.chartOptions.labels.length
      ) {
        return
      }
      this.selectedPieIndex = index
      this.activeFilter = this.chartOptions.labels[index]
      this.updateChartColors()
    },

    handleFilterChange(value) {
      if (!value) {
        this.clearFilter()
        return
      }

      const index = this.chartOptions.labels.findIndex(label => label === value)
      if (index !== -1) {
        this.selectedPieIndex = index
        this.updateChartColors()
      } else {
        this.selectedPieIndex = null
        this.updateChartColors()
      }
    },

    clearFilter() {
      this.activeFilter = null
      this.selectedPieIndex = null
      this.updateChartColors()
    },
  },

  watch: {
    parseDetails: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.generateChartData()
        })
      },
    },
  },

  async mounted() {
    const upload = this.uploads.find(upload => upload.id === this.uploadID)
    if (upload) {
      this.upload = upload
    }
  },
}
</script>

<style scoped>
.detail-row {
  display: flex;
  margin-bottom: 8px;
}

.detail-row > div:first-of-type {
  font-weight: bold;
  min-width: 110px;
}

.download {
  margin-top: 12px;
  font-weight: bold;
}

.download i {
  margin-right: 5px;
}

.row {
  padding: 0 16px;
}

.parsed-files {
  margin-top: 20px;
}

.parsed-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.file-status {
  font-size: 14px;
  padding-left: 5px;
}

.file-status i {
  width: 14px;
  margin-right: 8px;
}

.fa-check {
  color: #00a200;
}

.fa-times {
  color: #e00000;
}

.filter-label {
  font-size: 14px;
}

.chart-container {
  width: 100%;
  align-self: center;
}

.changes-summary {
  font-weight: bold;
}

.table-container {
  max-height: 600px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 4px;
}

.changes-table {
  width: 100%;
}

/* turn off apexcharts misc animations */
.apexcharts-tooltip,
.apexcharts-tooltip.active,
.apexcharts-xaxistooltip,
.apexcharts-xaxistooltip.active,
.apexcharts-marker {
  transition: none;
}
</style>
