<template>
  <div>
    <div
      v-if="
        fieldSupplySummaryDataFiltered.length > 0 &&
        fieldSupplySummaryData != undefined
      "
    >
      <v-toolbar class="d-flex justify-end">
        <v-text-field
          class="mr-4"
          v-model="includedSearch"
          label="Search By Attribute"
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          hide-details
        ></v-text-field>
        <v-text-field
          class="mr-4"
          label="Search by Field ID"
          v-model="fieldIDString"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          variant="solo"
          hide-details
          clearable
          @click:clear="fieldIDSearch('')"
          @click:prepend-inner="fieldIDSearch(fieldIDString)"
          @keydown.enter="fieldIDSearch(fieldIDString)"
        ></v-text-field>
        <v-btn
          class="ma-2 py-6"
          :color="multiAllocateLabel == 'Allocate' ? 'primary' : 'red'"
          v-if="
            selectedFSSForAllocationFiltered.length > 0 &&
            multiAllocateType != null
          "
          @click="handleFSSDetailsModal(selectedFSSForAllocationFiltered)"
        >
          {{ multiAllocateLabel }}
          {{ selectedFSSForAllocationFiltered.length }} Field Crops <br />(
          {{ selectedAcreage }} acres)</v-btn
        >
        <v-btn
          class="ma-2 py-6"
          color="red"
          v-if="
            selectedFSSForAllocationFiltered.length > 0 &&
            multiAllocateType == null
          "
        >
          Please select only FSS to Allocate <br />
          OR only FSS to Unallocate</v-btn
        >
        <v-btn
          v-if="filterUsed"
          class="ma-2 py-6"
          color="dark red lighten-1"
          @click="clearAllFilters"
          >Clear Filters <br />({{ filteredSummaryText }})</v-btn
        >
        <v-row class="ml-4 mt-4">
          <v-switch
            v-if="true"
            class="ml-4"
            v-model="showPractices"
            label="Show Practices"
            small
            inset
            :label="showPractices ? 'Hide Practices' : 'Show Practices'"
          ></v-switch>
          <v-switch
            class="mx-4"
            small
            inset
            v-model="showUnmatched"
            label="Show Unmatched"
            :label="showUnmatched ? 'Hide Unmatched' : 'Show Unmatched'"
          ></v-switch>
        </v-row>
        <div v-if="showLoading">
          <div class="spinner-border ml-4" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </v-toolbar>
      <v-layout column style="height: 65vh">
        <v-flex style="overflow: auto">
          <v-data-table
            v-model="selectedFSSForAllocation"
            :headers="enrollmentHeaders"
            :items="fieldSupplySummaryDataFiltered"
            :search="includedSearch"
            :items-per-page="100"
            height="58vh"
            fixed-header
            fixed-footer
            item-key="id"
            show-select
          >
            <template v-slot:header="{ header }">
              <VDataTablesMultiSelectHeader
                v-if="fieldSupplySummaryData.length > 0"
                :items="fieldSupplySummaryData"
                :headers="enrollmentHeaders"
                :filters="filters"
                showIcon="true"
                @filtered-data="filteredData"
              />
            </template>

            <template slot="item.fieldcrop.field_name" slot-scope="props">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    <span
                      class="hover"
                      @click="handeShowFieldDetailsCompletionModal(props.item)"
                      style="max-width: 100px"
                    >
                      {{ props.item.fieldcrop.field_name }}
                      <v-icon color="blue"> mdi-list-status </v-icon>
                    </span>
                  </div>
                </template>
                <span>Click to see Field Data Completion</span>
              </v-tooltip>
            </template>

            <template slot="item.ea_orders_name" slot-scope="props">
              <v-btn
                v-if="
                  props.item.num_matches > 0 &&
                  [
                    'available',
                    'requested',
                    'requested_pending',
                    'confirmed',
                  ].includes(props.item.status) &&
                  props.item.ea_orders_name == null
                "
                color="dark green lighten-1"
                small
                @click="handleFSSDetailsModal(props.item)"
                >Select Program</v-btn
              >
              <v-btn
                v-if="
                  props.item.num_matches == 0 &&
                  [
                    'available',
                    'requested',
                    'requested_pending',
                    'confirmed',
                  ].includes(props.item.status) &&
                  props.item.ea_orders_name == null
                "
                color="red"
                small
                @click="handleFSSDetailsModal(props.item)"
                >Not Matched <v-icon class="ml-2">mdi-magnify</v-icon></v-btn
              >
              <span v-if="props.item.ea_orders"
                ><v-chip color="primary"
                  >{{ props.item.ea_orders.name }}
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on">
                        <v-icon
                          v-if="props.item.is_locked == false"
                          class="ml-2"
                          color="error"
                          @click="handleOpenFSSRemoveModal(props.item)"
                          >mdi-delete</v-icon
                        >
                      </div>
                    </template>
                    <span>Click to Unallocate this FSS</span>
                  </v-tooltip>
                </v-chip>
              </span>
            </template>
            <!--<template slot="item.ea_orders" slot-scope="props">
          <span v-if="props.item.ea_orders"
            >{{ props.item.ea_orders.name }}
            <i
              v-if="props.item.status == 'locked'"
              class="pt-2 fa fa-times-circle float-right"
              @click="handleOpenFSSRemoveModal(props.item)"
            ></i>
          </span>
        </template>-->
            <template slot="item.practices" slot-scope="props">
              <span v-if="props.item.practices" v-html="props.item.practices">
              </span>
            </template>
            <template
              v-slot:item.data-table-select="{ item, isSelected, select }"
            >
              <!--<v-simple-checkbox
                v-if="
                  [
                    'available',
                    'requested',
                    'requested_pending',
                    'confirmed',
                    //'matched',
                    //'confirmed',
                  ].includes(item.status) &&
                  item.num_matches > 0 &&
                  item.ea_orders_name == null
                "
                :readonly="
                  ['matched', 'confirmed', 'locked'].includes(item.status) &&
                  item.ea_orders_name != null
                "
                :disabled="
                  ['matched', 'confirmed', 'locked'].includes(item.status) &&
                  item.ea_orders_name != null
                "
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>-->
              <v-simple-checkbox
                v-if="
                  [
                    'available',
                    'requested',
                    'requested_pending',
                    'confirmed',
                    //'matched',
                    //'confirmed',
                  ].includes(item.status) && item.num_matches > 0
                "
                :disabled="
                  (item.ea_orders_name != null &&
                    multiAllocateType == 'allocate') ||
                  (item.ea_orders_name == null && multiAllocateType == 'remove')
                "
                :value="isSelected"
                @input="select($event)"
              ></v-simple-checkbox>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <div
        v-if="showLoading || fieldSupplySummaryData == undefined"
        class="mt-3 d-flex justify-content-center"
      >
        <div class="text-bold mb-0">Fetching Data</div>
        <div class="spinner-border ml-4" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div v-else>
        <v-toolbar>
          <h4 class="ma-2">
            No Data Currently Available For Year In Focus: {{ yearInFocus }}
          </h4>
          <v-spacer if="filterUsed"></v-spacer>
          <v-btn
            v-if="filterUsed"
            class="ma-2 py-6"
            color="dark red lighten-1"
            @click="clearAllFilters"
            >Clear Filters <br />({{ filteredSummaryText }})</v-btn
          >
        </v-toolbar>
      </div>
    </div>

    <v-snackbar v-model="snackbar" timeout="3000" color="green lighten-1">
      Filters Are Clearing. This May Take a Few Seconds
      <template v-slot:actions>
        <v-btn color="blue" variant="text" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <ReviewFSSDetails
      v-if="showFSSDetailsModal"
      :items="items"
      :fssPrograms="fssPrograms"
      :fssID="selectedFssID"
      title="Matched Programs Available to Enroll"
      @close-modal="showFSSDetailsModal = false"
      @save-from-modal="handleConfirmFSSAddModal"
    />

    <ReviewFSSMatchability
      v-if="showFSSDetailsMatchabilityModal"
      :items="fssProgramsMatchability"
      title="Programs Unmatchable for Selected FSS"
      @close-modal="showFSSDetailsMatchabilityModal = false"
    />

    <ConfirmFSSRemovalModal
      v-if="selectedOrderID && showRemoveFSSModal"
      :selectedOrderName="selectedOrderName"
      :selectedOrderID="selectedOrderID"
      :fssID="selectedFssID"
      @close-modal="handleCloseFSSRemoveModal"
      @save-from-modal="handleConfirmFSSRemoveModal"
    />

    <FieldDetailsCompletion
      v-if="showFieldDetailsCompletion"
      :fieldID="selectedFieldID"
      :year="yearInFocus"
      @close-modal="handleCloseFieldDetailsCompletion"
    />
  </div>
</template>

<script>
import { Filter } from "@/store/modules"
import CarbonReadyAPI from "@/api/CarbonReadyAPI"
import _ from "lodash"
import { mapState, mapMutations } from "vuex"
import ReviewFSSMatchability from "@/components/insets/modals/ReviewFSSMatchability"
import ReviewFSSDetails from "@/components/insets/modals/ReviewFSSDetails"
import ConfirmFSSRemovalModal from "@/components/insets/modals/ConfirmFSSRemovalModal"
import FieldDetailsCompletion from "@/components/insets/modals/FieldDetailsCompletionModal"
import VDataTablesMultiSelectHeader from "@/components/misc/VDataTablesMultiSelectHeader"

export default {
  name: "FieldCropProgramManagement",
  components: {
    ReviewFSSMatchability,
    ReviewFSSDetails,
    ConfirmFSSRemovalModal,
    FieldDetailsCompletion,
    VDataTablesMultiSelectHeader,
  },
  props: ["activeFields", "targetFSSIds"],
  data() {
    return {
      selectedFSSForAllocation: [],
      showLoading: false,
      filterFieldSupplySummary: [],
      fieldSupplySummaryData: null,
      fieldSupplySummaryDataFiltered: [],
      filters: {
        crop_name: [],
        status: [],
        ea_orders_name: [],
        harvest_type: [],
        legal_entity: [],
        client: [],
        farm: [],
        //matched_program: [],
      },
      showUnmatched: false,
      showPractices: false,
      filterFSSIds: [],
      fssPrograms: [],
      lastFilter: null,
      items: [],
      includedSearch: "",
      selectedFssID: null,
      showFSSDetailsModal: false,
      showFSSDetailsMatchabilityModal: false,
      fssProgramsMatchability: [],
      showRemoveFSSModal: false,
      showFieldDetailsCompletion: false,
      selectedOrderID: null,
      selectedOrerName: null,
      selectedFieldID: null,
      multiAllocateType: null,
      multiAllocateLabel: null,
      snackbar: false,
      fieldIDString: "",
      enrollmentHeaders: [
        { text: "FSS", value: "id", width: "3%" },
        { text: "ID", value: "fieldcrop.field_id", width: "3%" },
        { text: "Field", value: "fieldcrop.field_name" },
        { text: "Legal Entity", value: "legal_entity", width: "8%" },

        { text: "Client", value: "client", width: "8%" },
        { text: "Farm", value: "farm", width: "8%" },
        { text: "Ac", value: "fieldcrop.acreage", width: "5%" }, //acres not currently available in serializer
        { text: "Crop", value: "crop_name", width: "15%" },
        { text: "Type", value: "harvest_type", width: "10%" },
        //{ text: "Practices", value: "practices", width: "8%" },
        //{ text: "Est CO2e Net", value: "scope3_emission", width: "10%" },
        //{ text: "Evidence", value: "evidence" },
        { text: "Status", value: "status", width: "10%" },

        //{ text: "Practices", value: "practices" },
        { text: "Program", value: "ea_orders_name", width: "18%" },
        //{ text: "Matched Program", value: "matched_program", align: " d-none" },

        //{ text: "Matched Program", value: "ea_orders" },
      ],
    }
  },

  methods: {
    ...mapMutations({
      setSelectedFields: Filter.Mutations.setSelectedFields,
    }),
    clearAllFilters() {
      this.snackbar = true
      this.filterFSSIds = []
      this.selectedFSSForAllocation = []
      for (let filter in this.filters) {
        this.filters[filter] = []
      }
      this.fieldIDString = ""
      this.includedSearch = ""
      /*this.setSelectedFields(
        this.fieldBoundaries.map(x => x.properties.field.id)
      )*/
      //NOTE may have to set field selection here too...
      this.fieldSupplySummaryData = _.cloneDeep(this.filterFieldSupplySummary)
      this.fieldSupplySummaryDataFiltered = _.cloneDeep(
        this.filterFieldSupplySummary
      )

      this.fieldIDSearch("")
    },
    filteredData(e) {
      this.fieldSupplySummaryDataFiltered = e

      this.lastFilter = e
      this.fieldIDSearch(this.fieldIDString)
    },
    async fetchFieldSupplySummary() {
      const payload = {
        year: this.yearInFocus,
        exclude_open: true,
        showUnmatched: this.showUnmatched,
      }
      this.showLoading = true
      await CarbonReadyAPI.getFieldSupplySummary(payload).then(resp => {
        let fieldSupplySummary = resp.data.results
        this.filterFieldSupplySummary = fieldSupplySummary.filter(
          e => e.status != "open"
        )
        this.buildFieldSupplySummary()

        this.fieldSupplySummaryDataFiltered = this.filterFieldSupplySummary

        //this.$emit("refreshAllocation")
        this.showLoading = false
      })
    },

    fieldIDSearch(fieldIDString) {
      if (fieldIDString == "" || fieldIDString == null) {
        if (this.lastFilter != null) {
          this.fieldSupplySummaryDataFiltered = this.lastFilter
        } else {
          this.fieldSupplySummaryDataFiltered = _.cloneDeep(
            this.fieldSupplySummaryData
          ) //this.filterFieldSupplySummary
        }
      } else {
        let fieldIDs = []
        let fieldIDsSplit = fieldIDString.split(",")

        fieldIDsSplit.forEach(e => {
          e.split(" ").forEach(f => {
            if (f != " ") {
              fieldIDs.push(f)
            }
          })
        })

        this.fieldSupplySummaryDataFiltered =
          this.fieldSupplySummaryDataFiltered.filter(e => {
            return fieldIDs.includes(String(e.fieldcrop.field_id))
          })
      }
    },

    handeShowFieldDetailsCompletionModal(fss) {
      this.selectedFieldID = fss.fieldcrop.field_id
      this.showFieldDetailsCompletion = true
    },
    handleCloseFieldDetailsCompletion() {
      this.selectedFieldID = null
      this.showFieldDetailsCompletion = false
    },

    handleFSSDetailsModal(fss) {
      if (Array.isArray(fss)) {
        this.fssPrograms = []

        let fss_ids = fss.map(e => e.id)

        CarbonReadyAPI.postFSSRecommendations({
          fss_ids: fss.map(e => e.id),
        }).then(response => {
          //need to make this na add/remove
          this.fssPrograms = response.data[1]

          this.selectedFssID = fss_ids
          if (this.multiAllocateType == "allocate") {
            this.showFSSDetailsModal = true
          } else if (this.multiAllocateType == "remove") {
            let payload = {
              fieldSupplyIds: fss_ids,
              job_type: "remove",
            }

            CarbonReadyAPI.postEAOrderFSS(payload)
              .then(response => {
                if (response.status == 200) {
                  this.multiAllocateType = null
                  this.handleConfirmFSSRemoveModal()
                } else {
                  this.errorText = "Could not Remove Fields"
                  this.showError = true
                }
              })
              .catch(err => {
                this.errorText = err.response.data
                //this.errorText = "Could not add Field. Program exceeds Allotment"
                this.showError = true
              })
          }
        })
      } else {
        if (fss.num_matches == 0) {
          CarbonReadyAPI.getFSSRecommendations({
            view_analysis_fss_id: fss.id,
          }).then(response => {
            //need to make this na add/remove

            this.fssProgramsMatchability = response.data
            this.showFSSDetailsMatchabilityModal = true
          })
          return
        }

        this.fssPrograms = []

        CarbonReadyAPI.getFSSRecommendations({
          fss_id: fss.id,
        }).then(response => {
          this.fssPrograms = response.data[1]

          if (this.fssPrograms.length == 0) {
            CarbonReadyAPI.getFSSRecommendations({
              view_analysis_fss_id: fss.id,
            }).then(response => {
              //need to make this na add/remove

              this.fssProgramsMatchability = response.data
              this.showFSSDetailsMatchabilityModal = true
            })
          } else {
            this.selectedFssID = fss.id

            this.showFSSDetailsModal = true
          }
        })
      }
    },

    handleOpenFSSRemoveModal(e) {
      this.selectedFssID = e.id
      this.selectedOrderName = e.ea_orders.name
      this.selectedOrderID = e.ea_orders.id

      this.showRemoveFSSModal = true
    },
    handleConfirmFSSAddModal() {
      this.selectedFssID = null
      this.showFSSDetailsModal = false
      this.selectedFSSForAllocation = []
      this.$emit("refreshAllocation")

      this.fetchFieldSupplySummary()
      //this.$emit("refreshAllocation")
    },
    handleCloseFSSRemoveModal() {
      this.selectedFssID = null

      this.selectedOrderID = null
      this.selectedOrderName = null
      this.showRemoveFSSModal = false
    },
    handleConfirmFSSRemoveModal() {
      this.selectedFssID = null
      this.selectedFSSForAllocation = []

      this.selectedOrderID = null
      this.selectedOrderName = null
      this.showRemoveFSSModal = false
      this.$emit("refreshAllocation")

      this.fetchFieldSupplySummary()
      //this.$emit("refreshAllocation")
    },
    buildFieldSupplySummary() {
      this.filterFieldSupplySummary.forEach(e => {
        if (Array.isArray(e.practices)) {
          let practice_list = "<ul>"
          e.practices.forEach(p => {
            practice_list += "<li>" + p.split(":")[1] + "</li>"
          })
          practice_list += "</ul>"
          e.practices = practice_list
        }

        e.legal_entity = null
        if (e.contractgroup_yessupply.length > 0) {
          if (e.contractgroup_yessupply[0].is_confirmed == true) {
            e.legal_entity = e.contractgroup_yessupply[0].name
          }
        } else if (e.contractgroup_nosupply.length > 0) {
          if (e.contractgroup_nosupply[0].is_confirmed == true) {
            e.legal_entity = e.contractgroup_nosupply[0].name
          }
        } else {
          e.legal_entity = null
        }

        e.fieldcrop.acreage = Math.round(e.fieldcrop.acreage)
        e.crop_name = e.fieldcrop.crop_name
        e.ea_orders_name = null
        if (e.ea_orders != null) {
          e.ea_orders_name = e.ea_orders.name //+ " (" + e.ea_orders.program_rollup.name + ")"
        }
      })

      if (this.filterFSSIds.length > 0) {
        this.fieldSupplySummaryDataFiltered = _.cloneDeep(
          this.filterFieldSupplySummary.filter(e => {
            return _.includes(this.filterFSSIds, e.id)
          })
        )
      } else {
        this.fieldSupplySummaryDataFiltered = _.cloneDeep(
          this.filterFieldSupplySummary
        )
      }

      this.fieldSupplySummaryData = _.cloneDeep(
        this.fieldSupplySummaryDataFiltered
      )
    },
  },

  computed: {
    ...mapState({
      yearInFocus: state => state.Organization.year,
      fieldBoundaries: state => state.Map.fieldBoundaries,
    }),

    filteredSummaryText() {
      return (
        "Showing " +
        this.fieldSupplySummaryDataFiltered.length +
        " / " +
        this.filterFieldSupplySummary.length
      )
    },
    filterUsed() {
      return (
        this.fieldSupplySummaryDataFiltered.length !=
        this.filterFieldSupplySummary.length
      )
    },

    selectedAcreage() {
      let acreages = this.selectedFSSForAllocationFiltered.map(e => {
        return e.fieldcrop.acreage
      })

      let acreage_sum = acreages.reduce((partialSum, a) => partialSum + a, 0)
      return acreage_sum
    },

    selectedFSSForAllocationFiltered() {
      return this.selectedFSSForAllocation.filter(
        e =>
          [
            "available",
            "requested",
            "requested_pending",
            "confirmed",
            //"matched",
            //"confirmed",
          ].includes(e.status) && e.num_matches > 0
      )
    },
  },

  watch: {
    selectedFSSForAllocationFiltered() {
      if (this.selectedFSSForAllocationFiltered.length == 0) {
        this.multiAllocateType = null
        return
      } else {
        let localAllocateType = "allocate"
        this.multiAllocateLabel = "Allocate"

        for (var i = 0; i < this.selectedFSSForAllocationFiltered.length; i++) {
          if (
            this.selectedFSSForAllocationFiltered[i].matched_program != null
          ) {
            localAllocateType = "remove"
            this.multiAllocateLabel = "Unallocate"
          }
        }
        if (localAllocateType == "remove") {
          let uniquePrograms = _.uniq(
            this.selectedFSSForAllocationFiltered.map(e => {
              return e.matched_program
            })
          )

          if (uniquePrograms.length > 1) {
            localAllocateType = null
          }
        }
        this.multiAllocateType = localAllocateType
      }
    },
    yearInFocus() {
      this.fetchFieldSupplySummary()

      this.filterFSSIds = []
      for (let filter in this.filters) {
        this.filters[filter] = []
      }
      this.includedSearch = ""
      this.fieldIDString = ""
    },
    targetFSSIds() {
      this.filterFSSIds = this.targetFSSIds
      this.buildFieldSupplySummary()
    },
    /*activeFields() {
      this.buildFieldSupplySummary()
    },*/
    showUnmatched() {
      this.fetchFieldSupplySummary()
    },
    showPractices() {
      if (this.showPractices) {
        this.enrollmentHeaders = [
          { text: "FSS", value: "id", width: "3%" },
          { text: "ID", value: "fieldcrop.field_id", width: "3%" },
          { text: "Field", value: "fieldcrop.field_name" },
          { text: "Legal Entity", value: "legal_entity", width: "8%" },

          { text: "Client", value: "client", width: "8%" },
          { text: "Farm", value: "farm", width: "8%" },

          { text: "Ac", value: "fieldcrop.acreage", width: "5%" }, //acres not currently available in serializer
          { text: "Crop", value: "crop_name", width: "15%" },
          { text: "Type", value: "harvest_type", width: "10%" },
          { text: "Practices", value: "practices", width: "8%" },
          //{ text: "Est CO2e Net", value: "scope3_emission", width: "10%" },
          { text: "Status", value: "status", width: "10%" },

          { text: "Program", value: "ea_orders_name", width: "18%" },
        ]
      } else {
        this.enrollmentHeaders = [
          { text: "FSS", value: "id", width: "3%" },
          { text: "ID", value: "fieldcrop.field_id", width: "3%" },
          { text: "Field", value: "fieldcrop.field_name" },
          { text: "Legal Entity", value: "legal_entity", width: "8%" },

          { text: "Client", value: "client", width: "8%" },
          { text: "Farm", value: "farm", width: "8%" },

          { text: "Ac", value: "fieldcrop.acreage", width: "5%" }, //acres not currently available in serializer
          { text: "Crop", value: "crop_name", width: "15%" },
          { text: "Type", value: "harvest_type", width: "10%" },

          { text: "Status", value: "status", width: "10%" },

          { text: "Program", value: "ea_orders_name", width: "18%" },
        ]
      }
    },
  },

  async mounted() {
    await this.fetchFieldSupplySummary()
    //this.fieldSupplySummaryDataFiltered = this.fieldSupplySummaryData
  },
}
</script>

<style scoped>
.fa-times-circle {
  cursor: pointer;
  color: #e60000;
}
.hover {
  cursor: pointer;
  color: #0095ff;
  font-weight: 500;
}
.v-data-table {
  overflow-x: auto;
  overflow-y: auto;
  max-height: "calc(90vh - 520px)";
}
</style>
