export const CHANNEL_PARTNER = "cp"
export const GROWER = "grower"

export const YES = "yes"
export const NO = "no"

export const CLIENT_PROFILES = "clientProfiles"
export const ENROLLMENT_TABLE = "enrollmentTable"
export const ROLLUP_TABLE = "rollupTable"
export const FSS_INDIVIUAL = "fss"

export const OPEN = "open"
export const REQUESTED = "requested"
export const REQUESTED_PENDING = "requested_pending"
export const AVAILABLE = "available"
export const CONFIRMED = "confirmed"
export const LOCKED_FOR_PAYMENT = "locked_for_payment"

export const NOT_READY_YET = "not ready yet"
export const READY_FOR_GENERATION = "ready for generation"
export const CONTRACT_GENERATED = "contract generated"
export const AWAITING_REVIEW = "awaiting review"
export const CONTRACT_VOIDED = "contract voided"
export const CHANGES_REQUESTED = "changes requested"
export const APPROVED = "approved"
export const CONTRACT_COMPLETED = "contract completed"

export const ADD_PROFILE = 0
export const REMOVE_PROFILE = 1
export const UPDATE_PROFILE = 2
export const SWAP_PROFILE = 3
export const CREATE_PROFILE = 4

export const BLANK_GROUP_DATA = {
  enrollment: {},
  cp_profiles: [],
  grower_profiles: [],
}

export const BLANK_METADATA = {
  thumbnail: null,
  farmName: null,
  practices: [],
  orderIds: 0,
}

export const BLANK_ACREAGE_VALUES = {}
BLANK_ACREAGE_VALUES[REQUESTED] = {}
BLANK_ACREAGE_VALUES[CONFIRMED] = {}
BLANK_ACREAGE_VALUES[REQUESTED_PENDING] = {}
BLANK_ACREAGE_VALUES[LOCKED_FOR_PAYMENT] = {}

export const STATUS_MAP = {}
STATUS_MAP[OPEN] = {
  text: "Unavailable",
  color: "gray",
}
STATUS_MAP[AVAILABLE] = {
  text: "Available",
  color: "gray",
}
STATUS_MAP[REQUESTED] = {
  text: "Requested",
  color: "green",
}
STATUS_MAP[REQUESTED_PENDING] = {
  text: "Pre-Requested",
  color: "green",
}
STATUS_MAP[CONFIRMED] = {
  text: "Confirmed",
  color: "confirmed-green",
}
STATUS_MAP[LOCKED_FOR_PAYMENT] = {
  text: "Locked For Payment",
  color: "green",
}

export const CONTRACT_STATUS_MAP = {}
CONTRACT_STATUS_MAP[NOT_READY_YET] = {
  text: "Not Ready Yet",
  color: "gray",
}
CONTRACT_STATUS_MAP[READY_FOR_GENERATION] = {
  text: "Ready For Generation",
  color: "gray",
}
CONTRACT_STATUS_MAP[CONTRACT_GENERATED] = {
  text: "Contract Generated",
  color: "gray",
}
CONTRACT_STATUS_MAP[AWAITING_REVIEW] = {
  text: "Awaiting Review",
  color: "amber",
}
CONTRACT_STATUS_MAP[CONTRACT_VOIDED] = {
  text: "Contract Voided",
  color: "red",
}
CONTRACT_STATUS_MAP[CHANGES_REQUESTED] = {
  text: "Changes Requested",
  color: "orange",
}
CONTRACT_STATUS_MAP[APPROVED] = {
  text: "Approved",
  color: "green",
}
CONTRACT_STATUS_MAP[CONTRACT_COMPLETED] = {
  text: "Contract Completed",
  color: "green",
}

export const CP_VIEW = 0
export const CSM_VIEW = 1

// Ariah - a.mazgajewski@arvaintelligence.com
// Alexis - a.covington@arvaintelligence.com
// Caroline - c.hollingsworth@arvaintelligence.com
// April - 	a.tutor@arvaintelligence.com
// Jonathan - j.hall@arvaintelligence.com
// Carson - c.miller@arvaintelligence.com
// Dylan S - d.sherfick@arvaintelligence.com
// Andrew G. - a.greenwell@arvaintelligence.com
// Emily B. - e.beddoe@arvaintelligence.com
// Kyle W. - k.wilson@arvaintelligence.com
export const USER_CAN_COUNTERSIGN = [215, 379, 398, 332, 257, 506, 527, 489, 224, 90] // Here is a list of user ids that can trigger the Send Counter-Sign
export const USER_CAN_VOID = [215]
